import GridViewIcon from '@mui/icons-material/GridView';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import HelpIcon from '@mui/icons-material/Help';
import SettingsIcon from '@mui/icons-material/Settings';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import { appRouters } from "./routes";

export const menuItems = [
  {
    id: 1,
    title: "Views",
    icon: GridViewIcon,
    href: appRouters.views,
    iconPosition: "left",
    stickToBottom: false,
    hideRouteName:"views",
    tourSelector:"third-step"
  },
  {
    id: 2,
    title: "Run Query",
    icon: EditCalendarOutlinedIcon,
    href: "",
    iconPosition: "left",
    stickToBottom: false,
    isTab: true,
    hideRouteName:"views",
    tourSelector:"fourth-step"
  },
  {
    id: 3,
    title: "Filters",
    icon: FilterAltOutlinedIcon,
    href: "",
    iconPosition: "left",
    stickToBottom: false,
    isTab: true,
    hideRouteName:"views",
    tourSelector:"fifth-step"
  },
  {
    id: 6,
    title: "Favorites",
    icon: FavoriteBorderOutlinedIcon,
    href: "",
    iconPosition: "left",
    stickToBottom: false,
    isTab: true,
    hideRouteName:"views",
    tourSelector:"sixth-step"
  },
  {
    id: 7,
    title: "Query Builder",
    icon: ExtensionOutlinedIcon,
    href: "",
    iconPosition: "left",
    stickToBottom: false,
    isTab: true,
    hideRouteName:"views",
    tourSelector:"seventh-step"
  }
  ,
  {
    id: 4,
    title: "Settings",
    icon: SettingsIcon,
    isTab: true,
    href: "",
    iconPosition: "left",
    stickToBottom: true,
    bottom: 10,
    hideRouteName:"views",
  },
  {
    id: 5,
    title: "Help",
    icon: HelpIcon,
    href: "",
    iconPosition: "left",
    stickToBottom: true,
    isTab:true,
    bottom: 60,
    hideRouteName:"",
  },
];
