import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TabState {
  tabIndex: number | null; // Define the type of tabIndex
}

const initialState: TabState = {
  tabIndex: 1, // Set the initial value to null or any other appropriate initial value
};

const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    setTabIndex(state, action: PayloadAction<number>) {
      state.tabIndex = action.payload;
    }
  }
});

export const { setTabIndex } = tabSlice.actions;

export default tabSlice.reducer;
