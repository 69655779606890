import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { resourceData } from '../../constants/screensData'
import { useNavigate } from 'react-router-dom';
import { setGetStartedModal } from '../../redux/reducers/getStartedModalReducer';
import { useDispatch } from 'react-redux';
import { setTabIndex } from '../../redux/reducers/tabReducer';

const style = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50rem",
    // height: "23rem",
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: "3vh",
};

const style1 = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#ffffff',
    borderRadius: "0.625rem",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
    p: "3vh",
    width: "50rem",
    height: "23rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
};

const numSteps = 5; // Define the number of steps

// export default const = GetStartesModal(open) {
const GetStartesModal = ({ open }: any) => {

    // const [open, setOpen] = useState(true);
    const [step, setStep] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleNextStep = () => {
        if (step < numSteps - 1) {
            setStep(step + 1);
        }
    };

    const handlePreviousStep = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const handleClose = () => {
        // setGetStartedModal(false);
        dispatch(setGetStartedModal(false));

    };

    const handleLearnMore = () => {
        step === 0 && (dispatch(setTabIndex(1))); navigate('/resource')
        step === 1 && (dispatch(setTabIndex(2))); navigate('/resource') 
        step === 2 && (dispatch(setTabIndex(3))); navigate('/resource')
        step === 3 && (dispatch(setTabIndex(4))); navigate('/resource')
        dispatch(setGetStartedModal(false));

    }

    const KnowledgeGraph = () => {
        return (
            <>
                <Typography marginBottom={"1vh"}>What is knowledge graph</Typography>
                <Box display={"flex"}>
                    <Typography color={"#3b3b3b"}>
                        A knowledge graph is a structured representation of knowledge that captures relationships between entities, concepts, or events in a graph format. It consists of nodes, which represent entities, and edges, which represent relationships between these entities.
                        <br />
                        <br />
                        <b>Why to use Knowledge Graph...</b>
                        <br />
                        <b>Real world Knowledge Graph problems...</b>
                    </Typography>
                    <img src={resourceData.aboutKG} style={{ height: "15rem" }} />

                </Box>
            </>
        )
    }
    const CustomizeCypher = () => {
        return (
            <>
                <Typography marginBottom={"1vh"}>Customize a Cypher query</Typography>
                <Typography color={"#3b3b3b"}>
                    Cypher, the query language for Neo4j, offers a range of ways to customize queries based on your specific needs. Here are some key aspects of customization:
                </Typography>
                <Box display={"flex"} color={"#3b3b3b"} gap={"6vh"} paddingLeft={"3vh"}>
                    <ul style={{ listStyle: "disc" }}>
                        <li>Filtering</li>
                        <li>Projection</li>
                        <li>Aggregation</li>
                    </ul>
                    <ul style={{ listStyle: "disc" }}>
                        <li>Sub-queries</li>
                        <li>Parameters</li>
                        <li>Functions</li>
                    </ul>

                </Box>
            </>
        )
    }
    const Ontology = () => {
        return (
            <>
                <Typography marginBottom={"1vh"}>Ontology</Typography>
                <Box display={"flex"}>
                    <Typography color={"#3b3b3b"}>
                        An ontology acts like a schema or blueprint for your knowledge graph. It defines:
                        <br />
                        <b>Entity types: </b>What kind of "things" exist in your domain (e.g., people, products, locations).
                        <br />
                        <b>Attributes:</b>What properties describe each entity type (e.g., name, price, address).
                        <br />
                        <b>Relationships: </b>How different entities are connected (e.g., "is-a," "part-of," "has-symptom").
                    </Typography>
                    <img src={resourceData.ontology} style={{ height: "15rem" }} />

                </Box>
            </>
        )
    }
    const SemanticLayer = () => {
        return (
            <>
                <Typography marginBottom={"1vh"}>Semantic Layer</Typography>
                <Typography color={"#3b3b3b"}>
                    In the world of knowledge graphs, a semantic layer acts like a translator between the raw data and the users.
                    <br />
                    Knowledge graphs store information using entities (like materials, customers) and relationships (like "bought," "located at"), which can be technical and challenging for non-technical users to understand directly.
                    <br />
                    The semantic layer sits on top of the knowledge graph, acting as a middleware.
                </Typography>

            </>
        )
    }
    const GraphWalkThrough = () => {
        return (
            <>
                <Typography marginBottom={"1vh"}>Graph Explorer - Walkthrough</Typography>
                <video controls  style={{height:"31vh"}}>
                    <source src={resourceData.walkThroughVideo} type="video/webm" />
                    <source src="movie.mp4" type="video/mp4" />
                </video>


            </>
        )
    }
    const steps = 4;
    const component = [<KnowledgeGraph />, <CustomizeCypher />, <Ontology />, <SemanticLayer />, <GraphWalkThrough />]

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style1}>
                {component[step]}
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                    {
                        step == 4 ? <Button variant="text" sx={{ textTransform: "none" }} disabled onClick={() => { handleLearnMore() }}>Learn more</Button> : 
                        <Button variant="text" sx={{ textTransform: "none" }} onClick={() => { handleLearnMore() }}>Learn more</Button>
                    }
                    <Box display={"flex"}>
                        {[...Array(numSteps)].map((_, index) => (
                            <div
                                key={index}
                                style={{
                                    width: 10,
                                    height: 10,
                                    borderRadius: '50%',
                                    backgroundColor: index === step ? '#461e96' : '#ccc',
                                    margin: '0 5px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setStep(index)}
                            />
                        ))}
                    </Box>
                    <Box display={"flex"} gap={"1vh"}>
                        <Button variant='outlined' disabled={step === 0} onClick={handlePreviousStep}>Previous</Button>
                        <Button variant='contained' disabled={step === numSteps - 1} onClick={handleNextStep}>Next</Button>
                    </Box>

                </Box>

            </Box>
        </Modal>
    );
}
export default GetStartesModal;