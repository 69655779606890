import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BasicNode, BasicRelationship } from "../../common";

export interface GraphReducerInterface {
  graphNodes: BasicNode[]; //intial
  graphRelationships: BasicRelationship[]; //intial
  graphLayoutStyles: any;
  miniMapOptions: {
    nodeDragged: boolean;
  };
}

const initialState: GraphReducerInterface = {
  graphNodes: [],
  graphRelationships: [],
  graphLayoutStyles: {},
  miniMapOptions: {
    nodeDragged: false,
  },
};

const graphReducer = createSlice({
  name: "graphReducer",
  initialState,
  reducers: {
    setGraphNodes(state, action: PayloadAction<BasicNode[]>) {
      state.graphNodes = [...action.payload];
    },

    setGraphRelationships(state, action: PayloadAction<BasicRelationship[]>) {
      state.graphRelationships = [...action.payload];
    },

    setMiniMapNodeDragged(state, action: PayloadAction<boolean>) {
      state.miniMapOptions.nodeDragged = action.payload;
    },

    setGraphLayoutStyles(state, action: PayloadAction<any>) {
      state.graphLayoutStyles = action.payload;
    },
  },
});

export const {
  setGraphNodes,
  setGraphRelationships,
  setGraphLayoutStyles,
} = graphReducer.actions;

export default graphReducer.reducer;
