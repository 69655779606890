import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import CodeSnippet from './CodeSnippet';

export default function CustomizeCypher() {
    const code1 = `MATCH (v:Vendor) WHERE v.status = 'Active' RETURN v`;
    const code2 = `MATCH (m:Material)-[r:SOLD_BY]->(v:Vendor) WHERE r.order_year > 2022 RETURN m, r, v`;
    const code3 = `MATCH (v:Vendor) RETURN v.name, v.status`;
    const code4 = `MATCH (v:Vendor) RETURN v.name as vendorName, v.status as vendorStatus`;
    const code5 = `MATCH (v:Vendor) RETURN COUNT(v) AS totalVendors`;
    const code6 = `MATCH (m:Material {name: "amoxicillin"}) RETURN DISTINCT(m.status) AS distinctStatus`;
    const code7 = `MATCH m:Material) WHERE m.name = "amoxicillin" 
RETURN m, [m-[:SOLD_BY]->(vendor:Vendor)] AS vendors`;
    const code8 = `MATCH (v.Vendor {name: $name}) RETURN v`;
    const code9 = `MATCH (v:Vendor) RETURN UPPER(v.name) AS uppercaseName`;
    useEffect(() =>{
        window.scrollTo(0,0);
    })
    return (
        <Box paddingTop={"1rem"} paddingLeft={"20rem"} paddingRight={"3rem"} width={"85%"} paddingBottom={"10rem"}>
            <Typography fontSize={"25px"} textAlign={"center"}>Customize a Cypher query</Typography>
            <Typography color={"#3b3b3b"} marginTop={"2vh"}>
                Cypher, the query language for Neo4j, offers a range of ways to customize queries based on your specific needs. Here are some key aspects of customization:

                <ol style={{ paddingLeft: '20px' }}>
                    <li>
                        <b>Filtering </b>
                        <br />
                        Use the WHERE clause to filter results based on specific criteria. For example, you can filter nodes by their properties:
                        <Box marginTop={"2vh"} marginBottom={"2vh"}>
                            <CodeSnippet code={code1} name='Cypher' />
                        </Box>
                        You can also filter relationships based on their properties:
                        <Box marginTop={"2vh"} marginBottom={"2vh"}>
                            <CodeSnippet code={code2} name='Cypher' />
                        </Box>
                    </li>
                    <li>
                        <b>Projections:</b>
                        <br />
                        Specify which properties you want to return from nodes and relationships using the RETURN clause. By default, all properties are returned.
                        <Box marginTop={"2vh"} marginBottom={"2vh"}>
                            <CodeSnippet code={code3} name='Cypher' />
                        </Box>
                        You can also use aliases for returned properties:
                        <Box marginTop={"2vh"} marginBottom={"2vh"}>
                            <CodeSnippet code={code4} name='Cypher' />
                        </Box>
                    </li>
                    <li>
                        <b>Aggregation:</b>
                        <br />
                        Use aggregation functions like COUNT, SUM, AVG, etc., to perform calculations on your data.
                        <Box marginTop={"2vh"} marginBottom={"2vh"}>
                            <CodeSnippet code={code5} name='Cypher' />
                        </Box>
                        You can use aggregation in combination with filtering for specific groups:
                        <Box marginTop={"2vh"} marginBottom={"2vh"}>
                            <CodeSnippet code={code6} name='Cypher' />
                        </Box>
                    </li>
                    <li>
                        <b>Subqueries:</b>
                        <br />
                        Use subqueries within your main query to retrieve more complex information. This lets you perform nested searches and combine results from multiple queries.
                        <Box marginTop={"2vh"} marginBottom={"2vh"}>
                            <CodeSnippet code={code7} name='Cypher' />
                        </Box>
                        This query retrieves information about Amoxilillin and a subquery within the RETURN clause retrieves Amoxilillin's vendors using a separate match statement.
                    </li>
                    <li>
                        <b>Parameters:</b>
                        <br />
                        Use parameters to make your queries dynamic and reusable. You can replace parameter values with different data at runtime.
                        <Box marginTop={"2vh"} marginBottom={"2vh"}>
                            <CodeSnippet code={code8} name='Cypher' />
                        </Box>
                        This query uses the $name parameter, which can be replaced with a specific name when executing the query.
                    </li>
                    <li>
                        <b>Functions:</b>
                        <br />
                        Cypher provides various built-in functions for string manipulation, date and time calculations, and other operations. These functions allow you to perform additional processing within your queries.
                        <Box marginTop={"2vh"} marginBottom={"2vh"}>
                            <CodeSnippet code={code9} name='Cypher' />
                        </Box>
                        This query uses the UPPER function to convert all characters in the name property to uppercase.
                        <br />
                        By combining these techniques, you can create highly customized Cypher queries that meet your specific data exploration and manipulation needs. The possibilities are vast, depending on your desired outcomes and the structure of your knowledge graph.
                    </li>
                </ol>
            </Typography>
        </Box>
    )
}
