import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface tableData {
  data?: any;
}
const initialState: tableData = {
    data: '',
};

const tableDataReducer = createSlice({
  name: "tableReducer",
  initialState,
  reducers: {
    setTableData(state, action: PayloadAction<any>) {
        state.data = action.payload;
      },
  },
});

export const { setTableData } =
tableDataReducer.actions;

export default tableDataReducer.reducer;
