import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface UserInfo {
  userInfo?: string;
}
const initialState: UserInfo = {
    userInfo: '',
};

const userInfoReducer = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<string>) {
        state.userInfo = action.payload;
      },
  },
});

export const { setUserInfo } =
userInfoReducer.actions;

export default userInfoReducer.reducer;
