import { Box, Typography } from "@mui/material";
import { count } from "console";
import { GraphStyleModel } from "../../models/GraphStyle";
import { NonClickableRelTypeChip } from "./styled";
import relationshipImage from "../../utils/relationship-line.png"

export type RelTypeProps = {
  graphStyle: GraphStyleModel;
  selectedRelType: { relType: string; propertyKeys: string[]; count?: number };
  onRelSelectedFromFilters?: (relType: string) => void;
  relsSelectedFromFilterState:any;
};

const RelTypeStyles = {
  height: "3.1rem",
  width: "100%",
  borderRadius: "1.5rem",
  border: "1px solid #D9D9D9",
  backgroundColor: "#ffffff",
  color: "#636363",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingX: "0.7rem",
  alignContent: "left",
  cursor: "pointer",
  ":hover": {
    backgroundColor: "#F5F5F5",
  }
}


export function RelType({
  selectedRelType,
  graphStyle,
  onRelSelectedFromFilters,
  relsSelectedFromFilterState
}: RelTypeProps): JSX.Element {
  const styleForRelType = graphStyle.forRelationship({
    type: selectedRelType.relType,
  });
  return (
    <>
      {
        selectedRelType.relType !== "*" && (
          <Box sx={
            relsSelectedFromFilterState.includes(selectedRelType.relType)
                ? {
                  ...RelTypeStyles,
                  border: "1px solid #461e96",
                  boxShadow: "0px 0px 0px 4px #DDDBF7"
                                }
                : RelTypeStyles
          }
            onClick={() => onRelSelectedFromFilters(selectedRelType.relType)}
          >
            <img src={relationshipImage} alt="relationship-line" />
            <Typography>{selectedRelType.relType}</Typography>
            <Typography>{selectedRelType.count}</Typography>
          </Box >
        )
      }
    </>
  );
}
