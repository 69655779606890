/**
 * Sidebar Component
 *
 * The `Sidebar` component is a navigation sidebar used for displaying a list of menu items.
 *  It is typically used as a part of the dashboard layout.
 *
 * @param {Object} props - Component props
 * @param {boolean} props.open - A boolean indicating whether the sidebar is open or closed.
 * @param {Function} props.handleDrawerClose - A function to handle closing the sidebar when a user clicks on the close icon.
 *
 * @returns {React.ReactNode} The `Sidebar` component.
 */

import { SidebarProps } from "../../@types/components";
// import { Drawer, DrawerHeader } from "./sidebar.styles";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { menuItems } from "../../constants/menuItems";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import React from "react";

const Sidebar = ({ open, handleDrawerClose, handleMenuPopover, isTabActive, activeTab }: SidebarProps) => {
  // const theme = useTheme();
  const location = useLocation();
  const [menuSelected, setMenuSelected] = useState(false) 
  const [activeMenuItem, setActiveMenuItem] = useState(""); 


  // const onClickFilter = (title: string) => {
  //   // setMenuSelected(open)
  //   handleMenuPopover(title)
  //   title == 'Filters' || title == 'Favorites' || title == 'Settings' ? setActiveMenuItem(title) : setActiveMenuItem('')
  // }
  const onClickFilter = (title: string) => {
    handleMenuPopover(title);
    if (title === 'Filters' || title === 'Favorites' || title === 'Settings') {
        setActiveMenuItem(title);
    } else {
        setActiveMenuItem('');
    }
    console.log(isTabActive,activeTab)
};

  return (
    <>
      <Drawer
        variant="permanent"
        open={open}
        onClose={handleDrawerClose}
      >
        <List
          sx={{
            flexDirection: "column",
            height: "calc(100vh - 64px)",
            marginTop: "64px",
            position: "relative",
          }}
        >
          {menuItems.map((item, index) => (
            location.pathname.substring(1) === item?.hideRouteName || location.pathname.substring(1) === item?.hideRouteName + "/" ? (
              <ListItem disablePadding
                sx={{
                  width: "4.5rem",
                }}
                key={index}
              ></ListItem>
            ) : (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  position: item.stickToBottom ? "absolute" : "static",
                  bottom: item.stickToBottom ? item.bottom : "auto",
                  width: "100%",
                  zIndex: item.stickToBottom ? 1 : "auto",
                  // borderRight: isTabActive && activeTab === item.title  ? "2px solid #461e96" : "none",

                }}

                
                className={item?.tourSelector}
              >
                {item.isTab ? (
                  <Tooltip title={item.title} arrow placement="right">
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "center",
                        px: 3,
                        color: location.pathname.includes(item.href) ? "primary.main" : "text.primary",
                        // borderRight: isTabActive && activeTab === item.title  ? "2px solid #461e96" : "none",
                      }}
                      onClick={(event) => onClickFilter(item.title)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          justifyContent: "center",
                          color: location.pathname.includes(item.href) ? "text.primary" : "text.primary",
                        }}
                      >
                        <item.icon />
                      </ListItemIcon>
                    </ListItemButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={item.title} arrow placement="right">
                    <NavLink to={item.href} style={{ textDecoration: "none", width: "100%" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "center",
                          px: 3,
                          color: location.pathname.includes(item.href)
                            ? "primary.main"
                            : "text.primary",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            justifyContent: "center",
                            color: location.pathname.includes(item.href)
                              ? "text.primary"
                              : "text.primary",
                          }}
                        >
                          <item.icon />
                        </ListItemIcon>
                      </ListItemButton>
                    </NavLink>
                  </Tooltip>
                )}
              </ListItem>
            )
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Sidebar;
