import React, { useEffect, useState } from "react";
import {
  numberToUSLocale,
  ShowMoreOrAll,
  WarningMessage,
} from "../../../../common";
import { GraphStyleModel } from "../../models/GraphStyle";
import { GraphStats } from "../../utils/mapper";
import SearchIcon from '@mui/icons-material/Search';

import {
  PaneBody,
  PaneHeader,
  PaneBodySectionHeaderWrapper,
  PaneBodySectionSmallText,
  PaneBodySectionTitle,
  StyledLegendInlineList,
  PaneWrapper,
} from "./styled";

import { NodeLabel } from "./NodeLabel";
import { RelType } from "./RelType";
import { Box, Button, Divider, Input, Typography } from "@mui/material";
import { useAppDispatch } from "../../../../redux/hooks/hook";
import { setFilterPanelWidth } from "../../../../redux/reducers/sidebarReducer";


type PaneBodySectionHeaderProps = {
  title: string;
  numOfElementsVisible: number;
  totalNumOfElements: number;
};
function PaneBodySectionHeader({
  title,
  numOfElementsVisible,
  totalNumOfElements,
}: PaneBodySectionHeaderProps) {
  return (
    <PaneBodySectionHeaderWrapper>
      <PaneBodySectionTitle>{title}</PaneBodySectionTitle>
      {numOfElementsVisible < totalNumOfElements && (
        <PaneBodySectionSmallText>
          {`(showing ${numOfElementsVisible} of ${totalNumOfElements})`}
        </PaneBodySectionSmallText>
      )}
    </PaneBodySectionHeaderWrapper>
  );
}

export type OverviewPaneProps = {
  graphStyle: GraphStyleModel;
  hasTruncatedFields: boolean;
  nodeCount: number | null;
  relationshipCount: number | null;
  stats: GraphStats;
  infoMessage: string | null;
  onNodeSelectedFromFilters?: (label: string) => void;
  onRelSelectedFromFilters?: (relType: string) => void;
  width?: number;
};

export const OVERVIEW_STEP_SIZE = 50;

const tabStyles = {
  fontSize: "1rem",
  fontWeight: 500,
  borderRadius: "0",
  textTransform: "capitalize",
}

function DefaultOverviewPane({
  graphStyle,
  hasTruncatedFields,
  nodeCount,
  relationshipCount,
  stats,
  infoMessage,
  onNodeSelectedFromFilters,
  onRelSelectedFromFilters,
  width,
}: OverviewPaneProps): JSX.Element {
  const dispatch = useAppDispatch()
  // state and dispatch
  const [maxLabelsCount, setMaxLabelsCount] = useState(OVERVIEW_STEP_SIZE);
  const [maxRelationshipsCount, setMaxRelationshipsCount] =
    useState(OVERVIEW_STEP_SIZE);
  const [tabSelected, setTabSelected] = useState<string>("nodes");
  const [nodesSearch, setNodesSearch] = useState<string>("");
  const [relationshipsSearch, setRelationshipsSearch] = useState<string>("");
  const [visibleLabelKeys, setVisibleLabelKeys] = useState<any>([])
  const [visibleRelationshipKeys, setVisibleRelationshipKeys] = useState<any>([])
  const [statsNodeCount, setStatsNodeCount] = useState<number | null>(null)
  const [statsRelationshipCount, setStatsRelationshipCount] = useState<number | null>(null)

  const handleTabChange = (value: string) => {
    setTabSelected(value);
  }

  React.useEffect(() => {
    // Handle the width of the filter panel
    dispatch(setFilterPanelWidth(400))
  }, [width])


  const { relTypes, labels } = stats;

  React.useEffect(() => {
    //nodes
    const newVisibleLabelKeys = labels
      ? Object.keys(labels).slice(0, maxLabelsCount)
      : [];
    setVisibleLabelKeys(newVisibleLabelKeys)
    // calculate the count for nodes
    let nodeSum = 0;
    for (const label in stats.labels) {
      if (label !== '*' && labels.hasOwnProperty(label)) {
        nodeSum += labels[label].count;
      }
    }
    setStatsNodeCount(nodeSum)

    // relationships
    const newVisibleRelationshipKeys = relTypes
      ? Object.keys(relTypes).slice(0, maxRelationshipsCount)
      : [];
    setVisibleRelationshipKeys(newVisibleRelationshipKeys)
    // calculate the count for relationships
    let relSum = 0;
    for (const rel in stats.relTypes) {
      if (rel !== '*' && relTypes.hasOwnProperty(rel)) {
        relSum += relTypes[rel].count;
      }
    }
    setStatsRelationshipCount(relSum)

  }, [stats])

  const onMoreLabelsClick = (numMore: number) => {
    setMaxLabelsCount(maxLabelsCount + numMore);
  };

  const onMoreRelationshipsClick = (numMore: number) => {
    setMaxRelationshipsCount(maxRelationshipsCount + numMore);
  };


  const totalNumOfLabelTypes = labels ? Object.keys(labels).length : 0;
  const totalNumOfRelTypes = relTypes ? Object.keys(relTypes).length : 0;

  // on change in search of node labels
  const handleNodesChange = (e: any) => {
    const searchValue = e.target.value;
    setNodesSearch(searchValue);
    const filteredLabelKeys = Object.keys(labels).slice(0, maxLabelsCount).filter(label =>
      label.toLowerCase().includes(searchValue.toLowerCase())
    );
    setVisibleLabelKeys(filteredLabelKeys);
  }

  // on change in search of relationship types
  const handleRelationshipsChange = (e: any) => {
    const searchValue = e.target.value;
    setRelationshipsSearch(searchValue);
    const filteredRelKeys = Object.keys(relTypes).slice(0, maxLabelsCount).filter(rel =>
      rel.toLowerCase().includes(searchValue.toLowerCase())
    );
    setVisibleRelationshipKeys(filteredRelKeys);
  }


  // filter node and relationship selected
  const [nodesSelectedFromFilterState, setNodesSelectedFromFilterState] = useState<any>([]);
  const [relsSelectedFromFilterState, setRelationshipsSelectedFromFilterState] = useState<any>([]);

  // on node selected from filters
  const onNodeSelectedFromFilter = (labelKey: string) => {
    // update the state of nodeSelectedFromFilters
    if (!nodesSelectedFromFilterState.includes(labelKey)) {
      setNodesSelectedFromFilterState([...nodesSelectedFromFilterState, labelKey])
    } else {
      setNodesSelectedFromFilterState(nodesSelectedFromFilterState.filter((node: string) => node !== labelKey))
    }
    // pass the label to the parent filter function
    onNodeSelectedFromFilters(labelKey)
  }

  // on relationship selected from filters
  const onRelationshipSelectedFromFilter = (relKey: string) => {
    // update the state of nodeSelectedFromFilters
    if (!relsSelectedFromFilterState.includes(relKey)) {
      setRelationshipsSelectedFromFilterState([...relsSelectedFromFilterState, relKey])
    } else {
      setRelationshipsSelectedFromFilterState(relsSelectedFromFilterState.filter((rel: string) => rel !== relKey))
    }
    // pass the label to the parent filter function
    onRelSelectedFromFilters(relKey)
  }


  return (
    <Box sx={{ backgroundColor: "#ffffff", paddingTop: "1rem", }}>
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingX: "1.5rem",
        gap: 3,
      }}>
        <Button onClick={() => handleTabChange("nodes")} fullWidth sx={tabSelected === "nodes" ? { ...tabStyles, borderBottom: "2px solid #461E96" } : tabStyles}>Nodes</Button>
        <div style={{ height: "30px", borderLeft: "1px solid #ccc" }}></div>
        <Button onClick={() => handleTabChange("relationships")} fullWidth sx={tabSelected === "relationships" ? { ...tabStyles, borderBottom: "2px solid #461E96" } : tabStyles}>Relationships</Button>
      </Box>
      <Divider orientation="horizontal" flexItem />
      {/* Tabs content */}
      <Box sx={{ paddingTop: "2rem", paddingX: "1rem", paddingBottom: "3rem", height: "90vh", overflowY: "scroll" }}>
        {
          tabSelected === "nodes" && (
            <Box>

                  <Box sx={{ marginBottom: "2rem" }}>
                    <Input
                      type="text"
                      sx={{
                        height: "3.1rem",
                        width: "100%",
                        borderRadius: "1.5rem",
                        backgroundColor: "#F5F5F5",
                        color: "#808080",
                        padding: "1rem",
                      }}
                      disableUnderline={true}
                      value={nodesSearch}
                      placeholder="Search Nodes"
                      onChange={(e) => handleNodesChange(e)}
                      endAdornment={
                        <Button
                          variant="text"
                          sx={{
                            border: "0px",
                            borderRadius: "1.5rem",
                            backgroundColor: "#F5F5F5",
                            ":hover": { backgroundColor: "#F5F5F5" },
                            color: "#808080",
                            height: "100%"
                          }}
                        >
                          <SearchIcon />
                        </Button>
                      }
                    />
                  </Box>
                  {/* nodes  */}
              {(visibleLabelKeys && visibleLabelKeys.length > 0) && (
                <>
                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.2rem",
                    paddingBottom: "2rem"
                  }}>
                    {visibleLabelKeys && visibleLabelKeys.map((label: string) => (
                      <NodeLabel
                        key={label}
                        graphStyle={graphStyle}
                        allNodesCount={statsNodeCount}
                        selectedLabel={{
                          label,
                          propertyKeys: Object.keys(labels[label].properties),
                          count: labels[label].count,
                        }}
                        onNodeSelectedFromFilters={onNodeSelectedFromFilter}
                        nodeSelectedFromFiltersState={nodesSelectedFromFilterState}
                      />
                    ))}
                    {/* nodes count */}
                    <Box sx={{
                      height: "3.1rem",
                      width: "100%",
                      borderRadius: "1.5rem",
                      border: "1px solid #461E96",
                      backgroundColor: "#ffffff",
                      color: "#461E96",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingX: "0.7rem",
                    }}>
                      <Typography>Total Nodes</Typography>
                      <Typography>{statsNodeCount}</Typography>
                    </Box>
                  </Box>
                </>
              )}
              {(visibleLabelKeys.length === 0) && (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                  <Typography>No nodes found</Typography>
                </Box>
              )}
            </Box>
          )
        }
        {
          tabSelected === "relationships" && (
            <Box>
                  <Box sx={{ marginBottom: "2rem" }}>
                    <Input
                      type="text"
                      sx={{
                        height: "3.1rem",
                        width: "100%",
                        borderRadius: "1.5rem",
                        backgroundColor: "#F5F5F5",
                        color: "#808080",
                        padding: "1rem",
                      }}
                      disableUnderline={true}
                      value={relationshipsSearch}
                      placeholder="Search Relationships"
                      onChange={(e) => handleRelationshipsChange(e)}
                      endAdornment={
                        <Button
                          variant="text"
                          sx={{
                            border: "0px",
                            borderRadius: "1.5rem",
                            backgroundColor: "#F5F5F5",
                            ":hover": { backgroundColor: "#F5F5F5" },
                            color: "#808080",
                            height: "100%"
                          }}
                        >
                          <SearchIcon />
                        </Button>
                      }
                    />
                  </Box>
              {visibleRelationshipKeys && visibleRelationshipKeys.length > 0 && (
                <>
                  {/* relationships  */}
                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.2rem",
                    paddingBottom: "2rem"
                  }}>
                    {visibleRelationshipKeys && visibleRelationshipKeys.map((relType) => (
                      <RelType
                        key={relType}
                        graphStyle={graphStyle}
                        selectedRelType={{
                          relType,
                          propertyKeys: Object.keys(relTypes[relType].properties),
                          count: relTypes[relType].count,
                        }}
                        relsSelectedFromFilterState={relsSelectedFromFilterState}
                        onRelSelectedFromFilters={onRelationshipSelectedFromFilter}
                      />
                    ))}
                    {/* relationships count */}
                    <Box sx={{
                      height: "3.1rem",
                      width: "100%",
                      borderRadius: "1.5rem",
                      border: "1px solid #461E96",
                      backgroundColor: "#ffffff",
                      color: "#461E96",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingX: "0.7rem",
                    }}>
                      <Typography>Total Relationships</Typography>
                      <Typography>{statsRelationshipCount}</Typography>
                    </Box>
                  </Box>
                </>
              )}
              {(visibleRelationshipKeys.length === 0) && (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                  <Typography>No relationships found</Typography>
                </Box>
              )}
            </Box>
          )
        }
      </Box>
    </Box>
  );
}

export default DefaultOverviewPane;
