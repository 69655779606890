import { useNavigate } from "react-router-dom";
import { axiosinstance } from "./interceptor";
import { useEffect } from "react";
import Cookies from "js-cookie";

export const runCipherQuery = async (query: string) => {
  try {
    const dbNameCheck =
    query.trim().slice(0, 3).toLocaleLowerCase() === "use" ? query : `use ${localStorage.getItem("dbName")} ` + query;
    // : `use neo4j ` + query;
    const queryLimit = handleQueryLimit(dbNameCheck)
    const finalQuery = queryLimit;
    const res = await axiosinstance.post("/queries/execute-cipher", {
      cipher: finalQuery,
    });
    
    return res.data;
  } catch (err) {
    return {
      error: true,
      message: err?.response?.data?.message,
    };
  }
};

export const handleQueryLimit = (query: string) => {
  const maxNodes = parseInt(localStorage.getItem("maxNodes"));

  const limitRegex = /LIMIT\s+(\d+)/i;
  const match = query.match(limitRegex);

  if (match) {
    const currentLimit = parseInt(match[1], 10);
    if (currentLimit > maxNodes) {
      query = query.replace(limitRegex, `LIMIT ${maxNodes}`);
    }
  } else {
    query = query.trim();
    if (!query.endsWith(';')) {
      query += ';';
    }
    query = query.replace(/;$/, ` LIMIT ${maxNodes};`);
  }

  return query;
}

export const findUserByEmail = async (email: string) => {
  try {
    const res = await axiosinstance.get(`/users/${email}`);
    return res.data;
  } catch (err) {
    console.info("Failed to execute");
  }
};

export const createUser = async (email: string) => {
  try {
    const res = await axiosinstance.post(`/users/create-user`, { email });
    return res.data;
  } catch (err) {
    console.info("Failed to execute");
  }
};

export const createFavorite = async (payload: any) => {
  const userLoggedIn = Cookies.get("user");
  try {
    const res = await axiosinstance.post(`/favorites/add-favorite`, {
      ...payload,
      email: JSON.parse(userLoggedIn!).username,
    });
    return res.data;
  } catch (err) {
    console.info("Failed to execute");
  }
};

export const getFavoritesByEmail = async () => {
  const userLoggedIn = Cookies.get("user");
  try {
    const res = await axiosinstance.get(
      `/favorites/favorites-by-email/${JSON.parse(userLoggedIn!).username}`
    );
    
    return res.data;
  } catch (err) {
    console.info("Failed to execute");
  }
};

export const deleteFavoriteByElementId = async (elementId: string) => {
  try {
    const res = await axiosinstance.delete(
      `/favorites/delete-favorite/${elementId}`
    );
    return res.data;
  } catch (err) {
    console.info("Failed to execute");
  }
};

export const getNews = async () => {
  try {
    const res = await axiosinstance.get(`/news/allNews`);
    return res.data;
  } catch (err) {
    console.info("Failed to execute");
  }
};
