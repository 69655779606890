import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { resourceData } from '../../constants/screensData'

export default function Ontology() {
    useEffect(() =>{
        window.scrollTo(0,0);
    })
    return (
        <Box paddingTop={"1rem"} paddingLeft={"20rem"} paddingRight={"3rem"} width={"85%"} paddingBottom={"10rem"}>
            <Typography fontSize={"25px"} textAlign={"center"}>Ontology</Typography>
            <Typography marginTop={"2vh"} fontSize={"20px"}>What is Ontology</Typography>
            <Typography color={"#3b3b3b"} marginTop={"2vh"}>
                An ontology acts like a schema or blueprint for your knowledge graph. It defines:
                Entity types: What kind of "things" exist in your domain (e.g., people, products, locations). These become nodes in your graph.
                <br />
                <br />
                <b>Attributes: </b>What properties describe each entity type (e.g., name, price, address). These become node properties.
                <br />
                <b>Relationships: </b>How different entities are connected (e.g., "is-a," "part-of," "has-symptom"). These become links between nodes.
            </Typography>
            <Typography marginTop={"2vh"} fontSize={"20px"}>Benefits of a Defined Ontology</Typography>
            <Typography color={"#3b3b3b"} marginTop={"2vh"}>
                <b>Consistency: </b>Ensures everyone uses the same "language" to represent information, preventing confusion and errors.
                <br />
                <b>Interoperability: </b>Allows different systems to understand the knowledge graph's data, facilitating data sharing and collaboration.
                <br />
                <b>Reasoning: </b>Enables the graph to draw conclusions and identify inconsistencies based on defined relationships.
            </Typography>
            <Typography marginTop={"2vh"} fontSize={"20px"}>Example</Typography>
            <Typography color={"#3b3b3b"} marginTop={"2vh"}>
                Imagine a knowledge graph about Logistics. The ontology would define the manufacturers, the distribution centers and their locations with interconnected relationships between them.A Distribution Center is LOCATED_IN a CityA Manufacturer either HAS_A_FACILITY_IN a City, or IS_HEADQUARTERED_IN a City
                A Manufacturer can also be a LABELER, hence a self relationship and it SHIPS_TO a Distribution Center.
            </Typography>
            <Box display={"flex"} justifyContent={"center"}>
                <img src={resourceData.ontology} alt='ontology Img' />
            </Box>



        </Box>
    )
}
