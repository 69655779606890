import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ModalInterface {
  isGetStartedModal?: boolean;
}
const initialState: ModalInterface = {
  isGetStartedModal: false,
};

const getStartedModalReducer = createSlice({
  name: "modalReducer",
  initialState,
  reducers: {
    setGetStartedModal(state, action: PayloadAction<boolean>) {
        state.isGetStartedModal =  action.payload !== undefined ? action.payload : !state.isGetStartedModal;
    },
  },
});

export const { setGetStartedModal } =
getStartedModalReducer.actions;

export default getStartedModalReducer.reducer;
