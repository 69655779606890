import { BasicNode } from "../common";
import { has, some } from "lodash-es";
import { Duration } from "luxon";
import neo4j, {
  Record as Neo4jRecord,
  Path as Neo4jPath,
  graph,
} from "neo4j-driver";
import {
  Integer,
  Relationship,
  Path,
  Node,
  Point,
  Date,
  DateTime,
  LocalDateTime,
  LocalTime,
  Time,
  Duration as Neo4jDuration,
} from "neo4j-driver-core";
import Graph from "graphology";

type DeduplicateHelper = {
  nodes: BasicNode[];
  taken: Record<string, boolean>;
  nodeLimitHit: boolean;
};

export interface Converters {
  intChecker: (item: {}) => boolean;
  intConverter: (item: {}) => any;
  objectConverter?: (item: {}, converters: Converters) => any;
}

type NumberOrInteger = number | Integer | bigint;
interface Properties {
  [key: string]: any;
}

/** Custom functions to check the types of items */

function isRelationship<
  T extends NumberOrInteger = Integer,
  P extends Properties = Properties,
  Type extends string = string
>(obj: unknown): obj is Relationship<T, P, Type> {
  return (
    typeof obj === "object" &&
    "identity" in obj &&
    "start" in obj &&
    "end" in obj &&
    "type" in obj
  );
}

function isNode<
  T extends NumberOrInteger = Integer,
  P extends Properties = Properties,
  Label extends string = string
>(obj: unknown): obj is Node<T, P, Label> {
  return (
    typeof obj === "object" &&
    "identity" in obj &&
    "labels" in obj &&
    "properties" in obj
  );
}

function isPath<T extends NumberOrInteger = Integer>(
  obj: unknown
): obj is Path<T> {
  return (
    typeof obj === "object" &&
    "start" in obj &&
    "end" in obj &&
    "segments" in obj
  );
}

function isInteger<T extends NumberOrInteger = Integer>(
  obj: unknown
): obj is T {
  return typeof obj === "object" && "low" in obj && "high" in obj;
}

function isPoint<T extends NumberOrInteger = Integer>(
  obj: unknown
): obj is Point<T> {
  return typeof obj === "object" && "srid" in obj && "x" in obj && "y" in obj;
}

function isDate<T extends NumberOrInteger = Integer>(
  obj: unknown
): obj is Date<T> {
  return (
    typeof obj === "object" && "year" in obj && "month" in obj && "day" in obj
  );
}

function isDateTime<T extends NumberOrInteger = Integer>(
  obj: unknown
): obj is DateTime<T> {
  return (
    typeof obj === "object" &&
    "year" in obj &&
    "month" in obj &&
    "day" in obj &&
    "hour" in obj &&
    "minute" in obj &&
    "second" in obj &&
    "millisecond" in obj
  );
}

function isLocalDateTime<T extends NumberOrInteger = Integer>(
  obj: unknown
): obj is LocalDateTime<T> {
  return (
    typeof obj === "object" &&
    "year" in obj &&
    "month" in obj &&
    "day" in obj &&
    "hour" in obj &&
    "minute" in obj &&
    "second" in obj &&
    "millisecond" in obj
  );
}

function isLocalTime<T extends NumberOrInteger = Integer>(
  obj: unknown
): obj is LocalTime<T> {
  return (
    typeof obj === "object" &&
    "hour" in obj &&
    "minute" in obj &&
    "second" in obj &&
    "millisecond" in obj
  );
}

function isTime<T extends NumberOrInteger = Integer>(
  obj: unknown
): obj is Time<T> {
  return (
    typeof obj === "object" &&
    "hour" in obj &&
    "minute" in obj &&
    "second" in obj &&
    "millisecond" in obj
  );
}

function isDuration<T extends NumberOrInteger = Integer>(
  obj: unknown
): obj is Neo4jDuration<T> {
  return (
    typeof obj === "object" &&
    "months" in obj &&
    "days" in obj &&
    "seconds" in obj &&
    "nanoseconds" in obj
  );
}

/** Instance for graphology object */
let graphologyObject: Graph;

const intChecker = isInteger;
const intConverter = (val: any): string => val.toString();
const filterRels = false;
const converters = {
  intChecker,
  intConverter,
  objectConverter: extractFromNeoObjects,
};

export function extractNodesAndRelationshipsFromItemsAndFormat(
  items: any,
  maxFieldItemsToSlices: number
) {
  /** If records length is zero then return empty nodes and relationships   */
  if (items && items.length === 0) {
    return { nodes: [], relationships: [] };
  }

  /** Create a new graphology instance */
  graphologyObject = new Graph({
    multi: true,
    allowSelfLoops: true,
  });

  /** Extract rawNodes and rawRels  */
  extractRawNodesAndRelationShipsFromItems(items, maxFieldItemsToSlices);

  const nodes = [];
  let relationships = [];
  const filterRels = true;

  graphologyObject.forEachNode((node, attr) => {
    nodes.push({
      id: node,
      elementId: attr.elementId,
      labels: attr.labels,
      properties: attr.properties,
      propertyTypes: {},
    });
  });

  graphologyObject.forEachEdge((edge, attr) => {
    const rel = relationships.find(
      (r) =>
        r?.type === attr?.type &&
        r?.startNodeId === attr?.startNodeId &&
        r?.endNodeId === attr?.endNodeId
    );
    if (!rel) {
      relationships.push({
        id: edge,
        elementId: attr.elementId,
        startNodeId: attr.startNodeId,
        endNodeId: attr.endNodeId,
        type: attr.type,
        properties: attr.properties,
        propertyTypes: {},
      });
    }
  });
  return { nodes, relationships, graphologyObject };
}

export function extractRawNodesAndRelationShipsFromItems(
  items: any,
  maxFieldItems: any
) {
  const paths = new Set<any>();
  const segments = new Set<any>();

  const flatTruncatedItems = items
    .map((item) =>
      maxFieldItems && Array.isArray(item) ? item.slice(0, maxFieldItems) : item
    )
    .flat();

  const findAllEntities = (item: any) => {
    if (item && typeof item === "object") {
      if (isRelationship(item)) {
        addEdge(item.start, item.end, item);
      } else if (isNode(item)) {
        addNode(item?.identity, item);
      } else if (isPath(item)) {
        paths.add(item);
      } else {
        for (const subItem of Object.values(item)) {
          findAllEntities(subItem);
        }
      }
    }
  };

  findAllEntities(flatTruncatedItems);

  for (const path of paths) {
    if (path.start) {
      addNode(path?.start?.identity, path?.start);
    }
    if (path.end) {
      addNode(path?.end?.identity, path?.end);
    }
    for (const segment of path.segments) {
      segments.add(segment);
    }
  }

  for (const segment of segments) {
    if (segment.start) {
      addNode(segment?.start?.identity, segment?.start);
    }
    if (segment.end) {
      addNode(segment?.end?.identity, segment?.end);
    }
    if (segment.relationship) {
      addEdge(
        segment?.relationship?.start,
        segment?.relationship?.end,
        segment?.relationship
      );
    }
  }
}

/** Add node to graphlogy */
export function addNode(identity: any, data: any) {
  if (isInteger(identity)) {
    const id = identity.low.toString();
    if (!graphologyObject?.hasNode(id)) {
      const node = {
        id,
        labels: data?.labels,
        elementId: data?.elementId,
        properties: itemIntToString(data?.properties, converters),
        propertyTypes: Object.entries(data?.properties).reduce(
          (acc, [key, val]) => ({ ...acc, [key]: getTypeDisplayName(val) }),
          {}
        ),
      };
      graphologyObject.addNode(id, node);
    }
  }
}

/** Add edges to graphology */
export function addEdge(startIdentity: any, endIdentity: any, data: any) {
  if (isInteger(startIdentity) && isInteger(endIdentity)) {
    const source = startIdentity.low.toString();
    const target = endIdentity.low.toString();
    if (graphologyObject.hasNode(source) && graphologyObject.hasNode(target)) {
      // check if edge already there
      const edge = {
        id: data?.identity?.low.toString(),
        elementId: data?.elementId,
        startNodeId: source,
        endNodeId: target,
        type: data?.type,
        properties: itemIntToString(data?.properties, converters),
        propertyTypes: Object.entries(data?.properties).reduce(
          (acc, [key, val]) => ({ ...acc, [key]: getTypeDisplayName(val) }),
          {}
        ),
      };
      graphologyObject.addEdge(source, target, edge);
    }
  }
}

export function itemIntToString(item: any, converters: Converters): any {
  const res = stringModifier(item);
  if (res) return res;
  if (converters.intChecker(item)) return converters.intConverter(item);
  if (Array.isArray(item)) return arrayIntToString(item, converters);
  if (["number", "string", "boolean"].indexOf(typeof item) !== -1) return item;
  if (item === null) return item;
  if (typeof item === "object") return objIntToString(item, converters);
}

export const stringModifier = (anything: any) => {
  if (typeof anything === "number") {
    return numberFormat(anything);
  }
  if (isInteger(anything)) {
    return anything.low.toString();
  }
  if (isPoint(anything)) {
    return spacialFormat(anything);
  }
  if (isTemporalType(anything)) {
    if (isDuration(anything)) {
      return durationFormat(anything);
    } else {
      return `"${anything.toString()}"`;
    }
  }
  return undefined;
};

export function arrayIntToString(arr: {}[], converters: Converters) {
  return arr.map((item) => itemIntToString(item, converters));
}

export function objIntToString(obj: any, converters: any) {
  const entry = converters.objectConverter(obj, converters);
  let newObj: any = null;
  if (Array.isArray(entry)) {
    newObj = entry.map((item) => itemIntToString(item, converters));
  } else if (entry !== null && typeof entry === "object") {
    newObj = {};
    Object.keys(entry).forEach((key) => {
      newObj[key] = itemIntToString(entry[key], converters);
    });
  }
  return newObj;
}

const numberFormat = (anything: any) => {
  // Exclude false positives and return early
  if ([Infinity, -Infinity, NaN].includes(anything)) {
    return `${anything}`;
  }
  if (Math.floor(anything) === anything) {
    return `${anything}.0`;
  }
  return undefined;
};

const spacialFormat = (anything: any): string => {
  const zString = anything.z !== undefined ? `, z:${anything.z}` : "";
  return `point({srid:${anything.srid}, x:${anything.x}, y:${anything.y}${zString}})`;
};

const isTemporalType = (anything: any) =>
  isDate(anything) ||
  isDateTime(anything) ||
  isLocalDateTime(anything) ||
  isLocalTime(anything) ||
  isTime(anything) ||
  isDuration(anything);

export const durationFormat = (duration: any): string =>
  Duration.fromISO(duration.toString())
    .shiftTo("years", "days", "months", "hours", "minutes", "seconds")
    .normalize()
    .toISO();

export function extractFromNeoObjects(obj: any, converters: Converters) {
  if (isNode(obj) || isRelationship(obj)) {
    return obj.properties;
  } else if (isPath(obj)) {
    return [].concat.apply([], extractPathForRows(obj, converters));
  }
  return obj;
}

const extractPathForRows = (path: Neo4jPath, converters: Converters) => {
  let segments = path.segments;
  // Zero length path. No relationship, end === start
  if (!Array.isArray(path.segments) || path.segments.length < 1) {
    segments = [{ ...path, end: null } as any];
  }
  return segments.map((segment: any) =>
    [
      objIntToString(segment.start, converters),
      objIntToString(segment.relationship, converters),
      objIntToString(segment.end, converters),
    ].filter((part) => part !== null)
  );
};

export const getTypeDisplayName = (val: any): string => {
  const jsType = typeof val;
  const complexType = jsType === "object";

  if (jsType === "number") {
    return "Float";
  }

  if (!complexType) {
    return upperFirst(jsType);
  }

  if (val instanceof Array) {
    return `Array(${val.length})`;
  }

  if (val === null) {
    return "null";
  }

  return getDriverTypeName(val) || "Unknown";
};

export const upperFirst = (str: string): string =>
  str[0].toUpperCase() + str.slice(1);

const getDriverTypeName = (val: any) => {
  if (isNode(val)) {
    return "Node";
  }
  if (isRelationship(val)) {
    return "Relationship";
  }
  if (isPath(val)) {
    return "Path";
  }
  if (isPoint(val)) {
    return "Point";
  }
  if (isDate(val)) {
    return "Date";
  }
  if (isDateTime(val)) {
    return "DateTime";
  }
  if (isLocalDateTime(val)) {
    return "LocalDateTime";
  }
  if (isLocalTime(val)) {
    return "LocalTime";
  }
  if (isTime(val)) {
    return "Time";
  }
  if (isDuration(val)) {
    return "Duration";
  }
  if (isInteger(val)) {
    return "Integer";
  }
  return undefined;
};

export const deduplicateNodes = (
  nodes: BasicNode[],
  limit: number
): { nodes: BasicNode[]; nodeLimitHit: boolean } =>
  nodes &&
  nodes.reduce(
    (all: DeduplicateHelper, curr: BasicNode) => {
      if (all.nodes.length === limit) {
        all.nodeLimitHit = true;
      } else if (!all.taken[curr.id]) {
        all.nodes.push(curr);
        all.taken[curr.id] = true;
      }
      return all;
    },
    { nodes: [], taken: {}, nodeLimitHit: false }
  );

export const resultHasTruncatedFields = (result: any, maxFieldItems: any) => {
  if (!maxFieldItems || !result) {
    return false;
  }
  return some(result.records, (record) =>
    some(record.keys, (key) => {
      const val = record.get(key);

      return Array.isArray(val) && val.length > maxFieldItems;
    })
  );
};
