import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import SplashScreen from "./pages/splash/SplashScreen";
import { appTheme } from "./themes/appTheme";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./utils/msalUtils";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <SplashScreen />
      </ThemeProvider>
    </StyledEngineProvider>
  </MsalProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
