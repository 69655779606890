import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface SideBarInterface {
  isFilterOpen?: boolean;
  isRunQueryOpen?: boolean;
  isFavoritesOpen?: boolean;
  isSettingsOpen?: boolean;
  isQueryBuildersOpen?: boolean;
  filterPanelWidth?: number;
  isOntologyOpen?: boolean;
}
const initialState: SideBarInterface = {
  isFilterOpen: false,
  isRunQueryOpen: false,
  isFavoritesOpen: false,
  isSettingsOpen: false,
  isQueryBuildersOpen: false,
  isOntologyOpen: false,
};

const sidebarReducer = createSlice({
  name: "sidebarReducer",
  initialState,
  reducers: {
    setFilterOpen(state, action: PayloadAction<boolean>) {
      state.isFilterOpen =  action.payload !== undefined ? action.payload : !state.isFilterOpen;
    },
    setRunQueryOpen(state, action: PayloadAction<boolean>) {
      state.isRunQueryOpen =
        action.payload !== undefined ? action.payload : !state.isRunQueryOpen;
    },
    setFavoritesOpen(state, action: PayloadAction<boolean>) {
      state.isFavoritesOpen =
        action.payload !== undefined ? action.payload : !state.isFavoritesOpen;
    },
    setSettingsOpen(state, action: PayloadAction<boolean>) {
      state.isSettingsOpen =
        action.payload !== undefined ? action.payload : !state.isSettingsOpen;
    },
    setFilterPanelWidth(state, action: PayloadAction<number>) {
      state.filterPanelWidth = action.payload;
    },
    setQueryBuilderOpen(state, action: PayloadAction<boolean>) {
      state.isQueryBuildersOpen =
        action.payload !== undefined ? action.payload : !state.isQueryBuildersOpen;
    },
    setOntologyOpen(state, action: PayloadAction<boolean>) {
      state.isOntologyOpen =
        action.payload !== undefined ? action.payload : !state.isOntologyOpen;
    }
  },
});

export const { setFilterOpen, setRunQueryOpen, setFavoritesOpen, setSettingsOpen,setFilterPanelWidth,setQueryBuilderOpen, setOntologyOpen } =
  sidebarReducer.actions;

export default sidebarReducer.reducer;
