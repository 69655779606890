import { Box, Fab, IconButton, Modal, Tooltip, Typography } from "@mui/material"
import { useState, useMemo, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/hook";
import RunQueryModal from "../../../components/runQueryModal/RunQueryModal";
import { BasicNode, BasicRelationship } from "../../../common";
import { setGraphNodes, setGraphRelationships } from "../../../redux/reducers/graphReducer";
import ConnectedGraphVisualizer from "../../../components/graphVisualization/GraphVisualizer/ConnectedGraphVisualizer";
import { GraphStyleModel } from "../../../components/graphVisualization";
import { runCipherQuery } from "../../../api/api";
import { setFilterOpen, setRunQueryOpen, setQueryBuilderOpen, setOntologyOpen } from "../../../redux/reducers/sidebarReducer";
import SearchInput from "../../../components/searchInput/SearchInput";
import Favorites from "../../../components/favorites/Favorites";
import { extractNodesAndRelationshipsFromItemsAndFormat, deduplicateNodes } from "../../../helpers/neo4jFormat";
import Graph from "graphology";
import Settings from "../../../components/settings/settings";
import { Toaster } from "react-hot-toast";
import { CustomToast } from "../../../helpers/customToast";
import QueryBuilder from "../../../components/queryBuilder/QueryBuilder";
import { fabIcon, ontologyGraphData } from "../../../constants/screensData";
import CloseIcon from '@mui/icons-material/Close';
import OntologyModal from "../../../components/ontologyModal/OntologyModal";
import { setTableData } from "../../../redux/reducers/tableDataReducer";


const GraphScreen = () => {
  const [nodes, setNodes] = useState<BasicNode[]>([]);
  const [relationships, setRelationships] = useState<BasicRelationship[]>([]);
  const dispatch = useAppDispatch()
  const [intialGraphReset, setIntialGraphReset] = useState<boolean>(false)
  const [queryRunning, setQueryRunning] = useState<boolean>(false)
  const [selectedFavQuery, setSelectedFavQuery] = useState<string>('')
  const [graphologyObject, setGraphologyObject] = useState<Graph>()
  const [autoCompleteRel, setAutoCompleteRel] = useState<boolean>(true)
  const [isFavoriteDisabled, setIsFavoriteDisabled] = useState<boolean>(true);

  // get the store value using app selector 
  const isFilterOpen = useAppSelector((state) => state.sidebarReducer.isFilterOpen)
  const isFavoritesOpen = useAppSelector((state) => state.sidebarReducer.isFavoritesOpen)
  const isSettingsOpen = useAppSelector((state) => state.sidebarReducer.isSettingsOpen)
  const isQueryBuilderOpen = useAppSelector((state) => state.sidebarReducer.isQueryBuildersOpen)
  const intialNodes = useAppSelector((state) => state.graphReducer.graphNodes)
  const intialRelationships = useAppSelector((state) => state.graphReducer.graphRelationships)
  const isOntologyOpen = useAppSelector((state) => state.sidebarReducer.isOntologyOpen)

  // method to serialize the data and update store with intial data
  const intialGraph = async (apiNodes: any, apiRelations: any) => {
    if (apiNodes) {
      const { nodes: uniqNodes, nodeLimitHit } = deduplicateNodes(
        apiNodes,
        1000
      )
      const uniqRels = nodeLimitHit
        ? apiRelations.filter(
          rel =>
            !!uniqNodes.find(node => node.id === rel.startNodeId) &&
            !!uniqNodes.find(node => node.id === rel.endNodeId)
        )
        : apiRelations
      setNodes(uniqNodes)
      setRelationships(uniqRels)

      // update the store with nodes and relationships
      dispatch(setGraphNodes(apiNodes))
      dispatch(setGraphRelationships(apiRelations))
    }
  }



  // If all the filter nodes are deselected then reset the graph
  useEffect(() => {
    if (intialGraphReset) {
      setNodes(intialNodes)
      setRelationships(intialRelationships)
      setIntialGraphReset(false)
    }
  }, [intialGraphReset])


  const updateGraphStyle = (updatedGraphStyle: GraphStyleModel) => { }


  const onClickRun = async (cipherQuery: string) => {
    setQueryRunning(true)
    const apiResult = await runCipherQuery(cipherQuery)
    if (!apiResult.graphData?.error) {
      if (apiResult.graphData && apiResult.graphData.length > 0) {
        const res = extractNodesAndRelationshipsFromItemsAndFormat(apiResult.graphData, 500)
        intialGraph(res?.nodes, res?.relationships)
        setGraphologyObject(res?.graphologyObject)
        // show the filters panel as default
        dispatch(setFilterOpen(true))
        setIsFavoriteDisabled(false)
        dispatch(setTableData(apiResult.tableData))

      } else {
        CustomToast('No data found', {
          type: "error",
          style: {
            maxHeight: "300px",
            overflowY: "scroll",
          }
        })
      }
    } else {
      CustomToast(apiResult.graphData?.message, {
        type: "error",
        style: {
          maxHeight: "300px",
          overflowY: "scroll",
        }
      })
    }
    // make the run button loader to false and close the run modal
    setQueryRunning(false)
    dispatch(setRunQueryOpen(false))
    dispatch(setQueryBuilderOpen(false))
    dispatch(setOntologyOpen(false))


  }
  const handleOntologyOpen = () => {
    dispatch(setOntologyOpen(true))
  }

  const handleIsFavoriteDisabled = () => {
    setIsFavoriteDisabled(true)
  }

  const fabButtonStyle = {
    position: "absolute",
    right: "3rem",
    bottom: "2rem",
    backgroundColor: "#fff",
    zIndex: "2000",
    boxShadow: "2px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px -5px rgba(0,0,0,0.14), 2px 1px 18px 0px rgba(0,0,0,0.12)"
  }


  return (
    <>
      <Box position="absolute"
        top={80}
        left={isFavoritesOpen || isFilterOpen ||  isSettingsOpen ? 400 : 0}
        zIndex={2}
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <SearchInput handleOnClickRun={onClickRun} queryRunning={queryRunning} setSelectedFavQuery={setSelectedFavQuery} />
      </Box>
      <Box mt={2} height="90vh" >
        <ConnectedGraphVisualizer
          key={`${nodes.length}-${relationships.length}`}
          relationships={relationships}
          nodes={nodes}
          autocompleteRelationships={autoCompleteRel}
          isFullscreen={true}
          initialZoomToFit
          nodePropertiesExpandedByDefault={isFilterOpen}
          updateStyle={(undefined)}
          assignVisElement={() => undefined}
          getAutoCompleteCallback={() => undefined}
          setGraph={undefined}
          hasTruncatedFields={false}
          setNodePropertiesExpandedByDefault={() => undefined}
          wheelZoomInfoMessageEnabled={false}
          disableWheelZoomInfoMessage={() => undefined}
          useGeneratedDefaultColors={false}
          nodeLimitHit={false}
          graphologyObject={graphologyObject}
        />

      </Box>

      {/* Modal for run query */}
      <RunQueryModal onSubmitRun={onClickRun} isFavoriteDisabled={isFavoriteDisabled} onChangeQuery={handleIsFavoriteDisabled} selectedFavQuery={selectedFavQuery} queryRunning={queryRunning} />
      <Favorites isFavoritesOpen={isFavoritesOpen} setSelectedFavQuery={setSelectedFavQuery} />
      <Settings isSettingsOpen={isSettingsOpen} />
      <QueryBuilder isQueryBuilderOpen={isQueryBuilderOpen} setSelectedFavQuery={setSelectedFavQuery} />
      <OntologyModal isOntologyOpen={isOntologyOpen} />
      <Tooltip title="Ontology" placement="top-start" arrow>
        <Fab size="medium" aria-label="Ontology" sx={fabButtonStyle} onClick={handleOntologyOpen} >
          <img src={fabIcon.ontologyIcon} />
        </Fab>
      </Tooltip>
      <Toaster />
    </>
  )
}

export default GraphScreen 