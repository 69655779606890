import React, { useState } from 'react'
import Home from './Home'
import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { path } from '../constants/routes';

export default function HomeScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  // const [user, setUser] = React.useState<AccountInfo>();
  let user: AccountInfo;
  const { instance, accounts } = useMsal();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);


  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onClickLogout = () => {
    setAnchorElUser(null);
    sessionStorage.clear();
    navigate("/login");
  };
  const isCurrentPath = () => {
    return location.pathname === path || location.pathname === `${path}/`;
  };

  const getUser = async () => {
    const account = accounts[0] || instance.getActiveAccount();
    user = account;
  };

  getUser();
  return (
    <Home
      handleOpenUserMenu={handleOpenUserMenu}
      anchorElUser={anchorElUser}
      handleCloseUserMenu={handleCloseUserMenu}
      onClickLogout={onClickLogout}
      userAccountInfo={user}
      open={false}
      isDrawerOpen={undefined}
      handleDrawerOpen={undefined}
      handleDrawerClose={undefined}
      isCurrentPath={isCurrentPath()}
      headerText={location.pathname} />
  )
}
