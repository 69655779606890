import React from 'react';
import Header from '../header';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { setTabIndex } from '../../redux/reducers/tabReducer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hook';
import KnowledgeGraph from './KnowledgeGraph';
import CustomizeCypher from './CustomizeCypher';
import Ontology from './Ontology';
import SemanticLayer from './SemanticLayer';

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function Resources() {
    const dispatch = useAppDispatch();
    const tabIndex = useAppSelector((state) => state.tabReducer.tabIndex);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setTabIndex(newValue));
    };

    return (
        <>
            <Header />
            <Box sx={{ display: 'flex', position: "absolute", top: "9vh", background: "#fff", left: 0, right: 0 }}>
                <Tabs
                    orientation="vertical"
                    value={tabIndex} // Controlled by tabIndex
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{
                        borderRight: 1,
                        borderColor: 'divider',
                        minWidth: '18rem',
                        position: 'fixed',
                        '& .MuiButtonBase-root.Mui-selected': {
                            backgroundColor: "#DDDBF7",
                            fontWeight: "bold"
                        },
                        '& .MuiTabs-indicator': {
                            width: "3px"
                        },
                    }}
                >
                    <Typography variant="h4" padding={"2vh"} fontSize={"25px"}>Resources</Typography>

                    <Tab sx={{
                        textTransform: "none",
                        alignItems: "flex-start",
                        fontSize: "18px"
                    }}
                        label="About knowledge graph" {...a11yProps(1)} />
                    <Tab sx={{
                        textTransform: "none",
                        fontSize: "18px",
                        alignItems: "flex-start",
                    }}
                        label="Customize a Cypher query" {...a11yProps(2)} />
                    <Tab sx={{
                        textTransform: "none",
                        fontSize: "18px",
                        alignItems: "flex-start",
                    }}
                        label="What is Ontology" {...a11yProps(3)} />
                    <Tab sx={{
                        textTransform: "none",
                        fontSize: "18px",
                        alignItems: "flex-start",
                    }}
                        label="What is Semantic Layer" {...a11yProps(4)} />
                </Tabs>

                {(tabIndex === 1) && <KnowledgeGraph />}
                {(tabIndex === 2) && <CustomizeCypher />}
                {(tabIndex === 3) && <Ontology />}
                {(tabIndex === 4) && <SemanticLayer />}
            </Box>
        </>
    )
}
