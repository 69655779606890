import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import sidebarReducer from "../reducers/sidebarReducer";
import graphReducer from "../reducers/graphReducer";
import tabReducer from "../reducers/tabReducer";
import getStartedModalReducer from "../reducers/getStartedModalReducer";
import userInfoReducer from "../reducers/userInfoReducer";
import tableDataReducer from "../reducers/tableDataReducer";

export const store = configureStore({
  reducer: {
    sidebarReducer,
    graphReducer,
    tabReducer,
    getStartedModalReducer,
    userInfoReducer,
    tableDataReducer
    
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
