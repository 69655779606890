import { logInScreenData } from "../../../constants/screensData";

export const styles = {
  container: {
    height: "100vh",
    width: "100%",
    background: `url(${logInScreenData.backgroundImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  logo: {
    width: "10rem",
    height: "5.132rem",
    marginLeft: "2vh"


  },
};
