import { Box, Button, Card, CardContent, Divider, Grid, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { headerData, homeData, logInScreenData } from '../constants/screensData'
import { styles } from './Home.styles'
import { Logout } from '@mui/icons-material'
import { HeaderContentProps } from '../@types/components'
import PersonIcon from '@mui/icons-material/Person';
import './home.css';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { getNews } from '../api/api'
import { useDispatch } from 'react-redux'
import { setGetStartedModal } from '../redux/reducers/getStartedModalReducer'
import Skeleton from '@mui/material/Skeleton';


export default function Home({
    handleOpenUserMenu,
    anchorElUser,
    handleCloseUserMenu,
    onClickLogout,
    userAccountInfo,
}: HeaderContentProps) {

    const [step, setStep] = useState(0);
    const [news, setNews] = useState([]);
    const [isNewsLoading, setIsNewsLoading] = useState<boolean>(true);
    const numSteps = 5;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleNextStep = () => {
        if (step < numSteps - 1) {
            setStep(step + 1);
        }
        else {
            setStep(0)
        }
    };

    const handlePreviousStep = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };
    const handleGetStarted = () => {
        navigate('/views');
        dispatch(setGetStartedModal(true));
    }
    const handleAreaOfInterest = () => {
        navigate('/views');
        dispatch(setGetStartedModal(false));
    };

    useEffect(() => {
        const getNewsData = async () => {
            setIsNewsLoading(true);
            try {
                const newsData = await getNews();
                setNews(newsData.articles);
                setIsNewsLoading(false);
            } catch (err) {
                // Handle errors if needed
                console.error("Error fetching news data:", err);
                setIsNewsLoading(true);

            } 
            // finally {
            //     setIsNewsLoading(false);
            // }
        };
        getNewsData(); 
    }, []);
    

    useEffect(() => {
        const timer = setTimeout(() => {
            handleNextStep();
        }, 4000);

        return () => clearTimeout(timer)
    }, [step])



    return (
        <Box sx={styles.container} >
            <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                <Box display={'flex'} alignItems={'center'} gap={"2vh"}>
                    <img src={homeData.logo} alt="logo" style={styles.logo} />
                    <div style={{ height: "30px", borderLeft: "1px solid #ccc", marginLeft: "10px" }}></div>
                    <Typography color={'#fff'} fontSize={"22px"}>Graph Explorer</Typography>
                </Box>
                <PersonIcon sx={{ color: "#fff", fontSize: "1.7rem", cursor: "pointer" }} onClick={handleOpenUserMenu} />
                <Menu
                    sx={{ mt: "54px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem onClick={handleCloseUserMenu}>
                        <img
                            src={headerData.userIcon}
                            alt="icon"
                            style={styles.userIcon}
                        />{" "}
                        <Box ml={1}>
                            <Typography color="text.primary">
                                {userAccountInfo?.name}
                            </Typography>
                            <Typography color="grey" fontSize={12}>
                                {userAccountInfo?.username}
                            </Typography>
                        </Box>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        onClick={onClickLogout}
                        sx={{ background: "#fff", pl: 3 }}
                    >
                        <Logout fontSize="small" />
                        <Typography ml={1} color={"text.primary"} fontSize={12}>
                            {headerData.dropdown.logOut}
                        </Typography>
                    </MenuItem>
                </Menu>
            </Toolbar>
            <Box paddingLeft={"10vh"} paddingRight={"10vh"} display={"flex"} flexDirection={"column"} >

                <Box display={'flex'} flexDirection={'column'} gap={'5vh'} marginTop={"3vh"} letterSpacing={"1px"}>
                    <Typography color={"#fff"} fontSize={"25px"}>
                        Welcome {userAccountInfo?.name}
                    </Typography>
                    <Box>
                        <Typography color={"#fff"} fontSize={"25px"} letterSpacing={"1px"}>
                            From Knowledge graphs to smarter & faster business decisions
                        </Typography>
                        <Typography color={"#fff"} fontSize={"18px"}>
                            Empower your enterprise with graph analytics
                        </Typography>
                    </Box>
                    <Button sx={{
                        background: "linear-gradient(90deg, #FF05E6 0.06%, #4400D6 98.7%)",
                        width: "29vh",
                        color: "#fff",
                        textTransform: "none",
                        borderRadius: "30px",
                    }}
                        onClick={handleGetStarted}>
                        Get Started
                    </Button>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '2rem',
                    paddingTop: '6rem',
                    '@media (min-width: 768px)': {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'normal',
                    }
                }}>
                    <Box sx={{ flex: '1', minWidth: 0 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Box sx={styles.linkBox}>Semantic Layer</Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={styles.linkBox} onClick={() => navigate('/resource')}>Resources</Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={styles.linkBox} onClick={handleAreaOfInterest}>Area of Interest</Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ flex: '2', minWidth: 0, display: "flex", justifyContent: "flex-end" }}>
                        <Card sx={{
                            background: "rgba(255, 255, 255, 0.15)",
                            backdropFilter: "blur(7.5px)",
                            width: "100%",
                            maxWidth: "37rem",
                            '@media (min-width: 768px)': {
                                width: 'auto',
                            }
                        }}>
                            <CardContent sx={{ paddingBottom: '16px !important', borderRadius: "5px", }}>
                                <Box display={"flex"} gap={"1rem"} alignItems={"center"}>
                                    <img src={homeData.newsIcon} alt='newsIcon' style={{ height: "3rem", marginLeft: "-2vh" }} />
                                    <Typography color={"#fff"} fontSize={"1.25rem"}>News Feature</Typography>
                                </Box>

                                <Box sx={{ minHeight: "12rem", minWidth: "35rem" }}>
                                    {isNewsLoading ?
                                        <>
                                            <br />
                                            <Skeleton variant="rectangular" height={50} sx={{ bgcolor: 'rgba(255, 255, 255, 0.15)' }} />
                                            <br />
                                            <Skeleton width="75%" sx={{ bgcolor: 'rgba(255, 255, 255, 0.15)' }} />
                                            <br />
                                            <Skeleton width="30%" sx={{ bgcolor: 'rgba(255, 255, 255, 0.15)' }} />
                                        </>
                                        :
                                        <>
                                        <Box>
                                            {news.map((newsItem, index) => (
                                                <Box key={index} sx={{ display: step === index ? 'block' : 'none' }}>
                                                    <Typography id="headline" color={"#fff"} fontSize={"1.25rem"} marginTop={"1rem"}>
                                                        {newsItem.title}
                                                    </Typography>
                                                    <Typography id="author-pub name" color={"#fff"} fontSize={"1rem"} marginTop={"1rem"}>
                                                        By {newsItem.source.name} &nbsp; {new Date(newsItem.publishedAt).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric', })}
                                                    </Typography>
                                                    <Button variant='text' href={newsItem.url} target="_blank" sx={{ color: "#fff !important", textTransform: "none", padding: "0", marginTop: "1rem" }}>
                                                        Read More
                                                    </Button>
                                                </Box>
                                            ))}
                                        </Box><Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                                <IconButton disabled={step === 0} onClick={handlePreviousStep}>
                                                    <ArrowBackIosNewRoundedIcon sx={{ color: '#8C7ED7', fontSize: "1.5rem" }} />
                                                </IconButton>
                                                <Box display={"flex"}>
                                                    {[...Array(numSteps)].map((_, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                width: 7,
                                                                height: 7,
                                                                borderRadius: '100%',
                                                                backgroundColor: index === step ? '#fff' : '#8C7ED7',
                                                                margin: '0 5px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => setStep(index)} />
                                                    ))}
                                                </Box>
                                                <IconButton disabled={step === numSteps - 1} onClick={handleNextStep}>
                                                    <ArrowForwardIosRoundedIcon sx={{ color: '#8C7ED7', fontSize: "1.5rem" }} />
                                                </IconButton>
                                            </Box>
                                            </>
                                    }
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>

            </Box>

        </Box>
    )
}
