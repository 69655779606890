/**
 * HeaderContent Component
 *
 * The `HeaderContent` component represents the content of the header section in the application.
 * It includes the app logo, title, notification and question icons, and user profile icon with user menu.
 *
 * @param {Object} props - Component props
 * @param {boolean} props.open - Indicates whether the sidebar is open or closed.
 * @param {Function} props.handleDrawerOpen - Function to handle opening the sidebar.
 * @param {Function} props.handleOpenUserMenu - Function to handle opening the user menu.
 * @param {HTMLElement} props.anchorElUser - The anchor element for the user menu.
 * @param {Function} props.handleCloseUserMenu - Function to handle closing the user menu.
 * @param {Function} props.onClickLogout - Function to handle logout button click.
 * @param {boolean} props.isCurrentPath - Indicates whether the current path matches the main path.
 *
 * @returns {React.ReactNode} The `HeaderContent` component.
 */

import {
  Box,
  CssBaseline,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, styles } from "./header.styles";
import { headerData } from "../../constants/screensData";
import { Logout } from "@mui/icons-material";
import { HeaderContentProps } from "../../@types/components";
import { useNavigate } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import './header.module.scss'
const HeaderContent = ({
  open,
  isDrawerOpen,
  handleDrawerOpen,
  handleDrawerClose,
  handleOpenUserMenu,
  anchorElUser,
  handleCloseUserMenu,
  onClickLogout,
  isCurrentPath,
  headerText,
  userAccountInfo,
}: HeaderContentProps) => {
  const navigate = useNavigate()
  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ borderBottom: "3px solid #461E96" }}>
        <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
          <IconButton
            color="inherit"
            edge="start"
            sx={{ mr: 2 }}
            onClick={isDrawerOpen ? handleDrawerClose : handleDrawerOpen}
          >
            {isDrawerOpen ? <CloseIcon sx={{ color: "#461E96", fontSize: "1.7rem" }} /> : <MenuIcon sx={{ color: "#461E96", fontSize: "1.7rem" }} />}
          </IconButton>

          <img src={headerData.logo} onClick={() => navigate("/home")} alt="logo" style={styles.logo} />
          <div style={{ height: "30px", borderLeft: "1px solid #ccc", marginLeft: "10px" }}></div>
          <Typography
            color="text.primary"
            fontSize={15}
            ml={1}
            sx={{ flexGrow: 1 }}
            fontWeight={600}
          >
            Graph Explorer
          </Typography>
          <Box display={"flex"} alignItems={"center"}>
            <Box>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography color="text.primary">Hello, {userAccountInfo?.name.split(',')[1]}</Typography>
                <PersonIcon sx={{ color: "#461E96", fontSize: "1.7rem", cursor: "pointer" }} onClick={handleOpenUserMenu} />
              </Box>
              <Menu
                sx={{ mt: "54px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu}>
                  <img
                    src={headerData.userIcon}
                    alt="icon"
                    style={styles.userIcon}
                  />{" "}
                  <Box ml={1}>
                    <Typography color="text.primary">
                      {userAccountInfo?.name}
                    </Typography>
                    <Typography color="grey" fontSize={12}>
                      {userAccountInfo?.username}
                    </Typography>
                  </Box>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={onClickLogout}
                  sx={{ background: "#fff", pl: 3 }}
                >
                  <Logout fontSize="small" />
                  {/* <img
                    src={headerData.dropdown.logoutIcon}
                    alt="icon"
                    style={styles.logoutIcon}
                  /> */}

                  <Typography ml={1} color={"text.primary"} fontSize={12}>
                    {headerData.dropdown.logOut}
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default HeaderContent;
