import axios from "axios";
import { msalInstance } from "../utils/msalUtils";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export const axiosinstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const addAuthTokenInterceptor = () => {
  axiosinstance.interceptors.request.use(async (config) => {
    try {
      let loginToken = Cookies.get("loginToken");
      if (!loginToken) {
        throw new Error("No login token found");
      }
      const decodedToken = jwtDecode(loginToken);
      const expiry = decodedToken.exp * 1000; // Convert to milliseconds
      
      if (Date.now() > expiry) {
        const newAccessToken = await getNewAccessToken();
        if (newAccessToken) {
          Cookies.set("loginToken", newAccessToken);
          config.headers.Authorization = `Bearer ${newAccessToken}`;
        } else {
          window.location.href = "/login"; // Redirect to login page on error
          throw new Error("Failed to refresh access token");
        }
      } else {
        config.headers.Authorization = `Bearer ${loginToken}`;
      }
    } catch (error) {
      console.error('Interceptor error:', error);
      window.location.href = "/login"; // Redirect to login page on error
    }
    
    
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

};

const getNewAccessToken = async () => {
  try {
    const scopes = ["User.Read"];
    const accounts = msalInstance.getAllAccounts();    
    const silentRequest = {
      scopes: scopes,
      account: accounts[0],
      forceRefresh: false,
    };
    
    const { idToken } = await msalInstance.acquireTokenSilent(silentRequest);
    return idToken;
  } catch (error) {
    console.error('Token refresh error:', error);
    return null;
  }
};

addAuthTokenInterceptor();




