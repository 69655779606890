import { useMsal } from "@azure/msal-react";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

const AuthGuard = ({ children }: { children: ReactNode }) => {
  const { instance, accounts } = useMsal();
  const account = accounts[0] || instance.getActiveAccount();
  return account ? <>{children}</> : <Navigate to="/login" />;
};

export default AuthGuard;
