import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import RouteIcon from '@mui/icons-material/Route';
import CircleIcon from '@mui/icons-material/Circle';
import InfoIcon from '@mui/icons-material/Info';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

const menuItems = [
  {
    name: "Find Neighbours",
    icon: <AcUnitIcon />,
  },
  {
    name: "Find Shortest Path",
    icon: <RouteIcon />,
  },
  // {
  //   name: "Hide Node",
  //   icon: <CircleIcon />
  // },
  {
    name: "Collapse Nodes",
    icon: <GroupWorkIcon />
  },
  {
    name: "Reset Collapse",
    icon: <WorkspacesIcon />
  }
]



function ContextMenuBox({ x, y, node, onClickContextItemClick }) {

  return <div style={
    {
      position: 'absolute',
      top: `${y}px`,
      left: `${x}px`,
      backgroundColor: 'white',
      boxShadow:"0px 5px 18px -8px rgba(0,0,0,0.75)",
      fontFamily:"AbGilroy Medium",
      borderRadius: '10px',
      zIndex: 100,
    } 
  }>
    <List sx={{
      alignItems: "flex-start"
    }}>
      {
        menuItems.map((item, index) => {
          return <ListItem key={index} disablePadding>
            <ListItemButton alignItems='center' onClick={() => (onClickContextItemClick(item?.name))} sx={{
              px: 3,
              borderBottom: index === menuItems.length - 1 ? "none" : "0.5px solid lightgrey",
            }} >
              <ListItemIcon sx={{
                px: 0
              }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText sx={{
                fontWeight: "normal",
                px: 0
              }} primary={item.name} />
            </ListItemButton>
          </ListItem>
        })
      }
    </List>
  </div>;
}

export default ContextMenuBox;
