import { createTheme } from "@mui/material";
const abgilroyBoldWebfont =
  "https://unpkg.com/@abds/styles@3.2.2/src/fonts/abgilroy-bold-webfont.woff2";
const abgilroySemiboldWebfont =
  "https://unpkg.com/@abds/styles@3.2.2/src/fonts/abgilroy-semibold-webfont.woff2";
const abgilroyMediumWebfont =
  "https://unpkg.com/@abds/styles@3.2.2/src/fonts/abgilroy-medium-webfont.woff2";

  const abgilroyRegularWebfont =
  "https://unpkg.com/@abds/styles@3.2.2/src/fonts/abgilroy-regular-webfont.woff2";
  
//Montserrat

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      success: string;
      danger: string;
      info: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      success?: string;
      danger?: string;
      info?: string;
    };
  }
}

export const appTheme = createTheme({
  palette: {
    primary: {
      main: "#461E96",
      contrastText: "#fff",
      light: "#0DC7FA",
    },
    secondary: {
      main: "#ffb875",
      light: "#F2F2F2",
      contrastText: "#1A1A1A",
    },
    success: {
      main: "#41FF00",
      light: "#EEFFE9",
    },
    info: {
      main: "#2B52DD",
      dark: "#064088",
      light: "#607DE6",
    },
    warning: {
      main: "#FFF3C9",
    },
    error: {
      main: "#BF2032",
      light: "#b518451a",
    },
    background: {
      // default: "#F1F3F9",
      default: "#FFFFFF",

    },
    text: {
      primary: "#461E96",
      secondary: "#636363",
    },
    grey: {
      50: "#E2E6E9",
      900: "#313131",
    },
    divider: "#E2E6E9",
  },
  typography: {
    fontFamily: ["AbGilroy Medium"].join(","),
    fontWeightRegular: 500,
  },
  status: {
    success: "#EEFFE9",
    danger: "#FFE9E9",
    info: "#607DE6",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": [
          {
            fontFamily: "AbGilroy",
            fontStyle: "regular",
            fontWeight:100,
            src: `
              url(${abgilroyRegularWebfont}) format('woff2')
            `,
          },
          {
            fontFamily: "AbGilroy",
            fontStyle: "normal",
            fontWeight:100,
            src: `
              url(${abgilroyMediumWebfont}) format('woff2')
            `,
          },
          {
            fontFamily: "AbGilroy",
            fontStyle: "semibold",
            fontWeight:300,
            src: `
              url(${abgilroySemiboldWebfont}) format('woff2')
            `,
          },
          {
            fontFamily: "AbGilroy",
            fontStyle: "bold",
            fontWeight:500,
            src: `
              url(${abgilroyBoldWebfont}) format('woff2')
            `,
          },
        ],
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1rem",
          color: "#ffffff",
          backgroundColor: "#461E96",
          zIndex: 10000,
        },
      },
    },
  },
});
