import pattern1 from '../assets/images/pattern1.png'
import pattern2 from '../assets/images/pattern2.png'
// import pattern3 from '../assets/images/pattern3.JPG'
import pattern3 from '../assets/images/pattern3.png'
import headerLogoImg from '../assets/CencoraLogoWhite.png'
import brandLogoImgPurple from '../assets/images/logoPurple.svg'
import brandLogoImgWhite from '../assets/images/logoWhite.svg'
import rightSideImg from '../assets/images/logo192.png'
import notificationImg from '../assets/icons/notification-line.png'
import userImg from '../assets/icons/User-Icon.png'
import questionImg from '../assets/icons/question-line.png'
import logoutImg from '../assets/icons/log-out.png'
import menuImg from '../assets/icons/menu.png'
import newsIcon from '../assets/icons/news.svg'
import aboutKGImg from '../assets/images/aboutKG.svg'
import ontologyImg from '../assets/images/ontology.svg'
import walkThroughVideo from '../assets/videos/graphExplorerDemoVideo.webm'
import ontologyIcon from '../assets/icons/ontologyIcon.svg'
import supplyside_ontology from '../assets/images/supplyside_ontology_1.svg'
import sellside_ontology_new from '../assets/images/sellside_ontology.svg'





export const brand = "Cencora";
export const brandName = "Cencora";
const brandLogo1 = brandLogoImgWhite;
const brandLogo2 = brandLogoImgPurple;

export const logInScreenData = {
  backgroundImage: pattern3,
  backgroundImage1: pattern2, 
  header: {
    title: "Login",
    logo: brandLogo1, 
    rightSideImg: rightSideImg,
  },
  button: {
    title: ` Log in with  SSO`,
    startIcon: "",
  },
  footer: {
    text1: `Log in with your ${brand} e-mail address. If you do not have a
    ${brand} e-mail address, please enter your network ID with
    @${brand}.com at the end (i.e., abcxyz00@${brandName.toLowerCase()}.com or
    00123456@${brandName.toLowerCase()}.com).`,
    text2: " New users,",
    clickableText: "click here",
    text3: "to enroll in two-step authentication.",
  },
};

export const headerData = {
  logo: brandLogo2,
  text: "Cencora",
  notificationIcon: notificationImg,
  questionIcon: questionImg,
  userIcon: userImg,
  menu: menuImg,
  dropdown: {
    logoutIcon: logoutImg,
    logOut: "Log Out",
  },
};

export const homeData = {
  logo: brandLogo1, 
  backgroundImage: pattern3,
  newsIcon: newsIcon 
}

export const resourceData={
  aboutKG: aboutKGImg,
  ontology: ontologyImg,
  walkThroughVideo: walkThroughVideo


}
export const fabIcon={
  ontologyIcon: ontologyIcon
}
export const ontologyGraphData=[
  {
    ontologyImg: supplyside_ontology,
    viewName: "srmsupplyside"
  }, 
  {
    ontologyImg: sellside_ontology_new,
    viewName: "srmsellside",
  }, 

]

