export const path = "/views";
export const routes = {
  login: "/login",
  views: `${path}/*`,
  graph: "/graph/:id",
  home: "/home",
  resource: "/resource"
};
export const appRouters = {
  graph: `${path}${routes.graph}`,
  views: `${path}`,
  home: `${routes.home}`,
  resource: `${routes.resource}`
};