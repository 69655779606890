/**
 * Login Component
 *
 * The `Login` component is a container component that renders the `LoginScreen` component.
 * It handles user interactions for logging in and redirecting to the dashboard after successful login.
 *
 * @returns {React.ReactNode} The `Login` component.
 */

import { useMsal } from "@azure/msal-react";
import LoginScreen from "./LoginScreen";
import { useNavigate } from "react-router-dom";
import { createUser, findUserByEmail } from "../../../api/api";
import Cookies from "js-cookie";
import { useAppDispatch } from "../../../redux/hooks/hook";


const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { instance } = useMsal();
  const onClickClickhere = () => {
    navigate("/home");
  };

  const onClickLogin = () => {
    instance
      .loginPopup({ scopes: ["User.Read"] })
      .then((res) => {
        Cookies.set("loginToken", res.idToken);
        findOrCreateUser(res?.account)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const findOrCreateUser = async (user: any) => {
    // Check user is registered or not
    try {
      const { username } = user
      const userExists = await findUserByEmail(username)
      // localStorage.setItem('user', JSON.stringify(user))
      Cookies.set('user', JSON.stringify(user))
      if (userExists) {
        navigate("/home");
        return;
      }
      const newUser = await createUser(username)
      if (newUser) {
        navigate("/home");
        return;
      }
    } catch (err) {
      console.info("Error creating user")
    }
  }

  return (
    <>
      <LoginScreen
        onClickClickhere={onClickClickhere}
        onClickLogin={onClickLogin}
      />
    </>

  );
};

export default Login;
