import { useState } from "react";

import { ClickableUrls } from "../ClickableUrls";
import {
  AlternatingTable,
  CopyCell,
  KeyCell,
  StyledExpandValueButton,
  StyledInlineList,
  ValueCell,
} from "./PropertiesTable.style";
import { ClipboardCopier } from "../ClipboardCopier";
import { ShowMoreOrAll } from "../showMoreOrAll/ShowMoreOrAll";
import { VizItemProperty } from "../../../common";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { isNull } from "lodash";

export const ELLIPSIS = "\u2026";
export const WIDE_VIEW_THRESHOLD = 900;
export const MAX_LENGTH_NARROW = 150;
export const MAX_LENGTH_WIDE = 300;
type ExpandableValueProps = {
  value: string;
  width: number;
  type: string;
};
function ExpandableValue({ value, width, type }: ExpandableValueProps) {
  const [expanded, setExpanded] = useState(false);

  const maxLength =
    width > WIDE_VIEW_THRESHOLD ? MAX_LENGTH_WIDE : MAX_LENGTH_NARROW;

  const handleExpandClick = () => {
    setExpanded(true);
  };

  let valueShown = expanded ? value : (value && value.length > 0) ? value.slice(0, maxLength) : undefined;
  const valueIsTrimmed = valueShown && valueShown.length !== (value && value.length);

  valueShown += valueIsTrimmed ? ELLIPSIS : "";

  return (
    <>
      {type && type.startsWith("Array") && "["}
      <ClickableUrls text={valueShown} />
      {valueIsTrimmed && (
        <StyledExpandValueButton onClick={handleExpandClick}>
          {" Show all"}
        </StyledExpandValueButton>
      )}
      {type && type.startsWith("Array") && "]"}
    </>
  );
}

type PropertiesViewProps = {
  visibleProperties: VizItemProperty[];
  onMoreClick: (numMore: number) => void;
  totalNumItems: number;
  moreStep: number;
  nodeInspectorWidth: number;
};
export const PropertiesTable = ({
  visibleProperties,
  totalNumItems,
  onMoreClick,
  moreStep,
  nodeInspectorWidth,
}: PropertiesViewProps): JSX.Element => {
  return (
    <>
      <Table data-testid="viz-details-pane-properties-table">
        <TableBody >
          {visibleProperties && visibleProperties.slice(1).map(({ key, type, value }) => (
            value !== undefined && (
            <TableRow
              key={key}
              sx={{
                borderBottom: "0.1rem solid #E0E0E0",
              }}
            >
              <TableCell component="th" scope="row">
                <ClickableUrls text={key} />
              </TableCell>
              <TableCell >
                <ExpandableValue
                  value={value}
                  width={nodeInspectorWidth}
                  type={type} />
              </TableCell>    
              <TableCell>
                <ClipboardCopier
                  titleText={"Copy key and value"}
                  textToCopy={`${key}: ${value}`}
                  iconSize={12} />
              </TableCell>
            </TableRow>
            )
          ))}
        </TableBody>
      </Table>
      <ShowMoreOrAll
        total={totalNumItems}
        shown={visibleProperties.length}
        moreStep={moreStep}
        onMore={onMoreClick}
      />
    </>
  );
};
