import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { resourceData } from '../../constants/screensData'
import CodeSnippet from './CodeSnippet'

const code1 = `MATCH n(p : Product)-[ : CATEGORY]->(l : ProductCategory)-[ : PARENT*0..]->
( : ProductCategory {name : "Dairy Products" })
RETURN p.name`

const code2 = `SELECT p.ProductName
FROM Product AS p
JOIN ProductCategory pc ON (p.CategoryID =pc.CategoryID AND pc.CategoryName ="Dairy Products")
JOIN ProductCategory pc1 ON (p.CategoryID =pc1.CategoryID)
JOIN ProductCategory pc2 ON (pc1.ParentID =pc2.CategoryID AND pc2.CategoryName ="Dairy Products")
JOIN ProductCategory pc3 ON (p.CategoryID =pc3.CategoryID)
JOIN ProductCategory pc4 ON (pc3.ParentID =pc4.CategoryID)
JOIN ProductCategory pc5 ON (pc4.ParentID =pc5.CategoryID AND pc5.CategoryName ="Dairy Products");`

export default function KnowledgeGraph() {
    useEffect(() =>{
        window.scrollTo(0,0);
    })
    return (
        <Box paddingTop={"1rem"} paddingLeft={"20rem"} paddingRight={"3rem"} width={"85%"} paddingBottom={"10rem"}>
            <Typography fontSize={"25px"} textAlign={"center"}>About Knowledge Graph</Typography>
            <Typography marginTop={"2vh"} fontSize={"20px"}>What is Knowledge Graph</Typography>
            <Typography color={"#3b3b3b"} marginTop={"2vh"}>
                Imagine a giant mind map
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        A knowledge graph is like a huge mind map of information, where everything is connected.
                    </li>
                    <li>
                        Each bubble on the mind map is a piece of information - it could be a person, a place, an idea, an event, anything!
                    </li>
                    <li>
                        The lines connecting the bubbles show how these pieces of information are related to each other.
                    </li>
                </ul>
                The nodes represent bubbles, while the connections between them signify relationships. When these elements interconnect within a context, they form the knowledge graph.
            </Typography>
            <Box display={"flex"} justifyContent={"center"}>
                <img src={resourceData.aboutKG} />
            </Box>

            <Typography marginTop={"2vh"} fontSize={"20px"}>Why to use Knowledge Graph</Typography>
            <Typography color={"#3b3b3b"} marginTop={"2vh"}>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        Uncover hidden connections: Spot unexpected links within your data, like hidden patterns or surprising relationships.
                    </li>
                    <li>
                        Smarter searches: Get the answers you truly need, not just random keywords. Knowledge graphs understand context and deliver relevant results.
                    </li>
                    <li>
                        Faster insights: Analyze your data quicker and easier, making informed decisions in a snap.
                    </li>
                    <li>
                        Scale with ease: As your data grows, your knowledge graph can too, always keeping up with your information needs.
                    </li>
                    <li>
                        Powerful analytics: Dive deeper into your data with graph analytics, unlocking valuable insights and trends.
                    </li>
                </ul>
            </Typography>

            <Typography marginTop={"2vh"} fontSize={"20px"}>Cypher vs SQL</Typography>
            <Typography color={"#3b3b3b"} marginTop={"2vh"}>
                Cypher is the top choice for graph queries because it's easy to write and understand, making it simpler to maintain applications. It's also good at handling complicated tasks and reduces the number of times the database needs to be accessed. Plus, its smart planner and execution engine help make queries run faster by using information from the database.
                <br />
            </Typography>
            <Box marginTop={"2vh"}>
                <CodeSnippet code={code1} name='Cypher' />
            </Box>
            <Typography color={"#3b3b3b"} marginTop={"2vh"}>
                Here's a similar query in SQL, which is longer and more complex. Unlike Cypher, where depth is unlimited, this SQL query selects just three levels of depth.
            </Typography>

            <Box marginTop={"2vh"}>
                <CodeSnippet code={code2} name='SQL' />
            </Box>


            <Typography marginTop={"2vh"} fontSize={"20px"}>Real world Knowledge Graph problems</Typography>
            <Typography color={"#3b3b3b"} marginTop={"2vh"}>
                <b>Drug discovery and development</b>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        <b>Problem: </b>
                        The traditional drug discovery process is lengthy, expensive, and has a high failure rate. Identifying new drug targets and repurposing existing drugs can be a challenge due to the vast amount of scientific literature and data involved.
                    </li>
                    <li>
                        <b>Solution: </b>
                        Knowledge graphs can connect information about genes, diseases, existing drugs, and clinical trials. This allows researchers to identify promising drug targets, predict potential drug interactions, and accelerate the development of new treatments.
                    </li>
                </ul>

                <b>Fraud detection and prevention</b>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        <b>Problem: </b>
                        Financial institutions and other organizations face significant challenges in detecting and preventing fraudulent activities due to the complex and evolving nature of fraud schemes.
                    </li>
                    <li>
                        <b>Solution: </b>
                        Knowledge graphs can analyze patterns in financial transactions, customer data, and other relevant information to identify suspicious activities. They can help detect anomalies, predict fraudulent behavior, and prevent financial losses.
                    </li>
                </ul>

                <b>Supply chain optimization</b>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        <b>Problem: </b>
                        Traditional supply chain management systems often lack transparency and struggle to deal with disruptions and delays. Optimizing logistics and ensuring efficient delivery can be difficult.
                    </li>
                    <li>
                        <b>Solution: </b>
                        Knowledge graphs can track the movement of goods and materials through a supply chain, connecting suppliers, manufacturers, distributors, and retailers. This allows for improved visibility, better tracking of inventory levels, and enables proactive identification and response to potential disruptions.
                    </li>
                </ul>

                <b>Personalized recommendations</b>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        <b>Problem: </b>
                        Recommending relevant products, services, or content to users can be challenging due to the vast amount of information available and the need to understand individual preferences.
                    </li>
                    <li>
                        <b>Solution: </b>
                        Knowledge graphs can connect information about users, their past behavior, and product attributes. This allows businesses to personalize recommendations by understanding user preferences, connections to different entities, and identifying similar items they might be interested in.
                    </li>
                </ul>

                <b>Scientific research and knowledge management</b>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        <b>Problem: </b>
                        Researchers often face challenges in accessing and analyzing the vast amount of scientific data and literature available across different fields. Sharing and collaborating on research findings can also be difficult.
                    </li>
                    <li>
                        <b>Solution: </b>
                        Knowledge graphs can connect information about scientific concepts, experiments, and researchers. This allows researchers to explore relationships between different research areas, discover new insights, and share knowledge more effectively.
                    </li>
                </ul>
            </Typography>
        </Box>
    )
}


