import { Box, Button, Card, CardContent, Checkbox, Chip, Divider, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography, makeStyles } from "@mui/material"
import { useEffect, useState } from "react"
import { useAppDispatch } from "../../redux/hooks/hook";
import { Toaster } from "react-hot-toast";
import { CustomToast } from "../../helpers/customToast";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { LoadingButton } from "@mui/lab";
import { setQueryBuilderOpen, setRunQueryOpen } from "../../redux/reducers/sidebarReducer";
import { appTheme } from "../../themes/appTheme";
import { runCipherQuery } from "../../api/api";
import AddIcon from '@mui/icons-material/Add';
import { theme } from "../../config/theme";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { PathSegment } from "neo4j-driver-core";
import React from "react";

const style = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight:"38rem",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "61rem",
    maxHeight: "40rem",
    overflowY: "auto",
    bgcolor: '#ffffff',
    borderRadius: "0.625rem",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
};



/**
 * Generate the relationships combinations
 * @param graph
 * @param node
 * @param uniqueKeys
 */
function getCombinations(graph: any[], node: any, uniqueKeys: Set<string>) {
    const uniqueCombinations = new Set()
    graph.forEach((eachItem: { l1: any[]; l2: any[]; r: any; }) => {
        if ((eachItem.l1 && eachItem.l1[0] === node)) {
            const key = eachItem.l1 && eachItem.l1[0] === node ?
                `${eachItem.l1[0]}-${eachItem.r}-${eachItem.l2 && eachItem.l2[0]}` :
                `${eachItem.l2[0]}-${eachItem.r}-${eachItem.l1 && eachItem.l1[0]}`
            // const key = eachItem.l1 && eachItem.l1[0] === node && `${eachItem.l1[0]}-${eachItem.r}-${eachItem.l2 && eachItem.l2[0]}`

            if (!uniqueKeys.has(key)) {
                if ((eachItem.l1 && eachItem.l1[0]) !== null && (eachItem.l2 && eachItem.l2[0]) !== null && eachItem.r !== null) {
                    uniqueKeys.add(key)
                    const set = new Set()
                    set.add({
                        type: "node",
                        label: eachItem.l1 && eachItem.l1[0] === node ? eachItem.l1[0] : eachItem.l2 && eachItem.l2[0],
                        whereProperties: eachItem.l1 && eachItem["l1_props"]
                    })
                    set.add({
                        type: "rel",
                        relType: eachItem.r && eachItem.r
                    })
                    set.add({
                        type: "node",
                        label: eachItem.l1 && eachItem.l1[0] !== node ? eachItem.l1[0] : eachItem.l2 && eachItem.l2[0],
                        whereProperties: eachItem.l2 && eachItem["l2_props"]
                    })
                    uniqueCombinations.add(Array.from(set))
                }
            }
        }
    });
    return {
        uniqueCombinations,
        uniqueKeys
    }
}


function mergePaths(combinations: any[][][]): any[] {
    const mergedPaths: any[] = [];
    const visitedPairs: Set<string> = new Set();
    const notVisitedPairs: any[] = []

    function getKey(path: any[][]): string {
        return path.map(step => step[0]?.label).join('-');
    }

    function merge(path: any[][]) {
        const key = getKey(path);
        if (visitedPairs.has(key)) {
            notVisitedPairs.push(path)
        };
        visitedPairs.add(key);
        // Use unshift to add elements to the beginning of the array
        mergedPaths.unshift(path);
        // mergedPaths.push(path);
    }

    for (const paths of combinations) {
        for (const path of paths) {
            merge([path]);
        }
    }

    const finalMergedPaths = mergedPaths.map(path => path.reduce((acc: string | any[], cur: any) => acc.concat(cur), []));
    const finalArrays = []
    for (let i = 0; i < finalMergedPaths.length; i++) {
        let currentTargetLabel = finalMergedPaths[i][2]?.label;
        let currentSourceLabel = finalMergedPaths[i][0]?.label;
        const mArray = [...finalMergedPaths[i]];
        for (let j = i + 1; j < finalMergedPaths.length; j++) {
            const targetSourceLabel = finalMergedPaths[j][0]?.label;
            const targetTargetLabel = finalMergedPaths[j][2]?.label;
            if (currentTargetLabel === targetSourceLabel) {
                currentTargetLabel = finalMergedPaths[j][0]?.label;
                if (currentTargetLabel !== null) {
                    mArray.push(...finalMergedPaths[j]);
                }
            }
            if (currentSourceLabel === targetTargetLabel) {
                currentSourceLabel = finalMergedPaths[j][0]?.label;
                if (currentSourceLabel !== null) {
                    mArray.unshift(...finalMergedPaths[j]);
                }

            }
        }

        const finalMArray = mArray.reduce((acc, cur) => {
            const lastNode = acc[acc.length - 1];
            if (!lastNode || cur.label !== lastNode.label) {
                acc.push(cur);
            }
            return acc;
        }, [])
        finalArrays.push([finalMArray]);
    }
    return finalArrays
}




function generateCipherQuery(queryArray: any[], path: any) {
    let cipherQuery = `MATCH ${path}=`;
    if (queryArray[0]?.type === "rel") {
        cipherQuery += '()'
    }
    queryArray.forEach((element: { type: string; label: string; relType: string; }, index: any) => {
        if (element.type === "node") {
            cipherQuery += `(${element?.label ? `\`${element?.label}\`` + ":" : ""}${element?.label ? `\`${element?.label}\`` : ""})`;
        } else if (element.type === "rel") {
            cipherQuery += `-[${element?.relType ? ":" + element.relType : ""}]->`;
        }
    });

    if (queryArray[queryArray.length - 1]?.type === "rel") {
        cipherQuery += '()'
    }
    return cipherQuery;
}

type ConditionsType = {
    id: number,
    entity: string,
    property: string,
    operator: string,
    value: string
}


async function addConditions(conditionsList: ConditionsType[], multiConditionOperators?: string[]): Promise<{
    conditionsQuery: string,
    error: string
}> {
    let conditions = {
        conditionsQuery: "",
        error: ""
    }
    if (conditionsList.length > 1) {
        if (multiConditionOperators && multiConditionOperators.length > 0) {
            for (let i = 0; i < conditionsList.length; i++) {
                const condition = conditionsList[i]
                if (condition.operator === 'IS NULL' || condition.operator === 'IS NOT NULL') {
                    conditions.conditionsQuery += `\`${condition.entity}\`.\`${condition.property}\` ${condition.operator} `;
                }
                else if (condition.operator === 'IN' || condition.operator === 'NOT IN') {
                    const values = condition.value.split(',');
                    const formattedValues = values.map(value => `"${value.trim().charAt(0).toUpperCase() + value.trim().slice(1)}"`).join(',');
                    {
                        condition.operator === "NOT IN" ?
                        conditions.conditionsQuery += `NOT \`${condition.entity}\`.\`${condition.property}\` IN [${formattedValues}]`
                        :
                        conditions.conditionsQuery += `\`${condition.entity}\`.\`${condition.property}\` ${condition.operator} [${formattedValues}]`;
                    }
                }
                else{
                    conditions.conditionsQuery += `\`${condition.entity}\`.\`${condition.property}\` ${condition.operator} \'${condition.value.charAt(0).toUpperCase()+condition.value.slice(1)}'\ `;
                }
                if (i < conditionsList.length - 1) {
                    conditions.conditionsQuery += ` ${multiConditionOperators[i]} `
                }
            }
        } else {
            conditions.error = "Please select the And/Or operators for the conditions"
        }
    } else {

        if (conditionsList[0].operator === 'IS NULL' || conditionsList[0].operator === 'IS NOT NULL') {
            conditions.conditionsQuery += `\`${conditionsList[0].entity}\`.\`${conditionsList[0].property}\` ${conditionsList[0].operator} `;
        }
        else if (conditionsList[0].operator === 'IN' || conditionsList[0].operator === 'NOT IN') {
            const values = conditionsList[0].value.split(',');
            const formattedValues = values.map(value => `"${value.trim().charAt(0).toUpperCase() + value.trim().slice(1)}"`).join(',');
            {
                conditionsList[0].operator === "NOT IN" ?
                conditions.conditionsQuery += `NOT \`${conditionsList[0].entity}\`.\`${conditionsList[0].property}\` IN [${formattedValues}]`
                :
                conditions.conditionsQuery += `\`${conditionsList[0].entity}\`.\`${conditionsList[0].property}\` ${conditionsList[0].operator} [${formattedValues}]`;
            }
        }
        else{
            conditions.conditionsQuery += `\`${conditionsList[0].entity}\`.\`${conditionsList[0].property}\` ${conditionsList[0].operator} \'${conditionsList[0].value.charAt(0).toUpperCase()+conditionsList[0].value.slice(1)}'\ `;
        }
    }
    return conditions
}

const DividerWithText = ({ children }) => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // width: "50rem"
        }}>
            <Box sx={{
                width: "2rem",
                borderBottom: "2px solid lightgrey",
            }}>
            </Box>
            <ArrowRightIcon sx={{ marginLeft: "-8px" }} />
            {children}
            <Box sx={{
                width: "2rem",
                borderBottom: "2px solid lightgrey",
            }}>
            </Box>
            <ArrowRightIcon sx={{ marginLeft: "-8px" }} />
        </Box>
    )
}


const QueryBuilder = ({ isQueryBuilderOpen, setSelectedFavQuery }: any) => {
    const dispatch = useAppDispatch()
    const [nodelLabels, setNodeLabels] = useState<string[]>([])
    const [nodesAndRelsJson, setNodesAndRelsJson] = useState<any>([])
    const [nodesSelected, setNodesSelected] = useState<string[]>([])
    const [pathsSelected, setPathsSelected] = useState<any>({})
    const [combinations, setCombinations] = useState<any[]>([])
    const [nodeLabelsLoading, setNodeLabelsLoading] = useState<boolean>(false)
    const [buildQueryLoading, setBuildQueryLoading] = useState<boolean>(false)
    const [buildError, setBuildError] = useState<string>("")
    const [selectAllNodes, setSelectAllNodes] = useState<boolean>(false)
    const [selectAllRelations, setSelectAllRelations] = useState<boolean>(false)
    const [queryConditions, setQueryConditions] = useState<ConditionsType[]>([])
    const [multiConditionOperators, setMultiConditionOperators] = useState<string[]>([])

    const [labelSet, setLabelSet] = useState<Array<string>>([])
    const [relationSet, setRelationSet] = useState<Array<string>>([])
    const [propertySet, setPropertySet] = useState<Array<string>>([]);

    const handleAddCondition = () => {
        const newCondition = { id: queryConditions.length + 1, entity: '', property: '', operator: '', value: '' };
        const updatedConditions = [...queryConditions, newCondition];
        setQueryConditions(updatedConditions);
    };

    const handleDeleteCondition = (index: number) => {
        const updatedConditions = [...queryConditions];
        updatedConditions.splice(index, 1);
        setQueryConditions(updatedConditions);
    };

    const handleConditionInput = (index: number, field: string, value: string) => {
        const updatedConditions = [...queryConditions];
        // const condition = updatedConditions.find(cond => cond.id === id);
        const condition=updatedConditions[index]
        if (condition) {
            condition[field] = value;
            setQueryConditions(updatedConditions);
        }
    };

    const handleChangeAndOr = (index: number, value: string) => {
        const updatedAndOr = [...multiConditionOperators];
        updatedAndOr[index] = value;
        setMultiConditionOperators(updatedAndOr);
    };

    useEffect(() => {
        if (isQueryBuilderOpen) {
            // call the methods to get the nodes labels and json objects
            getNodeLabels()
            getNodesAndRelationsJson()

        }
    }, [isQueryBuilderOpen])

    const getNodeLabels = async () => {
        setNodeLabelsLoading(true);
        try {
            const query = "MATCH(n) RETURN DISTINCT labels(n)";
            const response = await runCipherQuery(query);
            const nodes = [];
            for (const node of response.graphData) {
                nodes.push(node[0]);
            }
            setNodeLabels(nodes);
        } catch (err) { }
    
        // Adding a 2-second delay before setting NodeLabelsLoading to false
        setTimeout(() => {
            setNodeLabelsLoading(false);
        }, 2000);
    };
    

    const getNodesAndRelationsJson = async () => {
        try {
            const query = "MATCH (n) OPTIONAL MATCH (n)-[r]->(x) WITH DISTINCT {l1: labels(n), r: type(r), l2: labels(x),l1_props:keys(n),l2_props:keys(x),r_props:keys(r)} as query return query"
            const response = await runCipherQuery(query)
            setNodesAndRelsJson(response.graphData)
        } catch (err) { }
    }

    const handleNodeSelected = (node: string) => {
        if (nodesSelected.includes(node)) {
            const index = nodesSelected.indexOf(node)
            if (index > -1) {
                nodesSelected.splice(index, 1)
            }
        } else {
            nodesSelected.push(node)
        }
        setNodesSelected([...nodesSelected])
        if (nodesSelected.length > 0) {
            getCombinationsForNode()
        } else {
            setCombinations([])
        }
        // Clear the node selection error
        if (nodesSelected.length > 0) setBuildError("")

        // Reset the query builder if nodesSelected is empty
        if (nodesSelected.length === 0) {
            resetQueryBuilder()
        }
        // setPathsSelected({})
        // setCombinations([])

    }
    
    

    const getCombinationsForNode = async () => {
        if (nodesSelected.length > 0) {
            const finalCombinations = []
            const uniqueKeysCombined = new Set<string>()
            for await (const node of nodesSelected) {
                const { uniqueCombinations, uniqueKeys } = getCombinations(nodesAndRelsJson, node, uniqueKeysCombined)
                finalCombinations.push(Array.from(uniqueCombinations))
                for (const uniqueKey of uniqueKeys) {
                    uniqueKeysCombined.add(uniqueKey)
                }
            }
            const copiedFinalCombinations: any = finalCombinations
            const mergComb = mergePaths(copiedFinalCombinations)
            setCombinations(mergComb)
            // clear the unique keys
            uniqueKeysCombined.clear()
        }
    }

    const handleClose = () => {
        dispatch(setQueryBuilderOpen(false))
    };

    const onClickPath = async (combIndex: any, nodeIndex?: any) => {
        const path = combinations[combIndex][nodeIndex]
        const key = createPathKey(combIndex, nodeIndex)
        // const key=nodeIndex
        if (pathsSelected[key]) {
            delete pathsSelected[key]
        } else {
            pathsSelected[key] = path
        }
        setPathsSelected({ ...pathsSelected })
        await addOrRemoveLabelsAndRelsAndPropertiesFromPath(key)
        // Clear the path error
        if (Object.keys(pathsSelected).length > 0) setBuildError("")
    }

    async function addAllLabelsAndRelsAndPropertiesFromPath() {
        for (const key of Object.keys(pathsSelected)) {
            await addOrRemoveLabelsAndRelsAndPropertiesFromPath(key)
        }
    }

    async function addOrRemoveLabelsAndRelsAndPropertiesFromPath(key: string) {
        const finalPathSelectedKeys = []
        if (pathsSelected.hasOwnProperty(key)) {
            finalPathSelectedKeys.push(...Object.keys(pathsSelected))
        }
        else {
            finalPathSelectedKeys.push(...Object.keys(pathsSelected).filter((eachKey) => eachKey !== key))
        }

        // for label set 
        const newLabelSet: Set<string> = new Set()
        for (const eachPath of Object.keys(pathsSelected)) {
            const currentLabelsList = await getLabelsFromPath(eachPath as any);
            for (const eachLabel of currentLabelsList.flat()) {
                if (eachLabel !== null) {
                    newLabelSet.add(eachLabel)
                }
            }
        }
        setLabelSet(Array.from(newLabelSet))

        // for relation set
        const newRelationSet: Set<string> = new Set()
        for (const eachPath of Object.keys(pathsSelected)) {
            const currentRelationList = await getRelationFromPath(eachPath as any);
            for (const eachRelation of currentRelationList.flat()) {
                if (eachRelation !== null) {
                    newRelationSet.add(eachRelation)
                }
            }
        }
        setRelationSet(Array.from(newRelationSet))

        // for property set
        const newPropertySet: Set<string> = new Set()
        let newPropertyArray = []
                for (const eachPath of Object.keys(pathsSelected)) {
                    const currentPropertyList = await getPropertiesFromPath(eachPath as any);
                    for (const eachProperty of Object.keys(currentPropertyList)) {
                        if (newPropertyArray[eachProperty]) {
                            const propertySet = new Set(currentPropertyList[eachProperty]);
                            newPropertyArray[eachProperty] = Array.from(propertySet);
                        } else {
                            newPropertyArray[eachProperty] = currentPropertyList[eachProperty];
                        }
                    }
                }
        Object.keys(newPropertyArray).map(eachProp=>{
        })
        setPropertySet(newPropertyArray)

    }

    function resetQueryBuilder() {
        setNodesSelected([])
        setPathsSelected({})
        setCombinations([])
        setQueryConditions([])
        setMultiConditionOperators([])
        setBuildError("")
        setSelectAllNodes(false)
        setSelectAllRelations(false)
        setQueryConditions([])
        setMultiConditionOperators([])
        setLabelSet([])
        setRelationSet([])
        setPropertySet([])
    }

    async function getLabelsFromPath(key: string): Promise<Array<string>> {
        const [combIndex, nodeIndex] = key.trim().split("-")
        const path = combinations[combIndex][nodeIndex]
        const labelsList = []
        for(let i = 0; i < path.length; i++){
            if(i%2 == 0){
                labelsList.push(path[i] && path[i]?.label)
                i++;
            }
        }
        return labelsList
    }
    async function getRelationFromPath(key: string): Promise<Array<string>> {
        const [combIndex, nodeIndex] = key.trim().split("-")
        const path = combinations[combIndex][nodeIndex]
        const relationList = []
        for (let i = 0; i < path.length; i++) {
            if (i % 2 !== 0) { 
                relationList.push(path[i] && path[i]?.relType)
                i++;
            }
        }
        return relationList
    }
    async function getPropertiesFromPath(key: string): Promise<Array<string>> {
        const [combIndex, nodeIndex] = key.trim().split("-")
        const path = combinations[combIndex][nodeIndex]
        const propertyList = []
        for(let i = 0; i < path.length; i++){
            if(i%2 == 0){
                propertyList[path[i]?.label] = path[i]?.whereProperties
                i++;
            }
        }
        return propertyList
    }

    const createPathKey = (combIndex: any, nodeIndex?: any) => {
        return `${combIndex}-${nodeIndex}`
    }


    const onClickBuild = async () => {
        if (nodesSelected.length > 0) {
            if (Object.keys(pathsSelected).length > 0) {
                setBuildQueryLoading(true)
                const paths = Object.values(pathsSelected)
                let finalQuery = ""
                let returnStatement = "RETURN "
                for (let i = 0; i < paths.length; i++) {
                    const path = paths[i] as any
                    const result = generateCipherQuery(path, `p${i}`)
                    finalQuery += result + " "
                    if (i < paths.length) returnStatement += `p${i},`
                }
                // add the conditions to the query
                if (queryConditions.length > 0) {
                    let conditions = await addConditions(queryConditions, multiConditionOperators)
                    if (conditions.error) {
                        setBuildError(conditions.error)
                        setBuildQueryLoading(false)
                        return
                    }
                    conditions.conditionsQuery !== "" && (finalQuery += `WHERE ${conditions.conditionsQuery} `)
                }
                // add the return statement to the query
                finalQuery += returnStatement.trim().slice(0, -1)
                setBuildQueryLoading(false)
                if (finalQuery) {
                    setSelectedFavQuery(finalQuery)
                    dispatch(setRunQueryOpen(true))
                    dispatch(setQueryBuilderOpen(true))
                }
            } else {
                setBuildError("Please select atleast one path")
            }
        } else {
            setBuildError("Please select atleast one node")
        }
    }

    useEffect(() => {
        if (nodesSelected.length > 0 && selectAllNodes) {
            getCombinationsForNode()
        }
    }, [selectAllNodes])

    useEffect(() => {
        if (combinations.length > 0 && selectAllRelations) {
            for (let i = 0; i < combinations.length; i++) {
                for (let j = 0; j < combinations[i].length; j++) {
                    const key = createPathKey(i, j)
                    pathsSelected[key] = combinations[i][j]
                }
            }
            setPathsSelected({ ...pathsSelected })
            addAllLabelsAndRelsAndPropertiesFromPath()
        }
    }, [selectAllRelations])

    const onChangeSelectAllNodes = () => {
        if (selectAllNodes) {
            setNodesSelected([])
            setCombinations([])
            setSelectAllNodes(false)
        } else {
            setNodesSelected([...nodelLabels])
            setSelectAllNodes(true)
        }
    }

    const onChangeSelectAllRels = () => {
        if (selectAllRelations) {
            setPathsSelected({})
            setSelectAllRelations(false)
        } else {
            setSelectAllRelations(true)
        }
    }
    const operators_List = ["=", "<>", ">", "<", "<=", ">=", "STARTS WITH", "ENDS WITH", "CONTAINS", "IN", "NOT IN", "IS NULL", "IS NOT NULL"];

    return (
        <>
            <Modal
                open={isQueryBuilderOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} py="1rem">
                    <Box px="1.3rem" display="flex" mb={1} flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Typography sx={{
                            fontSize: "1.25rem",
                            fontWeight: 600,
                        }}>
                            Query Builder
                        </Typography>
                        <Typography sx={{
                            textAlign: "right",
                        }}>
                            <CloseOutlinedIcon onClick={handleClose} sx={{
                                fontSize: "1.3rem",
                                fontWeight: 600,
                                cursor: "pointer"
                            }} />
                        </Typography>
                    </Box>
                    {/* Nodes */}
                    <Box px="1.3rem">
                        <Box sx={{
                            marginBottom: "0.5rem",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <Box display={"flex"} gap={"2rem"}>
                            <Typography sx={{
                                fontSize: "1.3rem",
                                fontWeight: 200,
                            }}>
                                Nodes
                            </Typography>
                            {nodeLabelsLoading && (
                                <Typography sx={{
                                    fontSize: "1.2rem",
                                    fontWeight: 500,
                                    textAlign: "center",
                                }}>
                                    Loading...
                                </Typography>
                            )}
                            </Box>

                            {/* Create checkbox with select all text on right side use box,checkbox,typography */}
                            <Box display="flex" data-testid="selectAllNodes" flexDirection="row" justifyContent="flex-end" alignItems="center">
                                <Checkbox onChange={() => onChangeSelectAllNodes()} checked={selectAllNodes} />
                                <Typography sx={{
                                    fontSize: "1.1rem",
                                    fontWeight: 500,
                                    cursor: "pointer",
                                }}>
                                    Select All
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" data-testid="nodes-container" flexDirection="row" flexWrap="wrap" justifyContent="flex-start" alignItems="center">
                            {nodelLabels && nodelLabels.map((node, index) => {
                                return (
                                    <Chip onClick={() => handleNodeSelected(node)} variant={nodesSelected.includes(node) ? "filled" : "outlined"} key={index} label={node}
                                        size="small"
                                        sx={{
                                            marginRight: "1rem",
                                            marginBottom: "1rem",
                                            cursor: "pointer",
                                            backgroundColor: nodesSelected.includes(node) ? "#DDDBF7" : "#ffffff",
                                            borderWidth: "1px",
                                            borderColor: "#8C7ED7",
                                            color: "#461E96",
                                            pointerEvents: nodeLabelsLoading ? "none" : "auto",
                                        }} />
                                )
                            })}

                        </Box>
                        
                    </Box>
                    <Divider />
                    {/* Relations */}
                    <Box px="1.3rem" mt="1rem">
                        <Box sx={{
                            marginBottom: "0.5rem",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <Typography sx={{
                                fontSize: "1.3rem",
                                fontWeight: 200,
                            }}>
                                Relationships
                            </Typography>
                            {/* Create checkbox with select all text on right side use box,checkbox,typography */}
                            <Box display="flex" data-testid="selectAllRels" flexDirection="row" justifyContent="flex-end" alignItems="center">
                                <Checkbox onChange={() => onChangeSelectAllRels()} checked={selectAllRelations} />
                                <Typography sx={{
                                    fontSize: "1.1rem",
                                    fontWeight: 500,
                                    cursor: "pointer",
                                }}>
                                    Select All
                                </Typography>
                            </Box>
                        </Box>
                        {
                            nodesSelected.length === 0 && (
                                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                    <Typography sx={{
                                        fontSize: "1.2rem",
                                        fontWeight: 500,
                                        marginBottom: "0.5rem",
                                        textAlign: "center",
                                        paddingY: "1rem",
                                        paddingX: "3rem",
                                        borderStyle: "dashed",
                                        borderWidth: "1px",
                                        borderRadius: "0.5rem",
                                    }}>
                                        Please select nodes
                                    </Typography>
                                </Box>
                            )
                        }
                        {
                            combinations && (
                                <Box display="flex" sx={{
                                    width: "100%",
                                    overflowX: "auto"
                                }} flexDirection="row" flexWrap="wrap" justifyContent="space-between" alignItems="center">
                                    {combinations.map((nodeCombinations, combIndex) => {
                                        return nodeCombinations.map((eachCombination: any, nodeIndex) => (
                                            <Box key={nodeIndex} sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: "1rem",
                                                width: "100%",
                                            }}
                                                onClick={() => onClickPath(combIndex, nodeIndex)}
                                            >
                                                {eachCombination.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        {
                                                            index % 2 !== 0 ? (
                                                                <>
                                                                    {
                                                                        eachCombination && index !== eachCombination.length - 1 && (
                                                                            
                                                                            <DividerWithText>
                                                                                <Chip
                                                                                    size="small"
                                                                                    // variant="outlined"
                                                                                    label={item["relType"] ? item["relType"] : "[ ]"}
                                                                                    variant={pathsSelected[createPathKey(combIndex, nodeIndex)]  ? "filled" : "outlined"}
                                                                                    sx={{
                                                                                        cursor: "pointer",
                                                                                        borderRadius: "0.3rem",
                                                                                        borderWidth: "1px",
                                                                                        borderColor: "#8C7ED7",
                                                                                        color: "#461E96",
                                                                                        backgroundColor: pathsSelected[createPathKey(combIndex, nodeIndex)] ? "#DDDBF7" : "#FFFFFF",
                                                                                        // ":hover": { backgroundColor: "#DDDBF7" }
                                                                                    }}
                                                                                />
                                                                            </DividerWithText>
                                                                        )
                                                                    }
                                                                </>
                                                            ) : (
                                                                <Chip
                                                                    size="small"
                                                                    variant={pathsSelected[createPathKey(combIndex, nodeIndex)]  ? "filled" : "outlined"}
                                                                    label={item["label"] ? item["label"] : "( )"}
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                        backgroundColor: pathsSelected[createPathKey(combIndex, nodeIndex)] ? "#DDDBF7" : "#FFFFFF",
                                                                        borderWidth: "1px",
                                                                        borderColor: "#8C7ED7",
                                                                        color: "#461E96"
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </Box>
                                        ));
                                    })}
                                </Box>
                            )
                        }
                    </Box>
                    <Divider sx={{
                        marginTop: "1rem"
                    }} />
                    {/* Conditions */}
                    <Box px="1.3rem" mt="1rem">
                        <Typography sx={{
                            fontSize: "1.3rem",
                            fontWeight: 200,
                            marginBottom: "0.5rem",
                        }}>
                            Conditions
                        </Typography>
                        <Box>
                            {/* create a button with icon at start */}
                            <Box>
                                {
                                    Object.keys(pathsSelected).length === 0 ? (
                                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                            <Typography sx={{
                                                fontSize: "1.2rem",
                                                fontWeight: 500,
                                                marginBottom: "0.5rem",
                                                textAlign: "center",
                                                paddingY: "1rem",
                                                paddingX: "3rem",
                                                borderStyle: "dashed",
                                                borderWidth: "1px",
                                                borderRadius: "0.5rem",
                                            }}>
                                                Please select relationship
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <>
                                            {queryConditions.map((condition, index) => (
                                                <Box key={index}>
                                                    <Box display={"flex"} gap={"3vh"} alignItems={"center"} border={"1px solid #8C7ED7"} padding={"1.5vh"} borderRadius={"5px"}>
                                                        {/* <IconButton edge="start"> */}
                                                        <CloseOutlinedIcon onClick={() => handleDeleteCondition(index)} sx={{ cursor: "pointer" }} />
                                                        {/* </IconButton> */}
                                                        <FormControl fullWidth size="small">
                                                            <InputLabel>Entity</InputLabel>
                                                            <Select
                                                                labelId="demo-select-small-label"
                                                                id="demo-select-small"
                                                                value={condition.entity}
                                                                label="Entity"
                                                                onChange={e => handleConditionInput(index, 'entity', e.target.value)}
                                                            // sx={{height: "4vh"}}
                                                            >
                                                                <Typography sx={{
                                                                    fontSize: "0.75rem",
                                                                    color: "#808080",
                                                                    padding: "2vh",
                                                                }}>Nodes</Typography>
                                                                {Array.from(labelSet).map((label, index) => (
                                                                    <MenuItem key={index} value={label}>{label}</MenuItem>
                                                                ))}
                                                                <Typography sx={{
                                                                    fontSize: "0.75rem",
                                                                    color: "#808080",
                                                                    padding: "2vh",
                                                                }}>Relations</Typography>
                                                                {Array.from(relationSet).map((relation, index) => (
                                                                    <MenuItem key={index} value={relation}>{relation}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl fullWidth size="small">
                                                            <InputLabel>Property</InputLabel>
                                                            <Select
                                                                labelId="demo-select-property-label"
                                                                id="demo-select-property"
                                                                value={condition.property}
                                                                label="Property"
                                                                onChange={e => handleConditionInput(index, 'property', e.target.value)}
                                                            >
                                                                {propertySet[condition.entity] && propertySet[condition.entity].map((property, index) => (
                                                                    <MenuItem key={index} value={property}>{property}</MenuItem>
                                                                ))}
                                                            </Select>

                                                        </FormControl>
                                                        <FormControl fullWidth size="small">
                                                            <InputLabel >Operator</InputLabel>
                                                            <Select
                                                                labelId="demo-select-operator-label"
                                                                id="demo-select-operator"
                                                                value={condition.operator}
                                                                label="Operator"
                                                                onChange={e => handleConditionInput(index, 'operator', e.target.value)}
                                                            >
                                                                {
                                                                    operators_List.map((operator, index) => (
                                                                        <MenuItem key={index} value={operator}>{operator}</MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>

                                                        {condition.operator !== "IS NULL" && condition.operator !== "IS NOT NULL" && (
                                                            <FormControl fullWidth size="small">
                                                                <TextField
                                                                    label="Value"
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onChange={e => handleConditionInput(index, 'value', e.target.value)}
                                                                    value={condition.value}
                                                                />
                                                            </FormControl>
                                                        )}

                                                    </Box>
                                                    <Box>
                                                        {index < queryConditions.length - 1 && (
                                                            <FormControl sx={{ width: "22%", marginTop: "1rem" }} size="small" >
                                                                <InputLabel id="demo-select-operator-label">And/Or</InputLabel>
                                                                <Select
                                                                    labelId="demo-select-operator-label"
                                                                    id="demo-select-operator"
                                                                    value={multiConditionOperators[index] ? multiConditionOperators[index] : ''}
                                                                    label="Operator"
                                                                    onChange={e => handleChangeAndOr(index, e.target.value)}
                                                                >
                                                                    <MenuItem value='and'>And</MenuItem>
                                                                    <MenuItem value='or'>Or</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    </Box>
                                                    <br />
                                                </Box>

                                            ))}
                                            <Button startIcon={<AddIcon />} sx={{
                                                textTransform: "none",
                                                fontSize: "1rem",
                                                padding: "0.2rem 0.5rem",
                                                backgroundColor: "#DDDBF7",
                                                color: "#461E96",
                                                borderWidth: "1px",
                                                borderColor: "#461E96",
                                                fontWeight: 500,
                                                marginTop: "2vh"
                                            }}
                                                onClick={handleAddCondition}>
                                                Add Condition
                                            </Button>
                                        </>)}
                            </Box>
                        </Box>
                    </Box>
                    <Divider sx={{
                        marginTop: "1rem"
                    }} />
                    {/* Options */}
                    <Box mt="1rem" px="1.3rem" display="flex" justifyContent="flex-end" alignItems="center" gap={"1vh"} >
                        <Typography sx={{
                            color: "red",
                            fontSize: "1rem",
                            fontWeight: 500,
                            fontFamily: "AB Gilroy",
                            // marginBottom: "0.5rem",
                            // marginRight: "1rem"
                        }}>
                            {buildError}
                        </Typography>
                        <Button variant="text" sx={{ fontSize: "1rem", textTransform: "none" }} onClick={() => resetQueryBuilder()}>Reset</Button>
                        <LoadingButton sx={{
                            color: "#ffffff",
                            border: "1px solid #461E96",
                            textTransform: "none",
                            fontSize: "1rem",
                            padding: "0.5rem 2rem",
                            backgroundColor: "#461E96",
                            ":hover": { backgroundColor: "#461E96" }
                        }}
                            variant="contained"
                            loading={buildQueryLoading}
                            onClick={() => onClickBuild()}
                        >Build</LoadingButton>
                    </Box>
                </Box>
            </Modal>
            <Toaster />
        </>
    )
}

export default QueryBuilder