import { NonClickableLabelChip } from "./styled";

import { GraphStyleModel } from "../../models/GraphStyle";
import { Box, Typography } from "@mui/material";

export type NodeLabelProps = {
  selectedLabel: {
    label: string;
    propertyKeys: string[];
    count?: number;
  };
  graphStyle: GraphStyleModel;
  /* The total number of nodes in returned graph */
  allNodesCount?: number | null;
  onNodeSelectedFromFilters?: (labelKey: string) => void;
  nodeSelectedFromFiltersState?: any;
};

const NodeLabelStyles = {
  height: "3.1rem",
  width: "100%",
  borderRadius: "1.5rem",
  border: "1px solid #D9D9D9",
  backgroundColor: "#ffffff",
  color: "#636363",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingX: "0.7rem",
  alignContent: "left",
  cursor: "pointer",
  ":hover": {
    backgroundColor: "#F5F5F5",
  },
}

export function NodeLabel({
  graphStyle,
  selectedLabel,
  allNodesCount,
  onNodeSelectedFromFilters,
  nodeSelectedFromFiltersState
}: NodeLabelProps): JSX.Element {
  const labels = selectedLabel.label === "*" ? [] : [selectedLabel?.label];
  const graphStyleForLabel = graphStyle.forNode({
    labels: labels,
  });
  const count =
    selectedLabel.label === "*" ? allNodesCount : selectedLabel.count;

  return (
    <>
      {
        selectedLabel?.label !== "*" && (
          <Box
            sx={
              nodeSelectedFromFiltersState.includes(selectedLabel.label)
                ? {
                  ...NodeLabelStyles,
                  // backgroundColor: "#F5F5F5",
                  border: "1px solid #461e96",
                  boxShadow: "0px 0px 0px 4px #DDDBF7"
                }
                : NodeLabelStyles
            }
            onClick={() => onNodeSelectedFromFilters(selectedLabel.label)}
          >
            <Box sx={{
              width: "2rem",
              height: "1.9rem",
              borderRadius: "50%",
              backgroundColor: graphStyleForLabel.get("color"),
            }}>
            </Box>
            <Typography>{selectedLabel?.label}</Typography>
            <Typography>{count}</Typography>
          </Box>
        )
      }
    </>
  )
}
