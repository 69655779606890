import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'

export default function SemanticLayer() {
    useEffect(() =>{
        window.scrollTo(0,0);
    })
    return (
        <Box paddingTop={"1rem"} paddingLeft={"20rem"} paddingRight={"3rem"} width={"85%"} paddingBottom={"10rem"}>
            <Typography fontSize={"25px"} textAlign={"center"}>Semantic Layer</Typography>
            <Typography marginTop={"2vh"} fontSize={"20px"}>What is a semantic layer?</Typography>
            <Typography color={"#3b3b3b"} marginTop={"2vh"}>
                In the world of knowledge graphs, a semantic layer acts like a translator between the raw data and the users.
                <br />
                Here's the technical breakdown:
            </Typography>
            <Typography marginTop={"2vh"} fontSize={"20px"}>The Problem: Complex Data:</Typography>
            <Typography color={"#3b3b3b"} marginTop={"2vh"}>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        Knowledge graphs store information using entities (like materials, customers) and relationships (like "bought," "located at").
                    </li>
                    <li>
                        This data structure is powerful, but it can be technical and challenging for non-technical users to understand directly.
                    </li>
                </ul>
            </Typography>
            <Typography marginTop={"2vh"} fontSize={"20px"}>The Solution: Semantic Layer</Typography>
            <Typography color={"#3b3b3b"} marginTop={"2vh"}>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        The semantic layer sits on top of the knowledge graph, acting as a middleware.
                    </li>
                    <li>
                        It takes the technical data model of the knowledge graph and transforms it into a business-friendly representation.
                    </li>
                </ul>
            </Typography>
            <Typography marginTop={"2vh"} fontSize={"20px"}>Key Functions:</Typography>
            <Typography color={"#3b3b3b"} marginTop={"2vh"}>
                <b>Business Vocabulary Mapping</b>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        Translates technical entity and relationship labels into business-meaningful terms.
                    </li>
                    <li>
                        For example, a technical entity named "customer_id" might become "Customer" in the semantic layer.
                    </li>
                </ul>
                <b>Context and Enrichment</b>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        Applies business rules and logic to the data to add context and meaning.
                    </li>
                    <li>
                        This might involve calculations, filtering, or adding additional information specific to the domain.
                    </li>
                </ul>
                <b>Unified Access Point:</b>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        Provides a single interface for users to query and explore the knowledge graph.
                    </li>
                    <li>
                        Users can interact with the data using:
                        <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
                            <li>
                                Natural language queries: Asking questions like "What materials did Walgreens buy last month?"
                            </li>
                            <li>
                                Business-specific search terms: Searching for "high-value customers" based on defined criteria.
                            </li>
                        </ul>
                    </li>

                </ul>
            </Typography>
            <Typography marginTop={"2vh"} fontSize={"20px"}>Benefits:</Typography>
            <Typography color={"#3b3b3b"} marginTop={"2vh"}>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                        <b>Improved Data Accessibility: </b>
                        Makes the knowledge graph more accessible and usable for business users by removing the technical complexities.
                    </li>
                    <li>
                        <b>Enhanced Data Exploration: </b>
                        Makes the knowledge graph more accessible and usable for business users by removing the technical complexities.
                    </li>
                    <li>
                        <b>Simplified Integration: </b>

                        Facilitates easier integration of data from various sources into the knowledge graph by providing a consistent representation.
                    </li>
                </ul>
            </Typography>
            
        </Box>
    )
}
