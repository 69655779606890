import { GraphModel } from "../../models/Graph";
import { NodeModel } from "../../models/Node";
import { RelationshipModel } from "../../models/Relationship";
import { ContextItem, GetNodeNeighboursFn, VizItem } from "../../types";
import {
  GraphStats,
  getGraphStats,
  mapNodes,
  mapRelationships,
} from "../../utils/mapper";
import { Visualization } from "./visualization/Visualization";
import { GraphStyleModel } from "../../models/GraphStyle";

export type GraphInteraction =
  | "NODE_EXPAND"
  | "NODE_UNPINNED"
  | "NODE_DISMISSED";

export type GraphInteractionCallBack = (
  event: GraphInteraction,
  properties?: Record<string, unknown>
) => void;

export class GraphEventHandlerModel {
  getNodeNeighbours: GetNodeNeighboursFn;
  graph: GraphModel;
  visualization: Visualization;
  onGraphModelChange: (stats: GraphStats) => void;
  onItemMouseOver: (item: VizItem) => void;
  onItemSelected: (item: VizItem) => void;
  onContextItemSelected: (item: ContextItem) => void;
  onGraphInteraction: GraphInteractionCallBack;
  selectedItem: NodeModel | RelationshipModel | null;
  graphStyle: GraphStyleModel;
  nodeHovered: NodeModel;
  contextItemSelected: ContextItem | null;
  onGraphEventsChange: (type: string, data: any) => void;

  constructor(
    graph: GraphModel,
    visualization: Visualization,
    getNodeNeighbours: GetNodeNeighboursFn,
    onItemMouseOver: (item: VizItem) => void,
    onItemSelected: (item: VizItem) => void,
    onContextItemSelected: (item: ContextItem) => void,
    onGraphModelChange: (stats: GraphStats) => void,
    onGraphInteraction?: (event: GraphInteraction) => void,
    onGraphEventsChange?: (type: string, data: any) => void
  ) {
    this.graph = graph;
    this.visualization = visualization;
    this.getNodeNeighbours = getNodeNeighbours;
    this.selectedItem = null;
    this.onItemMouseOver = onItemMouseOver;
    this.onItemSelected = onItemSelected;
    this.onContextItemSelected = onContextItemSelected;
    this.onGraphInteraction = onGraphInteraction ?? (() => undefined);
    this.onGraphModelChange = onGraphModelChange;
    this.graphStyle = new GraphStyleModel(false);
    this.onGraphEventsChange = onGraphEventsChange;
  }

  graphModelChanged(): void {
    this.onGraphModelChange(getGraphStats(this.graph));
  }

  selectItem(item: NodeModel | RelationshipModel): void {
    if (this.selectedItem) {
      this.selectedItem.selected = false;
    }
    this.selectedItem = item;
    item.selected = true;

    this.visualization.update({
      updateNodes: this.selectedItem.isNode,
      updateRelationships: this.selectedItem.isRelationship,
      restartSimulation: false,
    });
  }

  deselectItem(): void {
    if (this.selectedItem) {
      this.selectedItem.selected = false;

      this.visualization.update({
        updateNodes: this.selectedItem.isNode,
        updateRelationships: this.selectedItem.isRelationship,
        restartSimulation: false,
      });

      this.selectedItem = null;
    }
    this.onItemSelected({
      type: "canvas",
      item: {
        nodeCount: this.graph.nodes().length,
        relationshipCount: this.graph.relationships().length,
      },
    });
  }

  nodeClose(d: NodeModel): void {
    this.graph.removeConnectedRelationships(d);
    this.graph.removeNode(d);
    this.deselectItem();
    this.visualization.update({
      updateNodes: true,
      updateRelationships: true,
      restartSimulation: true,
    });
    this.graphModelChanged();
    this.onGraphInteraction("NODE_DISMISSED");
  }

  nodeClicked(node: NodeModel): void {
    // if (!node) {
    //   return;
    // }
    // node.hoverFixed = true;
    // node.fx = node.x;
    // node.fy = node.y;
    // if (!node.selected) {
    //   this.selectItem(node);
    //   this.onItemSelected({
    //     type: "node",
    //     item: node,
    //   });
    // } else {
    //   this.deselectItem();
    // }
  }

  nodeUnlock(d: NodeModel): void {
    if (!d) {
      return;
    }
    d.fx = null;
    d.fy = null;
    this.deselectItem();
    this.onGraphInteraction("NODE_UNPINNED");
  }

  nodeDblClicked(node: NodeModel): void {
    // if (d.expanded) {
    //   this.nodeCollapse(d);
    //   return;
    // }
    // d.expanded = true;
    // const graph = this.graph;
    // const visualization = this.visualization;
    // const graphModelChanged = this.graphModelChanged.bind(this);
    // this.getNodeNeighbours(
    //   d,
    //   this.graph.findNodeNeighbourIds(d.id),
    //   ({ nodes, relationships }) => {
    //     graph.addExpandedNodes(d, mapNodes(nodes));
    //     graph.addRelationships(mapRelationships(relationships, graph));
    //     visualization.update({ updateNodes: true, updateRelationships: true });
    //     graphModelChanged();
    //   }
    // );
    // this.onGraphInteraction("NODE_EXPAND");

    if (!node) {
      return;
    }

    node.hoverFixed = true;
    node.fx = node.x;
    node.fy = node.y;
    if (!node.selected) {
      this.selectItem(node);
      this.onItemSelected({
        type: "node",
        item: node,
      });
    } else {
      this.deselectItem();
    }
  }

  nodeRightClicked(d: any) {
    const contextItem: ContextItem = {
      type: "context-menu-item",
      item: {
        event: d?.event,
        node: {
          ...d?.node,
          selected: true,
        },
      },
    };
    this.onContextItemSelected(contextItem);
    this.contextItemSelected = contextItem;
  }

  nodeCollapse(d: NodeModel): void {
    d.expanded = false;
    this.graph.collapseNode(d);
    this.visualization.update({ updateNodes: true, updateRelationships: true });
    this.graphModelChanged();
  }

  onNodeMouseOver(node: NodeModel): void {
    // if (!node.contextMenu) {
    //   this.onItemMouseOver({
    //     type: "node",
    //     item: node,
    //   });
    // }
    // this.nodeHovered = node;
  }

  onMenuMouseOver(itemWithMenu: NodeModel): void {
    if (!itemWithMenu.contextMenu) {
      throw new Error("menuMouseOver triggered without menu");
    }
    // this.onItemMouseOver({
    //   type: "context-menu-item",
    //   item: {
    //     label: itemWithMenu.contextMenu.label,
    //     content: itemWithMenu.contextMenu.menuContent,
    //     selection: itemWithMenu.contextMenu.menuSelection,
    //   },
    // });
  }

  onRelationshipMouseOver(relationship: RelationshipModel): void {
    // this.onItemMouseOver({
    //   type: "relationship",
    //   item: relationship,
    // });
    this.onGraphEventsChange("relHovered", relationship);
  }

  onRelationshipClicked(relationship: RelationshipModel): void {
    // if (!relationship.selected) {
    //   this.selectItem(relationship);
    //   this.onItemSelected({
    //     type: "relationship",
    //     item: relationship,
    //   });
    // } else {
    //   this.deselectItem();
    // }
  }

  onRelationshipDoubleClicked(relationship: RelationshipModel): void {
    if (!relationship.selected) {
      this.selectItem(relationship);
      this.onItemSelected({
        type: "relationship",
        item: relationship,
      });
    } else {
      this.deselectItem();
    }
  }

  onCanvasClicked(): void {
    this.deselectItem();
    this.deselectContextItem();
    this.onGraphEventsChange("resetStyles",null)
  }



  deselectContextItem(): void {
    this.onContextItemSelected({
      type: "context-menu-item",
      item: {
        node: null,
        event: null,
      },
    });
    this.contextItemSelected = null;
  }


  onItemMouseOut(): void {
    this.onItemMouseOver({
      type: "canvas",
      item: {
        nodeCount: this.graph.nodes().length,
        relationshipCount: this.graph.relationships().length,
      },
    });
  }

  nodeClickedWithCtrlKey(node: NodeModel) {
    this.onGraphEventsChange("nodeClickedWithCtrlKey", node);
  }

  bindEventHandlers(): void {
    this.visualization
      .on("nodeMouseOver", this.onNodeMouseOver.bind(this))
      .on("nodeMouseOut", this.onItemMouseOut.bind(this))
      .on("menuMouseOver", this.onMenuMouseOver.bind(this))
      .on("menuMouseOut", this.onItemMouseOut.bind(this))
      .on("relMouseOver", this.onRelationshipMouseOver.bind(this))
      .on("relMouseOut", this.onItemMouseOut.bind(this))
      .on("relationshipClicked", this.onRelationshipClicked.bind(this))
      .on("relationshipDoubleClicked",this.onRelationshipDoubleClicked.bind(this))
      .on("canvasClicked", this.onCanvasClicked.bind(this))
      .on("nodeClose", this.nodeClose.bind(this))
      .on("nodeClicked", this.nodeClicked.bind(this))
      .on("nodeDblClicked", this.nodeDblClicked.bind(this))
      .on("nodeUnlock", this.nodeUnlock.bind(this))
      .on("nodeRightClicked", this.nodeRightClicked.bind(this))
      .on("nodeClickedWithCtrlKey", this.nodeClickedWithCtrlKey.bind(this)); 
    this.onItemMouseOut();
  }
}
