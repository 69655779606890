/**
 * LoginScreen Component
 *
 * The `LoginScreen` component is responsible for rendering the login UI, including the login button and other relevant elements.
 *
 * @param {Object} props - Component props
 * @param {Function} props.onClickClickhere - Callback function to handle the click event when the user clicks the "click here" link.
 * @param {Function} props.onClickLogin - Callback function to handle the click event when the user clicks the login button.
 *
 * @returns {React.ReactNode} The `LoginScreen` component.
 */

import { Box, Button, Typography } from '@mui/material'
import { logInScreenData } from '../../../constants/screensData'
import { LoginScreenProps } from '../../../@types/components'
import { styles } from "./LoginScreen.styles";

export default function LoginScreen({ onClickLogin }: LoginScreenProps) {
  return (
    <Box
      sx={styles.container}
      data-testid="login-component">
      <img
        src={logInScreenData.header.logo}
        alt="logo"
        style={styles.logo}
      />  
      <Box sx={{position:"absolute", top:"30%", left:"10%"}}>
      <Typography 
      sx={{ 
        color: "#FFFFFF", 
        fontFamily: 'AbGilroy Medium', 
        fontSize:"2rem",
        lineHeight:1}}>Welcome</Typography>
      <Typography
        sx={{
          color: "#FFFFFF",
          fontFamily: 'AbGilroy Bold',
          fontSize:"2rem"

        }}>Graph Explorer </Typography>

      <Button
        variant="contained"
        size="large"
        sx={{
          background: "linear-gradient(90deg, #FF05E6 0.06%, #4400D6 98.7%)",
          fontFamily: 'AbGilroy Bold',
          textTransform: "none",
          fontSize:"1rem",
          marginTop:"3vh",
          paddingLeft:'4rem',
          paddingRight:"4rem"
        }}
        onClick={onClickLogin}
        data-testid="login-button"
      >
        Login with SSO
      </Button>
        </Box>    
      
    </Box>
  ) 
}
