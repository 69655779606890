import { InboxIcon } from "@heroicons/react/24/solid";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Button, Collapse, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListSubheader, TextField, Toolbar, Tooltip, Typography } from "@mui/material"
import ListItemText from '@mui/material/ListItemText';
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {setTabIndex} from "../../redux/reducers/tabReducer";
import { setGetStartedModal } from "../../redux/reducers/getStartedModalReducer";

const MenuDrawer = ({ isOpen, handleClose }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleClick = () => {
        setOpen(!open);
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    // const handleClickTab = (tab) => {
    //     setTab(tab)
    // }

    return (
        <>
            <Drawer
                open={isOpen}
                onClose={handleClose}
                variant="temporary"
                sx={{
                    width: 290,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: 290, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', paddingTop: "4vh" }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                {/* Nested List Items */}
                            </ListSubheader>
                        }
                    >
                        <ListItemButton onClick={() => { navigate('/views'); dispatch(setGetStartedModal(true)); handleClose()}}>
                            <ListItemText primary="Get Started" />
                        </ListItemButton>
                        <Divider variant="middle" component="li" />
                        <ListItemButton onClick={handleClick}>
                            <ListItemText primary="Resources" />
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Divider variant="middle" component="li" />
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }} onClick={()=>{(dispatch(setTabIndex(1))); navigate('/resource'); handleClose()}}>
                                    <ListItemText primary="About Knowledge graph" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} onClick={()=>{(dispatch(setTabIndex(2))); navigate('/resource'); handleClose()}}>
                                    <ListItemText primary="Customize a Cypher query" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} onClick={()=>{(dispatch(setTabIndex(3))); navigate('/resource'); handleClose()}}>
                                    <ListItemText primary="What is ontology" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} onClick={()=>{(dispatch(setTabIndex(4))); navigate('/resource'); handleClose()}}>
                                    <ListItemText primary="What is semantic layer" />
                                </ListItemButton>
                            </List>
                        <Divider variant="middle" component="li" />
                        </Collapse>
                        <ListItemButton onClick={()=>{navigate('/views'); handleClose()}}>
                            <ListItemText primary="Area of Interest" />
                        </ListItemButton>
                        <Divider variant="middle" component="li" />
                        <ListItemButton >
                            <ListItemText primary="Semantic layer" />
                        </ListItemButton>


                    </List>
                </Box>
            </Drawer>
        </>
    )
}

export default MenuDrawer;
