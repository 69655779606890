import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks/hook";
import { CustomToast } from "../../helpers/customToast";

const Settings = ({ isSettingsOpen }: any) => {
    const dispatch = useAppDispatch();
    
    // Set initial state by checking localStorage
    const [maxNodes, setMaxNodes] = useState<number>(() => {
        const storedMaxNodes = localStorage.getItem("maxNodes");
        return storedMaxNodes ? Number(storedMaxNodes) : 1000;
    });

    // Local state for input value
    const [inputMaxNodes, setInputMaxNodes] = useState<number>(maxNodes);

    // Save maxNodes to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem("maxNodes", String(maxNodes));
    }, [maxNodes]);

    const handleMaxNodes = () => {
        setMaxNodes(inputMaxNodes);
        CustomToast(`Maximum nodes to visualize set to ${inputMaxNodes}`, {
            type: "success",
            position: "top-center",
            duration: 2000, 
        });
    };

    return (
        <>
            {isSettingsOpen && (
                <Box sx={{
                    width: '25rem',
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    left: "4.5rem",
                    top: 60,
                    height: "calc(100vh - 60px)",
                    zIndex: 1000,
                }}>
                    <Box sx={{
                        borderBottom: "1px solid #E0E0E0",
                        paddingTop: "1.2rem",
                        paddingBottom: "1rem",
                    }}>
                        <Typography sx={{
                            textAlign: "center",
                            fontWeight: 600,
                            fontSize: "1.2rem",
                        }}>
                            Settings
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "1rem",
                        gap: "1rem",
                        overflowY: "auto"
                    }}>
                        <TextField
                            id="outlined-basic"
                            type="number"
                            label="Max Node Limit"
                            variant="outlined"
                            size="small"
                            value={inputMaxNodes}
                            onChange={(e) => setInputMaxNodes(Number(e.target.value))}
                        />
                        <Box display={'flex'} gap={'1vh'} justifyContent={'flex-end'}>
                            <Button variant="contained" onClick={handleMaxNodes}>Apply</Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default Settings;

