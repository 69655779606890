import { Box, Typography } from '@mui/material'
import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const CodeSnippet = ({ code, name = "Code Snippet" }) => {
    return (
        <Box sx={{ backgroundColor: "#1e1e1e", padding: "3vh", paddingBottom: "1vh", borderRadius: "5px" }}>
            <Typography color="#fff" paddingLeft="2vh" borderLeft="3px solid #388FDF">{name}</Typography>
            <SyntaxHighlighter language="sql" style={vs2015} >
                {code}
            </SyntaxHighlighter>
        </Box>
    );
};

export default CodeSnippet;
