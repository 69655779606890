import deepmerge from "deepmerge";
import neo4j from "neo4j-driver"
import { add, debounce } from "lodash-es";
import React, { Component } from "react";
import { deduplicateNodes, extractNodesAndRelationshipsFromItemsAndFormat } from "../../../helpers/neo4jFormat";
import { Graph as Neo4jGraph } from "./Graph/Graph";
import { NodeInspectorPanel, defaultPanelWidth } from "./NodeInspectorPanel";
import { StyledFullSizeContainer, panelMinWidth } from "./styled";
import {
  BasicNode,
  BasicNodesAndRels,
  BasicRelationship,
  deepEquals,
} from "../../../common";
import { OverviewPaneProps } from "./DefaultPanelContent/DefaultFiltersPane";
import { GraphStyleModel } from "../models/GraphStyle";
import { ContextItem, GetNodeNeighboursFn, VizItem } from "../types";
import { GraphStats } from "../utils/mapper";
import { GraphModel } from "../models/Graph";
import { GraphEventHandlerModel, GraphInteractionCallBack } from "./Graph/GraphEventHandlerModel";
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import ContextMenuBox from "./Graph/visualization/renderers/contextMenu";
import Graph from "graphology";
import { bidirectional } from "graphology-shortest-path"
import { NodeModel } from "../models/Node";
import { Toaster } from "react-hot-toast";
import { CustomToast } from "../../../helpers/customToast";
import { RelationshipModel } from "../models/Relationship";
import { Visualization } from "./Graph/visualization/Visualization";
import { runCipherQuery } from "../../../api/api";
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';

const DEFAULT_MAX_NEIGHBOURS = 100;

type GraphVisualizerDefaultProps = {
  maxNeighbours: number;
  updateStyle: (style: any) => void;
  isFullscreen: boolean;
  assignVisElement: (svgElement: any, graphElement: any) => void;
  getAutoCompleteCallback: (
    callback: (rels: BasicRelationship[], initialRun: boolean) => void
  ) => void;
  setGraph: (graph: GraphModel) => void;
  hasTruncatedFields: boolean;
  nodePropertiesExpandedByDefault: boolean;
  setNodePropertiesExpandedByDefault: (expandedByDefault: boolean) => void;
  wheelZoomInfoMessageEnabled?: boolean;
  initialZoomToFit?: boolean;
  disableWheelZoomInfoMessage: () => void;
  useGeneratedDefaultColors: boolean;
  isFilterOpen: boolean;
  graphLayoutStyles: any;
  graphologyObject: Graph;
};
type GraphVisualizerProps = GraphVisualizerDefaultProps & {
  relationships: BasicRelationship[];
  nodes: BasicNode[];
  maxNeighbours?: number;
  graphStyleData?: any;
  getNeighbours?: (
    id: string,
    currentNeighbourIds: string[] | undefined
  ) => Promise<BasicNodesAndRels & { allNeighboursCount: number }>;
  updateStyle?: (style: any) => void;
  isFullscreen?: boolean;
  assignVisElement?: (svgElement: any, graphElement: any) => void;
  getAutoCompleteCallback?: (
    callback: (rels: BasicRelationship[], initialRun: boolean) => void
  ) => void;
  setGraph?: (graph: GraphModel) => void;
  hasTruncatedFields?: boolean;
  nodeLimitHit?: boolean;
  nodePropertiesExpandedByDefault?: boolean;
  setNodePropertiesExpandedByDefault?: (expandedByDefault: boolean) => void;
  wheelZoomRequiresModKey?: boolean;
  wheelZoomInfoMessageEnabled?: boolean;
  disableWheelZoomInfoMessage?: () => void;
  DetailsPaneOverride?: React.FC<any>;
  OverviewPaneOverride?: React.FC<OverviewPaneProps>;
  onGraphInteraction?: GraphInteractionCallBack;
  useGeneratedDefaultColors?: boolean;
  autocompleteRelationships: boolean;
};

type GraphVisualizerState = {
  graphStyle: GraphStyleModel;
  hoveredItem: VizItem;
  nodes: BasicNode[];
  relationships: BasicRelationship[];
  selectedItem: VizItem;
  stats: GraphStats;
  styleVersion: number;
  freezeLegend: boolean;
  width: number;
  nodePropertiesExpanded: boolean;
  contextBoxX: any;
  contextBoxY: any;
  contextItemSelect: ContextItem;
  graphModel: GraphModel;
  graphHandler: GraphEventHandlerModel;

};

export class GraphVisualizer extends Component<
  GraphVisualizerProps,
  GraphVisualizerState
> {
  defaultStyle: any;
  visualization: Visualization | null;
  prevRelWidth: number;
  shortestPathRelIds: string[];
  shortestPathSourceNode?: NodeModel | null;
  shortestPathTargetNode?: NodeModel | null;
  contextItemSelected: NodeModel | null;
  shortestPathFound: boolean;

  static defaultProps: GraphVisualizerDefaultProps = {
    maxNeighbours: DEFAULT_MAX_NEIGHBOURS,
    updateStyle: () => undefined,
    isFullscreen: false,
    assignVisElement: () => undefined,
    getAutoCompleteCallback: () => undefined,
    setGraph: () => undefined,
    hasTruncatedFields: false,
    nodePropertiesExpandedByDefault: false,
    setNodePropertiesExpandedByDefault: () => undefined,
    wheelZoomInfoMessageEnabled: false,
    disableWheelZoomInfoMessage: () => undefined,
    useGeneratedDefaultColors: false,
    isFilterOpen: false,
    graphLayoutStyles: {},
    graphologyObject: new Graph()
  };


  constructor(props: GraphVisualizerProps) {
    super(props);
    const graphStyle = new GraphStyleModel(
      this.props.useGeneratedDefaultColors
    );
    this.defaultStyle = graphStyle.toSheet();
    const {
      nodeLimitHit,
      nodes,
      relationships,
      nodePropertiesExpandedByDefault,
    } = this.props;

    const selectedItem: VizItem = nodeLimitHit
      ? {
        type: "status-item",
        item: `Not all return nodes are being displayed due to Initial Node Display setting. Only first ${this.props.nodes.length} nodes are displayed.`,
      }
      : {
        type: "canvas",
        item: {
          nodeCount: nodes.length,
          relationshipCount: relationships.length,
        },
      };


    if (this.props.graphStyleData) {
      const rebasedStyle = deepmerge(
        this.defaultStyle,
        this.props.graphStyleData
      );
      graphStyle.loadRules(rebasedStyle);
    }
    this.state = {
      stats: {
        labels: {},
        relTypes: {},
      },
      graphStyle,
      styleVersion: 0,
      nodes,
      relationships,
      selectedItem,
      hoveredItem: selectedItem,
      freezeLegend: false,
      width: defaultPanelWidth(),
      nodePropertiesExpanded: nodePropertiesExpandedByDefault,
      contextBoxX: 0,
      contextBoxY: 0,
      contextItemSelect: null,
      graphModel: null,
      graphHandler: null,
    };


  }

  getNodeNeighbours: GetNodeNeighboursFn = (
    node,
    currentNeighbourIds,
    callback
  ) => {
    if (currentNeighbourIds.length > this.props.maxNeighbours) {
      callback({ nodes: [], relationships: [] });
    }
    if (this.props.getNeighbours) {
      this.props.getNeighbours(node.id, currentNeighbourIds).then(
        ({ nodes, relationships, allNeighboursCount }) => {
          if (allNeighboursCount > this.props.maxNeighbours) {
            this.setState({
              selectedItem: {
                type: "status-item",
                item: `Rendering was limited to ${this.props.maxNeighbours} of the node's total ${allNeighboursCount} neighbours due to browser config maxNeighbours.`,
              },
            });
          }
          callback({ nodes, relationships });
        },
        () => {
          callback({ nodes: [], relationships: [] });
        }
      );
    }
  };

  /**
   * Triggers when any events are triggered in the graph
   * @param type - type of the event
   * @param data - event data
   */
  onGraphEventsChange = (type: string, data?: any) => {
    switch (type) {
      case "nodeClickedWithCtrlKey":
        // update the shortest source and target node
        if (this.shortestPathSourceNode) {
          this.shortestPathTargetNode = data;
        } else if (!this.shortestPathTargetNode) {
          this.shortestPathSourceNode = data;
        }
        this.shortestPath()
        break;
      case "resetStyles":
        this.resetShortestPath()
        this.resetNeighbors()
        break;
      case "relHovered":
        this.showShortestPathToast(data)
        break;
      default:
        break;
    }
  }

  onItemMouseOver(item: VizItem): void {
    this.setHoveredItem(item);
  }

  mounted = true;
  setHoveredItem = debounce((hoveredItem: VizItem) => {
    if (this.mounted) {
      this.setState({ hoveredItem });
    }
  }, 200);

  onItemSelect(selectedItem: VizItem): void {
    this.setState({ selectedItem });
  }

  setGraphEventHandler = (handler: GraphEventHandlerModel) => {
    // store the graph event handler in state 
    this.setState({ graphHandler: handler });
  }

  setGraph = (graphModel: GraphModel) => {
    if (graphModel) {
      this.setState({
        graphModel: graphModel
      })
      this.autoCompleteRelationships([], graphModel.nodes(), true)
    }
  };

  autoCompleteCallback?: (
    rels: BasicRelationship[],
    initialRun: boolean
  ) => void

  autoCompleteRelationships(
    existingNodes: { id: string }[],
    newNodes: { id: string }[],
    initialRun: boolean
  ): void {
    if (this.props.autocompleteRelationships) {
      const existingNodeIds = existingNodes.map(node => parseInt(node.id))
      const newNodeIds = newNodes.map(node => parseInt(node.id))
      this.getInternalRelationships(existingNodeIds, newNodeIds).then(graph => {
        this.autoCompleteCallback &&
          this.autoCompleteCallback(graph.relationships, initialRun)
      })
    } else {
      this.autoCompleteCallback && this.autoCompleteCallback([], initialRun)
    }
  }

  async getInternalRelationships(
    rawExistingNodeIds: number[],
    rawNewNodeIds: number[]
  ): Promise<BasicNodesAndRels> {
    const newNodeIds = rawNewNodeIds.map(n => neo4j.int(n))
    const existingNodeIds = rawExistingNodeIds
      .map(n => neo4j.int(n))
      .concat(newNodeIds)
    const query =
      `MATCH q=(a)-[r]->(b) WHERE id(a) IN [${existingNodeIds}] AND id(b) IN [${newNodeIds}] RETURN q;`
    // execute the cipher query
    const apiResult = await runCipherQuery(query)
    const { nodes, relationships } = apiResult.graphData && extractNodesAndRelationshipsFromItemsAndFormat(apiResult.graphData, 500)
    if (nodes || relationships) {
      return {
        nodes,
        relationships
      }
    }
    return {
      nodes: [],
      relationships: []
    }
  }

  /**
   * Show the toast if shortest path is found
   */
  showShortestPathToast = (rel: RelationshipModel) => {
    if (this.shortestPathFound) {
      if (this.shortestPathRelIds.includes(rel.elementId)) {
        CustomToast(`Path found with ${this.shortestPathRelIds.length === 1 ? `${this.shortestPathRelIds.length} hop` : `${this.shortestPathRelIds.length} hops`}`, {
          type: "success",
          duration: 3000
        })
      }
    }
  }

  /**
   * Triggered when the user clicks on the context menu item
   * @param type - the menu item type selected from context menu
   */
  onClickContextItemClick = (type: string) => {
    switch (type) {
      case "Find Shortest Path":
        this.shortestPath(true)
        break;
      case "Find Neighbours":
        this.findNeighbours(this.state.contextItemSelect?.item?.node)
        break;
      case "Collapse Nodes":
        this.collapseNodes()
        break;
      case "Reset Collapse":
        this.resetCollapse()
        break;
      default:
        break;
    }
  }

  /**
   * Filter the nodes in the graph
   * @param label - the label selected from filters
   */
  onNodeSelectedFromFilters = (label: string) => {
    // remove the all the nodes which having the label 
    const nodes = this.state.graphModel.findAllNodesByLabel(label, false)
    if (nodes.length > 0) {
      nodes.forEach((eachNode) => {
        this.state.graphModel.removeNode(eachNode)
        this.state.graphModel.removeConnectedRelationships(eachNode)
      })
    } else {
      const removedNodes = this.state.graphModel.findAllNodesByLabel(label, true)
      if (removedNodes.length > 0) {
        removedNodes.forEach((eachNode) => {
          this.state.graphModel.addNodes([eachNode])
          const rels = this.state.graphModel.findAllRelationshipToNode(eachNode, true)
          this.state.graphModel.addRelationships(rels)
        })
      }
    }
    this.setState({
      styleVersion: this.state.styleVersion + 1
    })
  }

  /**
   * Filter the relationships in the graph
   * @param relType - the relationship type selected from filters
   */
  onRelSelectedFromFilters = (relType: string) => {
    const rels = this.state.graphModel.findAllRelsByType(relType, false)
    // remove all the rels
    if (rels.length > 0) {
      rels.forEach((eachRel) => {
        this.state.graphModel.removeOrAddRelationship(eachRel, false)
      })
    } else {
      const removedRels = this.state.graphModel.findAllRelsByType(relType, true)
      if (removedRels.length > 0) {
        removedRels.forEach((eachRel) => {
          this.state.graphModel.removeOrAddRelationship(eachRel, true)
        })
      }
    }
    this.setState({
      styleVersion: this.state.styleVersion + 1
    })
  }

  /**
   * Reset the collapse nodes in the graph by adding the nodes and relationships
   * and updating the node attributes
   */
  resetCollapse = () => {
    // hide the context menu
    this.state.graphHandler.deselectContextItem();
    const node = this.state.contextItemSelect?.item?.node
    if (node) {
      const collapseIds = node?.propertyMap["Nodes_collapsed"]
      if (collapseIds) {
        const collapseIdsArray = collapseIds.split(",")
        const deCollapsedNodes: NodeModel[] = []
        const deCollapsedRelationships: RelationshipModel[] = []
        collapseIdsArray.forEach((eachId) => {
          const removedNode = this.state.graphModel.findNode(eachId, true)
          if (removedNode) {
            const rels = this.state.graphModel.findAllRelationshipToNode(removedNode, true)
            rels.forEach((eachRel) => {
              deCollapsedRelationships.push(eachRel)
            })
            deCollapsedNodes.push(removedNode)
          }
        })
        if (deCollapsedNodes) {
          this.state.graphModel.addNodes(deCollapsedNodes)
          this.state.graphModel.addRelationships(deCollapsedRelationships)
          const prevNodeAttr = this.props.graphologyObject.getNodeAttributes(node.id)
          this.state.graphModel.updateNodeAttributes(node, { "Nodes_collapsed": undefined, "No_of_nodes_collapsed": prevNodeAttr?.properties?.No_of_nodes_collapsed })
        }
        this.setState({
          styleVersion: this.state.styleVersion + 1
        })
      }
    }
  }


  /**
   * Collapse the nodes in the graph  by removing the nodes and relationships 
   * from the graph and updating the node attributes
   */
  collapseNodes = () => {
    // hide the context menu
    this.state.graphHandler.deselectContextItem();
    const node = this.state.contextItemSelect?.item?.node
    const neighbours = this.state.graphModel.findAllRelationshipToNode(node)
    if (neighbours.length === 1) {
      const parentNode = neighbours[0].target
      const childNode = neighbours[0].source
      // find all the relations of parentNode
      const parentRelations = this.state.graphModel.findAllRelationshipToNode(parentNode)
      // now remove all the childnodes except the childnode from source
      let collapseNodesIds = [];
      let collapseNodesIdsCount = 0
      parentRelations.forEach((eachRelation) => {
        // now here check if the source node in each relation have any other relationships apart from parent node relation if there then don't remove that node and get the count of nodes removing 
        const sourceNodeRelations = this.state.graphModel.findAllRelationshipToNode(eachRelation.source)
        if (sourceNodeRelations.length === 1 && (eachRelation.source.id !== childNode.id)) {
          this.state.graphModel.removeNode(eachRelation.source)
          this.state.graphModel.removeConnectedRelationships(eachRelation.source)
          // push the collapse nodes and update the count
          collapseNodesIds.push(eachRelation.source.id)
          collapseNodesIdsCount += 1;
        }
      })
      if (collapseNodesIdsCount > 0) {
        //update the node attributes
        this.state.graphModel.updateNodeAttributes(node, {
          "Nodes_collapsed": collapseNodesIds.join(","),
          "No_of_nodes_collapsed": collapseNodesIdsCount.toString()
        })
        CustomToast(` ${collapseNodesIdsCount} nodes collapsed`, {
          type: "success",
          duration: 5000
        })
      } else {
        CustomToast("No nodes to collapse", {
          type: "error",
          duration: 5000
        })
      }
      this.setState({
        styleVersion: this.state.styleVersion + 1
      })
    }
  }

  /**
   * Reset the shortest path  between two nodes
   */
  resetShortestPath = () => {
    if (this.shortestPathSourceNode || this.shortestPathTargetNode) {
      // get the item for styles
      const sourceNodeStyle = this.shortestPathSourceNode && this.state.graphStyle.forNode(this.shortestPathSourceNode)
      const targetNodeStyle = this.shortestPathTargetNode && this.state.graphStyle.forNode(this.shortestPathTargetNode)
      // clear styles for source node and target node
      sourceNodeStyle && this.state.graphStyle.updateNodeStylesById(`node-${this.shortestPathSourceNode?.elementId}`, sourceNodeStyle.props["border-color"], sourceNodeStyle.props["border-width"])
      targetNodeStyle && this.state.graphStyle.updateNodeStylesById(`node-${this.shortestPathTargetNode?.elementId}`, targetNodeStyle.props["border-color"], targetNodeStyle.props["border-width"])
      // Clear styles for relationships
      this.shortestPathRelIds && this.shortestPathRelIds.forEach((eachEdgeId, index) => {
        const rel = this.state.graphModel.findRelationship(eachEdgeId)
        // update the default shaft width
        this.prevRelWidth = parseFloat(
          this.state.graphStyle.forRelationship(rel).get("shaft-width")
        )
        const prevRelColor = this.state.graphStyle.forRelationship(rel).get("color")
        this.state.graphStyle.updateRelationshipStylesById(`relationship-${eachEdgeId}`, prevRelColor)
      })

      // clear rel width 
      this.shortestPathRelIds && this.visualization.updateShortestPathWidth(this.shortestPathRelIds, this.prevRelWidth)

      // clear the default shortest path rel ids
      this.shortestPathRelIds = []
      // clear the source and target nodes
      this.shortestPathSourceNode = null
      this.shortestPathTargetNode = null

      // update the shortest path found
      this.shortestPathFound = false
    }
  }

  /**
   * Finds the shortest path between two nodes
   * @param show - if true then show the shortest path
   */
  shortestPath = (show?: boolean) => {
    this.state.graphHandler.deselectContextItem()
    const edgesIds = []
    if (this.shortestPathSourceNode && this.shortestPathTargetNode && show) {
      // use graphology to find the shortest path
      const path = bidirectional(this.props.graphologyObject, this.shortestPathSourceNode.id, this.shortestPathTargetNode.id)
      path && path.forEach((eachPath, index) => {
        if (index !== 0) {
          this.props.graphologyObject.findEdge(path[index - 1], eachPath, (p: any) => {
            const rel = this.state.graphModel.findRelationship(p)
            edgesIds.push(rel?.elementId)
          })
        }
      })
      if (edgesIds.length > 0) {
        // update the shortest path  relationship ids in graph handler for clearing the styles when user clicks outside
        // this.state.graphHandler.shortestPathRealationsIds = edgesIds
        // update the shortest path relationships ids
        this.shortestPathRelIds = edgesIds
        // update the styles of the edges
        edgesIds && edgesIds.forEach((eachEdgeId, index) => {
          this.state.graphStyle.updateRelationshipStylesById(`relationship-${eachEdgeId}`, "#461E96")
        })
        // update the shortest path found
        this.shortestPathFound = true
        // update the shortest paths widths
        this.visualization?.updateShortestPathWidth(edgesIds, 10)
        CustomToast(`Path found with ${edgesIds.length === 1 ? `${edgesIds.length} hop` : `${edgesIds.length} hops`}`, {
          type: "success",
          duration: 5000
        })
      } else {
        CustomToast("No hops found", {
          type: "error",
          duration: 5000
        })
      }
    } else if (this.shortestPathSourceNode && !this.shortestPathTargetNode) {
      // if source is there add styles to it and close the context menu
      this.state.graphStyle.updateNodeStylesById(`node-${this.shortestPathSourceNode?.elementId}`, "#461E96", "5px")
      // show user to select the target node
      CustomToast("Select target node", {
        type: "error",
        duration: 5000
      })
    } else if (this.shortestPathTargetNode) {
      // if target is there add styles to it and close the context menu
      this.state.graphStyle.updateNodeStylesById(`node-${this.shortestPathTargetNode?.elementId}`, "#461E96", "5px")

    }
    else if (!this.shortestPathSourceNode && !this.shortestPathTargetNode) {
      CustomToast("Select source and target node to establish path", {
        type: "error",
        duration: 5000
      })
    }
  }

  /**
   * Finds the relations to a node
   * @param node - the node where context items is clicked
   */
  findNeighbours(node: NodeModel) {
    if (node) {
      // update the context item selected
      this.contextItemSelected = node
      // hide the context menu
      this.state.graphHandler.deselectContextItem()
      // find the relations to a node
      const rels = this.state.graphModel.findAllRelationshipToNode(node);
      // Push all the styles for rels and source nodes
      const relsStyles: any = []
      const sourceNodeStyles: any = []
      rels.forEach((eachRel) => {
        // find the styles
        const eachRelObj = {}
        eachRelObj["color"] = "#461E96"
        eachRelObj["id"] = `relationship-${eachRel?.elementId}`
        relsStyles.push(eachRelObj)

        // find the styles for source nodes
        const eachNodeObj = {}
        eachNodeObj["color"] = "#461E96"
        eachNodeObj["width"] = "5px"
        eachNodeObj["id"] = `node-${eachRel.source?.elementId}`
        sourceNodeStyles.push(eachNodeObj)
      })
      // push the selected node also
      const eachNodeObj = {}
      eachNodeObj["color"] = "#461E96"
      eachNodeObj["width"] = "5px"
      eachNodeObj["id"] = `node-${node?.elementId}`
      sourceNodeStyles.push(eachNodeObj)
      // update the styles for rels and source nodes
      relsStyles && sourceNodeStyles && this.state.graphStyle.updateRelsAndNodeStylesByIds(relsStyles, sourceNodeStyles)
    } else {
      CustomToast("No node selected", {
        type: "error",
        duration: 5000
      })
    }
  }


  /**
   * Reset the styles for the neighbours of a node
   */
  resetNeighbors = () => {
    if (this.contextItemSelected) {
      const rels = this.state.graphModel.findAllRelationshipToNode(
        this.contextItemSelected
      );
      const relsStyles: any = []
      const sourceNodeStyles: any = []
      rels && rels.forEach((eachRel) => {
        // find the styles
        const eachRelObj = {}
        eachRelObj["color"] = this.state.graphStyle.forRelationship(eachRel).props["color"]
        eachRelObj["id"] = `relationship-${eachRel?.elementId}`
        relsStyles.push(eachRelObj)

        // find the styles for source nodes
        const eachNodeObj = {}
        eachNodeObj["color"] = this.state.graphStyle.forNode(eachRel.source).props["border-color"]
        eachNodeObj["width"] = this.state.graphStyle.forNode(eachRel.source).props["border-width"]
        eachNodeObj["id"] = `node-${eachRel.source?.elementId}`
        sourceNodeStyles.push(eachNodeObj)
      })
      // push the selected node also
      const eachNodeObj = {}
      eachNodeObj["color"] = this.state.graphStyle.forNode(this.contextItemSelected).props["border-color"]
      eachNodeObj["width"] = this.state.graphStyle.forNode(this.contextItemSelected).props["border-width"]
      eachNodeObj["id"] = `node-${this.contextItemSelected?.elementId}`
      sourceNodeStyles.push(eachNodeObj)
      // update the styles for rels and source nodes
      relsStyles && sourceNodeStyles && this.state.graphStyle.updateRelsAndNodeStylesByIds(relsStyles, sourceNodeStyles)
      // clear the default
      this.contextItemSelected = null
    }
  }

  /**
   * Updates the context item selected  and the position of the context menu
   * @param selectedItem - the context item selected  from the context menu
   * contextBoxX - the x position of the context menu box
   * contextBoxY - the y position of the context menu box 
   * contextItemSelect - the context item selected from the context menu
  **/
  onContextItemSelect(selectedItem: ContextItem): void {
    if (selectedItem.type === "context-menu-item") {
      if (selectedItem.item.node?.selected) {
        this.setState({ contextItemSelect: selectedItem });
        this.setState({
          // calculate the top and left based on the mouse click postion
          contextBoxX: selectedItem.item.event.clientX - 100,
          contextBoxY: selectedItem.item.event.clientY - 100
        })
      } else {
        this.setState({ contextItemSelect: null });
      }
    }
  }

  onGraphModelChange(stats: GraphStats): void {
    this.setState({ stats });
    if (this.props.updateStyle) {
      this.props.updateStyle(this.state.graphStyle);
    }
  }


  /**
   * Callback function to set the visualization object
   * @param visualization - the visualization object
   */
  setVisualization = (visualization: Visualization) => {
    this.visualization = visualization;
  }

  /**
   * Updates the graph layout styles
   * Triggered when the nodes and relationships styles are changing from right details pane
   * @param graphLayoutStyles - the graph layout styles
   * @param graphStyle - the graph style  object
   * @param styleVersion - the style version
   */
  updateGraphLayout(): void {
    const { itemType, styleProps } = this.props?.graphLayoutStyles
    let graphStyleForItem;
    if (itemType === 'NODE') {
      graphStyleForItem = this.state.graphStyle.forNode({
        labels: this.props?.graphLayoutStyles?.labels,
      });
    }
    else if (itemType === 'RELATIONSHIP') {
      graphStyleForItem = this.state.graphStyle.forRelationship({
        type: this.props?.graphLayoutStyles?.type,
      });
    }
    const newGraphStyle: GraphStyleModel = this.state.graphStyle
    newGraphStyle.changeForSelector(graphStyleForItem.selector, styleProps);

    this.setState({
      graphStyle: newGraphStyle,
      styleVersion: this.state.styleVersion + 1,
    });
  }

  componentDidUpdate(prevProps: GraphVisualizerProps): void {
    if (!deepEquals(prevProps.graphStyleData, this.props.graphStyleData)) {
      if (this.props.graphStyleData) {
        const rebasedStyle = deepmerge(
          this.defaultStyle,
          this.props.graphStyleData
        );
        this.state.graphStyle.loadRules(rebasedStyle);
        this.setState({
          graphStyle: this.state.graphStyle,
          styleVersion: this.state.styleVersion + 1,
        });
      } else {
        this.state.graphStyle.resetToDefault();
        this.setState(
          { graphStyle: this.state.graphStyle, freezeLegend: true },
          () => {
            this.setState({ freezeLegend: false });
            this.props.updateStyle(this.state.graphStyle.toSheet());
          }
        );
      }
    }
    if (!deepEquals(prevProps.graphLayoutStyles, this.props.graphLayoutStyles)) {
      this.updateGraphLayout()
    }
  }



  render(): JSX.Element {
    // This is a workaround to make the style reset to the same colors as when starting the browser with an empty style
    // If the legend component has the style it will ask the neoGraphStyle object for styling before the graph component,
    // and also doing this in a different order from the graph. This leads to different default colors being assigned to different labels.
    const graphStyle = this.state.freezeLegend
      ? new GraphStyleModel(this.props.useGeneratedDefaultColors)
      : this.state.graphStyle;

    return (
      <StyledFullSizeContainer id="svg-vis">
        {
          (this.props.nodes.length > 0) ? (
            <Neo4jGraph
              isFullscreen={this.props.isFullscreen}
              relationships={this.state.relationships}
              nodes={this.state.nodes}
              getNodeNeighbours={this.getNodeNeighbours.bind(this)}
              onItemMouseOver={this.onItemMouseOver.bind(this)}
              onItemSelect={this.onItemSelect.bind(this)}
              onContextItemSelect={this.onContextItemSelect.bind(this)}
              graphStyle={graphStyle}
              styleVersion={this.state.styleVersion} // cheap way for child to check style updates
              onGraphModelChange={this.onGraphModelChange.bind(this)}
              assignVisElement={this.props.assignVisElement}
              getAutoCompleteCallback={(
                callback: (rels: BasicRelationship[], initialRun: boolean) => void
              ) => {
                this.autoCompleteCallback = callback
              }}
              autocompleteRelationships={this.props.autocompleteRelationships}
              setGraph={this.setGraph.bind(this)}
              offset={
                (this.state.nodePropertiesExpanded ? this.state.width + 8 : 0) + 8
              }
              wheelZoomRequiresModKey={this.props.wheelZoomRequiresModKey}
              wheelZoomInfoMessageEnabled={this.props.wheelZoomInfoMessageEnabled}
              disableWheelZoomInfoMessage={this.props.disableWheelZoomInfoMessage}
              initialZoomToFit={this.props.initialZoomToFit}
              onGraphInteraction={this.props.onGraphInteraction}
              setGraphEventHandler={this.setGraphEventHandler}// use to get the graph event handler from graph to here
              onGraphEventsChange={this.onGraphEventsChange}
              setVisualization={this.setVisualization}
              stats={this.state.stats}
            />
          ) : (
            <div style={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              top: '45%',
              gap: '1vh',
              fontFamily: "Abgilroy Medium"
            }}
            >
              {/* Select a predefined query from <b>"Search Graph"</b> or run a cypher <b><EditCalendarOutlinedIcon /></b> */}
            Select a predefined query, run a cypher <b><EditCalendarOutlinedIcon /></b> or build your own query <b><ExtensionOutlinedIcon /></b>
            </div>
          )
        }
        <NodeInspectorPanel
          graphStyle={graphStyle}
          hasTruncatedFields={this.props.hasTruncatedFields}
          hoveredItem={this.state.hoveredItem}
          selectedItem={this.state.selectedItem}
          stats={this.state.stats}
          width={this.state.width}
          setWidth={(width: number) =>
            this.setState({ width: Math.max(panelMinWidth, width) })
          }
          expanded={this.props?.isFilterOpen}
          toggleExpanded={() => {
            const { nodePropertiesExpanded } = this.state;
            this.props.setNodePropertiesExpandedByDefault(
              !nodePropertiesExpanded
            );
            this.setState({ nodePropertiesExpanded: !nodePropertiesExpanded });
          }}
          DetailsPaneOverride={this.props.DetailsPaneOverride}
          OverviewPaneOverride={this.props.OverviewPaneOverride}
          onNodeSelectedFromFilters={this.onNodeSelectedFromFilters}
          onRelSelectedFromFilters={this.onRelSelectedFromFilters}
        />
        {
          this.state.contextItemSelect && (
            <ContextMenuBox
              x={this.state.contextBoxX}
              y={this.state.contextBoxY}
              node={this.state.contextItemSelect.item.node}
              onClickContextItemClick={this.onClickContextItemClick}
            />
          )
        }
        <Toaster />
      </StyledFullSizeContainer>
    );
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }
}
