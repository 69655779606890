import { connect } from "react-redux";

import { GraphVisualizer } from "../../../components/graphVisualization";



const mapStateToProps = (state) => ({
    isFilterOpen: state.sidebarReducer.isFilterOpen,
    graphLayoutStyles: state.graphReducer.graphLayoutStyles
});


const ConnectedGraphVisualizer = connect(mapStateToProps)(GraphVisualizer);
export default ConnectedGraphVisualizer