import toast from "react-hot-toast";

interface ToastOptionInterface {
    position?: "top-left" | "top-right" | "top-center" | "bottom-left" | "bottom-right" | "bottom-center";
    duration?: number;
    style?: any;
    type?: "success" | "error" | "blank";
}


export  const CustomToast = (message: string, toastOptions: ToastOptionInterface) => {
    if (toastOptions.type) {
        toast[toastOptions.type](message, toastOptions)
    } else {
        toast(message, toastOptions)
    }
}
