import React, { useRef, useState } from "react";

import {
  BasicNode,
  BasicRelationship,
  MapIcon,
  PresentationIcon,
  ZoomInIcon,
  ZoomOutIcon,
  ZoomToFitIcon,
} from "../../../../common";

import { GraphModel } from "../../models/Graph";
import {
  GraphEventHandlerModel,
  GraphInteractionCallBack,
} from "./GraphEventHandlerModel";
import { GraphStyleModel } from "../../models/GraphStyle";
import {
  ContextItem,
  GetNodeNeighboursFn,
  VizItem,
  ZoomLimitsReached,
  ZoomType,
} from "../../types";
import {
  GraphStats,
  createGraph,
  getGraphStats,
  mapRelationships,
} from "../../utils/mapper";
import { Visualization } from "./visualization/Visualization";
import { WheelZoomInfoOverlay } from "./WheelZoomInfoOverlay";
import { StyledSvgWrapper, StyledZoomButton, StyledZoomHolder, StyledLabelHolder } from "./styled";
import { ResizeObserver } from "@juggle/resize-observer";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Tooltip } from "@mui/material";
import cookies from "js-cookie"
import { NodeModel } from "../../models/Node";
import { downloadPNGFromSVG, downloadSVG } from "../../../../utils/imageUtils";
import TableExport from '../../../exportAs/ExportAsTable'


export type GraphProps = {
  isFullscreen: boolean;
  relationships: BasicRelationship[];
  nodes: BasicNode[];
  getNodeNeighbours: GetNodeNeighboursFn;
  onItemMouseOver: (item: VizItem) => void;
  onItemSelect: (item: VizItem) => void;
  onContextItemSelect: (item: ContextItem) => void;
  graphStyle: GraphStyleModel;
  styleVersion: number;
  onGraphModelChange: (stats: GraphStats) => void;
  assignVisElement: (svgElement: any, graphElement: any) => void;
  autocompleteRelationships: boolean;
  getAutoCompleteCallback: (
    callback: (
      internalRelationships: BasicRelationship[],
      initialRun: boolean
    ) => void
  ) => void;
  setGraph: (graph: GraphModel) => void;
  offset: number;
  wheelZoomRequiresModKey?: boolean;
  wheelZoomInfoMessageEnabled?: boolean;
  disableWheelZoomInfoMessage: () => void;
  initialZoomToFit?: boolean;
  onGraphInteraction?: GraphInteractionCallBack;
  setGraphEventHandler: (handler: GraphEventHandlerModel) => void
  onGraphEventsChange?: (type: string, data: any) => void;
  setVisualization: (visualization: Visualization | null) => void;
  stats: GraphStats;
};

type GraphState = {
  zoomInLimitReached: boolean;
  zoomOutLimitReached: boolean;
  displayingWheelZoomInfoMessage: boolean;
  totalNodeCount: number;
  totalRelationshipCount: number;
  miniMapBoxPosition: { x: number; y: number };
  miniMapWidthAndHeight: { width: number; height: number }
  isMiniMapBoxEnabled: boolean;
  zoomScalePercentage: any;
  isMiniMapDragging: boolean;
  miniMapBoxWidthAndHeight: { width: number; height: number }
  isIframeIntialLoaded: boolean;
  exportAnchorEl: HTMLElement | null;
  isTableExportModalOpen: boolean;
};

export class Graph extends React.Component<GraphProps, GraphState> {
  svgElement: React.RefObject<SVGSVGElement | null>;
  wrapperElement: React.RefObject<HTMLDivElement>;
  wrapperResizeObserver: ResizeObserver;
  visualization: Visualization | null = null;
  intialSvgChangeCount: number = 0; /** Used to check if the svg has changed */
  iframeMiniMap: React.RefObject<HTMLIFrameElement>;
  visElement: null | {
    svgElement: unknown
    graphElement: unknown
    type: 'plan' | 'graph'
  } = null

  constructor(props: GraphProps) {
    super(props);
    this.state = {
      zoomInLimitReached: false,
      zoomOutLimitReached: false,
      displayingWheelZoomInfoMessage: false,
      totalNodeCount: 0,
      totalRelationshipCount: 0,
      miniMapBoxPosition: { x: 0, y: 0 },
      miniMapWidthAndHeight: { width: 0, height: 0 },
      isMiniMapBoxEnabled: false,
      zoomScalePercentage: 0,
      isMiniMapDragging: false,
      miniMapBoxWidthAndHeight: { width: 0, height: 0 },
      isIframeIntialLoaded: false,
      exportAnchorEl: null,
      isTableExportModalOpen: false,
    };
    this.svgElement = React.createRef<SVGSVGElement | null>();
    this.wrapperElement = React.createRef();
    this.iframeMiniMap = React.createRef();

    this.wrapperResizeObserver = new ResizeObserver(() => {
      this.visualization?.resize(
        this.props.isFullscreen,
        !!this.props.wheelZoomRequiresModKey
      );
    });
  }

  componentDidMount(): void {
    const {
      assignVisElement,
      autocompleteRelationships,
      getAutoCompleteCallback,
      getNodeNeighbours,
      graphStyle,
      initialZoomToFit,
      isFullscreen,
      nodes,
      onGraphInteraction,
      onGraphModelChange,
      onItemMouseOver,
      onItemSelect,
      onContextItemSelect,
      relationships,
      setGraph,
      wheelZoomRequiresModKey,
      stats
    } = this.props;

    if (!this.svgElement.current) return;

    const measureSize = () => ({
      width: this.svgElement.current?.parentElement?.clientWidth ?? 200,
      height: this.svgElement.current?.parentElement?.clientHeight ?? 200,
    });

    const graph = createGraph(nodes, relationships);
    this.visualization = new Visualization(
      this.svgElement.current,
      measureSize,
      this.handleZoomEvent,
      this.handleDisplayZoomWheelInfoMessage,
      graph,
      graphStyle,
      isFullscreen,
      wheelZoomRequiresModKey,
      initialZoomToFit
    );

    this.props.setVisualization(this.visualization);


    const graphEventHandler = new GraphEventHandlerModel(
      graph,
      this.visualization,
      getNodeNeighbours,
      onItemMouseOver,
      onItemSelect,
      onContextItemSelect,
      onGraphModelChange,
      onGraphInteraction,
      this.props.onGraphEventsChange
    );
    graphEventHandler.bindEventHandlers();
    // passing the graph event handler to parent graph visualizer
    if (graphEventHandler) {
      this.props.setGraphEventHandler(graphEventHandler)
    }

    onGraphModelChange(getGraphStats(graph));
    this.visualization.resize(isFullscreen, !!wheelZoomRequiresModKey);

    if (setGraph) {
      setGraph(graph);
    }
    if (autocompleteRelationships) {
      getAutoCompleteCallback(
        (internalRelationships: BasicRelationship[], initialRun: boolean) => {
          if (initialRun) {
            this.visualization?.init();
            graph.addInternalRelationships(
              mapRelationships(internalRelationships, graph)
            );
            onGraphModelChange(getGraphStats(graph));
            this.visualization?.update({
              updateNodes: false,
              updateRelationships: true,
              restartSimulation: false,
            });
            this.visualization?.precomputeAndStart();
            graphEventHandler.onItemMouseOut();
          } else {
            graph.addInternalRelationships(
              mapRelationships(internalRelationships, graph)
            );
            onGraphModelChange(getGraphStats(graph));
            this.visualization?.update({
              updateNodes: false,
              updateRelationships: true,
              restartSimulation: false,
            });
          }
        }
      );
    } else {
      this.visualization?.init();
      this.visualization?.precomputeAndStart();
    }
    if (assignVisElement) {
      assignVisElement(this.svgElement.current, this.visualization);
      this.visElement = {
        svgElement: this.svgElement.current,
        graphElement: this.visualization,
        type: 'graph'
      }
    }

    this.wrapperResizeObserver.observe(this.svgElement.current);
    this.calculateMiniMapWidthAndHeight()
    this.calculateMiniMapBoxWidthAndHeight()
  }

  componentDidUpdate(prevProps: GraphProps): void {
    if (this.props.isFullscreen !== prevProps.isFullscreen) {
      this.visualization?.resize(
        this.props.isFullscreen,
        !!this.props.wheelZoomRequiresModKey
      );
    }

    if (this.props.styleVersion !== prevProps.styleVersion) {
      this.visualization?.update({
        updateNodes: true,
        updateRelationships: true,
        restartSimulation: false,
      });
    }

    // Update the total node count and total relationship count
    if (this.props.stats !== prevProps.stats) {
      const { relTypes, labels } = this.props.stats;

      // calculate the count for nodes
      let nodeSum = 0;
      for (const label in this.props.stats.labels) {
        if (label !== '*' && labels.hasOwnProperty(label)) {
          nodeSum += labels[label].count;
        }
      }
      this.setState({ totalNodeCount: nodeSum })

      // calculate the count for relationships
      let relSum = 0;
      for (const rel in this.props.stats.relTypes) {
        if (rel !== '*' && relTypes.hasOwnProperty(rel)) {
          relSum += relTypes[rel].count;
        }
      }
      this.setState({ totalRelationshipCount: relSum })
    }

    this.handleIframeContent()


  }

  handleIframeContent(event?: string) {
    // check if intially it loaded svg or not
    if (!this.state.isIframeIntialLoaded) {
      if (this.iframeMiniMap.current && this.svgElement.current) {
        const svgContent = this.svgElement.current.outerHTML
        this.iframeMiniMap.current.srcdoc = svgContent
      }
      if (this.intialSvgChangeCount === 3) {
        this.setState({
          isIframeIntialLoaded: true
        })
      }
    }

    // checking if any node dragged then update the iframe again 
    const isNodeDragged = cookies.get('nodeDragged');
    if (isNodeDragged && isNodeDragged === "true") {
      if (this.iframeMiniMap.current && this.svgElement.current) {
        const svgContent = this.svgElement.current.outerHTML
        this.iframeMiniMap.current.srcdoc = svgContent
      }
      cookies.set('nodeDragged', 'false');
    }
  }

  componentWillUnmount(): void {
    this.wrapperResizeObserver.disconnect();
  }

  handleZoomEvent = (limitsReached: ZoomLimitsReached): void => {
    if (
      limitsReached.zoomInLimitReached !== this.state.zoomInLimitReached ||
      limitsReached.zoomOutLimitReached !== this.state.zoomOutLimitReached
    ) {
      this.setState({
        zoomInLimitReached: limitsReached.zoomInLimitReached,
        zoomOutLimitReached: limitsReached.zoomOutLimitReached,
      });
    }
    this.setState({ zoomScalePercentage: this.visualization.zoomScalePercentage })


    /** On first load there will no change in the minimap */
    if (this.intialSvgChangeCount == 3) {
      this.updateMiniMap()
    }

    if (this.intialSvgChangeCount >= 0 && this.intialSvgChangeCount < 3) {
      this.intialSvgChangeCount = this.intialSvgChangeCount + 1
    }


  };

  updateMiniMap = () => {

    /** MiniMap Box position calculation based on main svg change */
    const { miniMapWidthAndHeight } = this.state;
    const { svgx, svgY, zoomScalePercentage, svgScale } = this.visualization;


    // Calculate the new position of the minimap-box
    const newMiniMapBoxX = -svgx * 0.2
    const newMiniMapBoxY = -svgY * 0.2;

    // Ensure that the newMiniMapBoxX and newMiniMapBoxY are within valid boundaries
    const parentMaxWidth = this.svgElement.current?.parentElement?.clientWidth;
    const parentMaxHeight = this.svgElement.current?.parentElement?.clientHeight;

    // Calculate the maximum valid position
    let validX = Math.min(Math.max(newMiniMapBoxX, 0), parentMaxWidth - miniMapWidthAndHeight.width);
    let validY = Math.min(Math.max(newMiniMapBoxY, 0), parentMaxHeight - miniMapWidthAndHeight.height);


    if (validX > this.state.miniMapWidthAndHeight.width - this.state.miniMapBoxPosition.x) {
      validX = this.state.miniMapWidthAndHeight.width - this.state.miniMapBoxPosition.x
    }

    if (validY > this.state.miniMapWidthAndHeight.height - this.state.miniMapBoxPosition.y) {
      validY = this.state.miniMapWidthAndHeight.height - this.state.miniMapBoxPosition.y
    }



    // Update the position of the minimap-box
    this.updateMiniMapBoxPosition(validX, validY);

    /** Minimap width and height based on zoom percentage */

    // Calculate the new width and height based on the zoom scale percentage
    let newWidth, newHeight;

    if (zoomScalePercentage > 0) {
      newWidth = miniMapWidthAndHeight.width * (1 - (zoomScalePercentage / 100)) * 0.5;
      newHeight = miniMapWidthAndHeight.height * (1 - (zoomScalePercentage / 100)) * 0.5;
    } else {
      newWidth = miniMapWidthAndHeight.width * (1 - (zoomScalePercentage / 100)) * 0.5;
      newHeight = miniMapWidthAndHeight.height * (1 - (zoomScalePercentage / 100)) * 0.5;
    }

    // Ensure that the new width and height are within valid boundaries
    const clampedWidth = Math.max(newWidth, 5); // Minimum width to prevent disappearance
    const clampedHeight = Math.max(newHeight, 5); // Minimum height to prevent disappearance

    // Update the width and height of the minimap-box
    this.setState({
      miniMapBoxWidthAndHeight: {
        width: clampedWidth,
        height: clampedHeight,
      },
    });

  }



  handleDisplayZoomWheelInfoMessage = (): void => {
    if (
      !this.state.displayingWheelZoomInfoMessage &&
      this.props.wheelZoomRequiresModKey &&
      this.props.wheelZoomInfoMessageEnabled
    ) {
      this.displayZoomWheelInfoMessage(true);
    }
  };

  displayZoomWheelInfoMessage = (display: boolean): void => {
    this.setState({ displayingWheelZoomInfoMessage: display });
  };

  zoomInClicked = (): void => {
    this.visualization?.zoomByType(ZoomType.IN);
  };

  zoomOutClicked = (): void => {
    this.visualization?.zoomByType(ZoomType.OUT);
  };

  zoomToFitClicked = (): void => {
    this.visualization?.zoomByType(ZoomType.FIT);
  };

  toggleFullScreen = (): void => {
    const isFullscreen = document.fullscreenElement !== null;
    if (isFullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    } else {
      const element = document.documentElement;
      if (element && element.requestFullscreen) {
        element.requestFullscreen();
      }
    }
  }

  setTotalNodeCount = (totalNodeCount: number): void => {
    this.setState({ totalNodeCount });
  }

  setTotalRelationshipCount = (totalRelationshipCount: number): void => {
    this.setState({ totalRelationshipCount });
  }


  updateMiniMapBoxPosition = (x: number, y: number): void => {
    this.setState({
      miniMapBoxPosition: {
        x: x,
        y: y
      },
    });
  };

  handleMiniMouseDown = (event: React.MouseEvent): void => {
    this.setState({ isMiniMapDragging: true })
  };

  handleMiniMouseUp = (): void => {
    this.setState({ isMiniMapDragging: false })
  };

  handleMiniMouseMove = (event: React.MouseEvent): void => {
    if (this.state.isMiniMapDragging && this.svgElement.current) {
      const svgRect = this.svgElement.current.getBoundingClientRect();
      const parentMaxWidth = svgRect.width;
      const parentMaxHeight = svgRect.height;
      const miniMapWidth = this.state.miniMapWidthAndHeight.width;
      const miniMapHeight = this.state.miniMapWidthAndHeight.height;

      const x = event.nativeEvent.offsetX;
      const y = event.nativeEvent.offsetY;

      // Check if the mouse is still pressed inside the mini-map box
      if (x >= 0 && x <= miniMapWidth && y >= 0 && y <= miniMapHeight) {
        // Calculate the ratio of movement in the mini-map
        const ratioX = x / miniMapWidth;
        const ratioY = y / miniMapHeight;

        // Calculate the new position of the main SVG based on the ratio
        const newX = -ratioX * (parentMaxWidth - miniMapWidth);
        const newY = -ratioY * (parentMaxHeight - miniMapHeight);

        // Ensure that the newMiniMapX and newMiniMapY are within valid boundaries
        const newMiniMapX = Math.min(Math.max(x, 0), parentMaxWidth - miniMapWidth);
        const newMiniMapY = Math.min(Math.max(y, 0), parentMaxHeight - miniMapHeight);

        // Update the position of the main SVG and the mini-map box
        this.visualization?.adjustMainSvgPosition(newX, newY);
        this.updateMiniMapBoxPosition(newMiniMapX, newMiniMapY);

        // this.isMiniMapDragging = true;
      }
    }
  };

  calculateMiniMapWidthAndHeight = () => {
    if (this.svgElement.current) {
      const svgRect = this.svgElement.current.getBoundingClientRect();
      const svgWidth = svgRect.width;
      const svgHeight = svgRect.height;
      const svgRatio = svgWidth / svgHeight;

      // Adjusted MiniMapWidth and MiniMapHeight based on the ratio of the main box
      const miniMapWidthRatio = 0.2;
      const miniMapWidth = svgWidth * miniMapWidthRatio;
      const miniMapHeight = miniMapWidth / svgRatio;

      this.setState({
        miniMapWidthAndHeight: {
          width: miniMapWidth,
          height: miniMapHeight,
        },
      });


    }
  }

  calculateMiniMapBoxWidthAndHeight() {
    if (this.svgElement.current) {
      const svgRect = this.svgElement.current.getBoundingClientRect();
      const svgWidth = svgRect.width;
      const svgHeight = svgRect.height;
      const svgRatio = svgWidth / svgHeight;

      // Adjusted MiniMapWidth and MiniMapHeight based on the ratio of the main box
      const miniMapWidthRatio = 0.2;
      const miniMapWidth = svgWidth * miniMapWidthRatio;
      const miniMapHeight = miniMapWidth / svgRatio;

      this.setState({
        miniMapBoxWidthAndHeight: {
          width: miniMapWidth,
          height: miniMapHeight,
        }
      })
    }

  }


  /** handle export button open and close */
  handleExportAnchorEl = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      exportAnchorEl: event.currentTarget
    })
  };

  handleCloseAnchorEl = () => {
    this.setState({
      exportAnchorEl: null
    })
  }

  handleTableExportClose=()=>{
    this.setState({isTableExportModalOpen: null})
  }

  handleExport(type: string) {
    switch (type) {
      case "pngExport":
        downloadPNGFromSVG(this.visElement.svgElement, this.visElement.graphElement, 'graph')
        break;
      case "svgExport":
        downloadSVG(this.visElement.svgElement, this.visElement.graphElement, 'graph')
        break;
      case "tableExport":
        this.setState({ isTableExportModalOpen: true });
        this.handleCloseAnchorEl();
        break;
      default:
        break;
    }
  }



  render(): JSX.Element {
    const {
      offset,
      isFullscreen,
      wheelZoomInfoMessageEnabled,
      disableWheelZoomInfoMessage,
    } = this.props;
    const {
      zoomInLimitReached,
      zoomOutLimitReached,
      displayingWheelZoomInfoMessage,
    } = this.state;
    return (
      <StyledSvgWrapper ref={this.wrapperElement}>
        {/* Export component */}
        <Box
          sx={{
            width: "9rem",
            height: "3.1rem",
            color: "black",
            backgroundColor: "white",
            "::selection": { border: "0px" },
            position: "absolute",
            right: "1.5rem",
            top: "0",
            display: "flex",
            alignItems: "center",
            zIndex: 1000,
            border: "0.5px solid #D9D9D9"
          }}
        >
          <Button className="seventh-step" sx={{ width: "100%", height: "100%", textTransform: "capitalize" }} onClick={(e) => this.handleExportAnchorEl(e)} endIcon={<ArrowDropDownIcon />}>
            Export As
          </Button>
          <Menu
            anchorEl={this.state.exportAnchorEl}
            open={Boolean(this.state.exportAnchorEl)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={(e) => this.handleCloseAnchorEl()}

          >
            <MenuItem
              onClick={() => this.handleExport("pngExport")}
              sx={{
                width: "9rem"
              }} >
              PNG
            </MenuItem>
            <MenuItem
              onClick={() => this.handleExport("svgExport")}
              sx={{
                width: "9rem"
              }} >
              SVG
            </MenuItem>
            <MenuItem
            onClick={() => this.handleExport("tableExport")}
              sx={{
                width: "9rem"
              }} >
              Table
            </MenuItem>
          </Menu>
        </Box>     
        <TableExport open={this.state.isTableExportModalOpen} close={this.handleTableExportClose}/>
        {/* <TableExportNew open={this.state.isTableExportModalOpen} close={this.handleTableExportClose}/>   */} 
        

        {/* Parent SVG */}
        <svg className="neod3viz" ref={this.svgElement} />
        {/* MiniMap  */}
        {this.state.isMiniMapBoxEnabled && (
          <Box
            id="minimap-container"
            sx={{
              position: "absolute",
              bottom: 85,
              right: 30,
              width: this.state.miniMapWidthAndHeight.width,
              height: this.state.miniMapWidthAndHeight.height,
              overflow: "hidden",
              backgroundColor: "white",
              zIndex: 1000,
              boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.75)",
            }}
            onMouseDown={this.handleMiniMouseDown}
            onMouseUp={this.handleMiniMouseUp}
            onMouseMove={this.handleMiniMouseMove}
          >
            <Box width="100%" height="100%" sx={{ pointerEvents: "none", overflow: "hidden" }}>
              <iframe style={{
                border: "none",
                overflow: "hidden",
                pointerEvents: "none",
                transform: String(this.visualization?.transform),
              }} width="100%" height="100%" ref={this.iframeMiniMap} />
            </Box>
            <Box
              id="minimap-box"
              style={{
                border: "1px solid #461E96",
                position: "absolute",
                width: this.state.miniMapBoxWidthAndHeight.width,
                height: this.state.miniMapBoxWidthAndHeight.height,
                cursor: "pointer",
                top: this.state.miniMapBoxPosition.y,
                left: this.state.miniMapBoxPosition.x,
                zIndex: 10000,
              }}
            >
            </Box>
          </Box>
        )}
        {/* Label holder showing nodes and relations count */}
        <StyledLabelHolder offset={offset} isFullscreen={isFullscreen}>
          <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
            <Typography sx={{ color: "#333333", fontSize: "0.75rem", fontWeight: 400 }}>All({this.state.totalNodeCount + this.state.totalRelationshipCount})</Typography>
            <Typography sx={{ color: "#333333", fontSize: "0.75rem", fontWeight: 400 }}>Nodes({this.state.totalNodeCount})</Typography>
            <Typography sx={{ color: "#333333", fontSize: "0.75rem", fontWeight: 400 }}>Relationships({this.state.totalRelationshipCount})</Typography>
          </Box>
          <Typography sx={{ color: "#00000085", fontSize: "0.75rem", fontWeight: 400 }}>Graph shown above is subset of full graph</Typography>
          {/* <Typography sx={{ color: "#333333", fontSize: "0.75rem", fontWeight: 400 }}><ArrowDropDownIcon /></Typography> */}
        </StyledLabelHolder>
        {/* Zoom Holder */}
        <StyledZoomHolder offset={offset} isFullscreen={isFullscreen} >
          <Tooltip title="Zoom To Fit" placement="top-start" arrow>
            <StyledZoomButton
              aria-label={"zoom-to-fit"}
              onClick={this.zoomToFitClicked}
            >
              <ZoomToFitIcon large={isFullscreen} />
            </StyledZoomButton>
          </Tooltip>
          {/* <Tooltip title="Mini Map" placement="top-start" arrow>
            <StyledZoomButton
              aria-label={"mine-map"}
              onClick={() => {
                this.setState({
                  isMiniMapBoxEnabled: !this.state.isMiniMapBoxEnabled,
                });
              }}
            >
              <MapIcon large={isFullscreen} />
            </StyledZoomButton>
          </Tooltip> */}
          <Tooltip title="Full Screen" placement="top-start" arrow>
            <StyledZoomButton
              aria-label={"full-screen"}
              onClick={() => this.toggleFullScreen()}
            >
              <PresentationIcon large={isFullscreen} />
            </StyledZoomButton>
          </Tooltip>
          <Tooltip title="Zoom In" placement="top-start" arrow>
            <StyledZoomButton
              aria-label={"zoom-in"}
              className={"zoom-in"}
              disabled={zoomInLimitReached}
              onClick={this.zoomInClicked}
            >
              <ZoomInIcon large={isFullscreen} />
            </StyledZoomButton>
          </Tooltip>
          <Tooltip title="Zoom Out" placement="top-start" arrow>
            <StyledZoomButton
              aria-label={"zoom-out"}
              className={"zoom-out"}
              disabled={zoomOutLimitReached}
              onClick={this.zoomOutClicked}
            >
              <ZoomOutIcon large={isFullscreen} />
            </StyledZoomButton>
          </Tooltip>
          <Tooltip title="Zoom Scale" placement="top-start" arrow>
            <StyledZoomButton
              aria-label={"zoom-percentage"}
            >
              <Typography sx={{ fontSize: "0.652rem" }}>{Math.round(this.state.zoomScalePercentage)}%</Typography>
            </StyledZoomButton>
          </Tooltip>
        </StyledZoomHolder>
        {
          wheelZoomInfoMessageEnabled && displayingWheelZoomInfoMessage && (
            <WheelZoomInfoOverlay
              onDisableWheelZoomInfoMessage={disableWheelZoomInfoMessage}
            />
          )
        }
      </StyledSvgWrapper >
    );
  }
}
