/**
 * Header Component
 *
 * The `Header` component is used to render the header of the application. It includes a header content section
 * and a sidebar section (for navigation) when the user navigates to a page other than the main path.
 *
 * @returns {React.ReactNode} The `Header` component.
 */

import React, { useState } from "react";
import HeaderContent from "./HeaderContent";
import { useLocation, useNavigate } from "react-router-dom";
import { path } from "../../constants/routes";
import Sidebar from "../sidebar/Sidebar";
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import { Grid } from "@mui/material";
import MenuDrawer from "../menuDrawer/menuDrawer";
import cookies from "js-cookie";
import { useAppDispatch } from "../../redux/hooks/hook";
import { setUserInfo } from "../../redux/reducers/userInfoReducer";

const Header: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  // const [user, setUser] = React.useState<AccountInfo>();
  let user: AccountInfo;
  const { instance, accounts } = useMsal();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);


  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const onClickLogout = () => {
    setAnchorElUser(null);
    // clear all the session storage,local storage and cookies
    sessionStorage.clear();
    localStorage.clear();


    cookies.remove("loginToken");
    cookies.remove("user");
    navigate("/login");
  };
  const isCurrentPath = () => {
    return location.pathname === path || location.pathname === `${path}/`;
  };

  const getUser = async () => {
    const account = accounts[0] || instance.getActiveAccount();
    user = account;
  };

  getUser();


  return (
    <>
      <HeaderContent
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        handleOpenUserMenu={handleOpenUserMenu}
        anchorElUser={anchorElUser}
        handleCloseUserMenu={handleCloseUserMenu}
        onClickLogout={onClickLogout}
        isCurrentPath={isCurrentPath()}
        headerText={location.pathname}
        //@ts-ignore
        userAccountInfo={user}
        isDrawerOpen={isDrawerOpen}
      />
      <MenuDrawer isOpen={isDrawerOpen} handleClose={handleDrawerClose} />

    </>
  );
};

export default Header;


